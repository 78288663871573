import { tagsApi } from './tagsApi'
import { authenticationApi } from './authenticationApi'
import { mediaApi } from './mediaApi'
import { configApi } from './configApi'
import { roomsApi } from './roomsApi'
import { groupsApi } from './groupsApi'
import { tutorialVideosApi } from './tutorialVideosApi'
import { menuMakerApi } from './menuMakerApi'
import { emailNotificationApi } from './emailNotificationApi'
import { playlistsApi } from './playlistsApi'
import { office365Api } from './office365Api'
import { tablesApi } from './tablesApi'
import { templatesApi } from './templatesApi'
import { featureApi } from './featureApi'
import { designGalleryCategoriesApi } from './designGalleryCategoriesApi'
import { schedulesApi } from './schedulesApi'
import { oauth2Api } from './oauth2Api'
import { usersApi } from './usersApi'
import { featureManagementApi } from './featureManagementApi'
import { eulaTermsApi } from './eulaTermsApi'
import { clientUsersApi } from './clientUsersApi'
import { emailTemplateApi } from './emailTemplateApi'
import { featureCategoriesApi } from './featureCategoriesApi'
import { appApi } from './appApi'
import { clientsApi } from './clientsApi'
import { workplacePosterApi } from './workplacePosterAPI'
import { upgradeRequestApi } from './upgradeApi'
import { deviceApi } from './deviceApi'
import { devicePackageApi } from './devicePackageApi'
import { preferenceApi } from './preferenceApi'
import { aiVideoCreatorApi } from './aiVideoCreator'
import { oneDriveApi } from './oneDrive'
import { licensesApi } from './licensesApi'
import { reportsApi } from './reportsApi'
import { clientLicensesApi } from './clientLicensesApi'
import { supportTokenApi } from './supportToken'
import { contentsApi } from './contentsApi'
import { googleDocsApi } from './googleDocsApi'
import { smartSchedulesApi } from './smartSchedulesApi'

export const registeredApi = [
  tagsApi,
  authenticationApi,
  mediaApi,
  configApi,
  roomsApi,
  groupsApi,
  tutorialVideosApi,
  menuMakerApi,
  emailNotificationApi,
  playlistsApi,
  office365Api,
  tablesApi,
  templatesApi,
  featureApi,
  designGalleryCategoriesApi,
  schedulesApi,
  oauth2Api,
  usersApi,
  featureManagementApi,
  eulaTermsApi,
  clientUsersApi,
  emailTemplateApi,
  featureCategoriesApi,
  appApi,
  clientsApi,
  workplacePosterApi,
  upgradeRequestApi,
  deviceApi,
  devicePackageApi,
  preferenceApi,
  aiVideoCreatorApi,
  oneDriveApi,
  licensesApi,
  reportsApi,
  clientLicensesApi,
  supportTokenApi,
  contentsApi,
  googleDocsApi,
  smartSchedulesApi
]

if (
  process.env.NODE_ENV === 'development' &&
  registeredApi.length !==
    new Set(registeredApi.map(({ reducerPath }) => reducerPath)).size
) {
  throw new Error('A non-unique reducerPath in registeredApi was detected')
}
