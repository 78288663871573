import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Spacing from 'components/Containers/Spacing'
import { Text } from 'components/Typography'
import Tooltip from 'components/Tooltip'
import { playlistConstants } from 'constants/index'
import ShareIndicator from 'components/ShareIndicator'
import { secondsToDurationString, stringToSec } from 'utils/time'
import StatusInfoIcon from 'components/Icons/StatusInfoIcon'
import useStatusInfo from 'hooks/statusInfo/useStatusInfo'
import { getMediaDuration } from 'utils/mediaUtils'
import { MEDIA_FALLBACK_TIME } from 'constants/media'
import EmptySetIcon from 'components/Icons/EmptySetIcon'

const useStyles = makeStyles(({ colors }) => ({
  icon: ({ iconColor }) => ({
    fontSize: 18,
    color: iconColor || colors.light
  }),
  emptySetIcon: {
    padding: '0 12px'
  }
}))

const PlaylistCardBottomInfo = ({ playlist, truncateDuration = false }) => {
  const { t } = useTranslation()

  const { getPlaylistStatusInfo } = useStatusInfo()

  const { playlistType, durationTotal, noOfFiles } = playlist

  const playlistTypeInfo = playlistConstants.playlistTypes[playlistType] || {}

  const classes = useStyles({ iconColor: playlistTypeInfo.color })

  const duration = secondsToDurationString(durationTotal) || 'N/A'
  const isDurationGreaterThan24Hours =
    stringToSec(getMediaDuration(playlist, true, MEDIA_FALLBACK_TIME)) > 86400

  return (
    <Spacing
      direction="row"
      justifyContent="space-between"
      borderTop={1}
      variant={0}
      paddingHor={1}
      paddingVert={1}
    >
      <Grid item container xs={6} alignItems="center">
        <Tooltip arrow title={t(playlistTypeInfo.title)}>
          {playlistTypeInfo.icon ? (
            <playlistTypeInfo.icon className={classes.icon} />
          ) : (
            <i
              className={classNames(
                playlistTypeInfo.iconHelperClass,
                classes.icon
              )}
            />
          )}
        </Tooltip>
        {!noOfFiles && <EmptySetIcon rootClassName={classes.emptySetIcon} />}
        <ShareIndicator item={playlist} />
        <StatusInfoIcon data={playlist} getStatusInfo={getPlaylistStatusInfo} />
      </Grid>
      <Grid item container xs={6} alignItems="center" justifyContent="flex-end">
        {isDurationGreaterThan24Hours && truncateDuration ? (
          <Tooltip arrow title={duration}>
            <Text variant="small">24h+</Text>
          </Tooltip>
        ) : (
          <Text variant="small">{duration}</Text>
        )}
      </Grid>
    </Spacing>
  )
}

export default PlaylistCardBottomInfo
