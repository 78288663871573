import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { _uniqBy, _cloneDeep, _isEmpty } from 'utils/lodash'

import Scrollbars from 'components/Scrollbars'
import { dependencyType } from 'constants/library'
import DevicePreviewModal from '../DevicePreviewModal'
import { ScreenPreviewModal } from '../index'
import TabsGroup, { createTab } from 'components/Tabs/TabsGroup'
import { capitalize } from 'utils'
import { featureNames } from 'constants/featureConstants'
import Spacing from 'components/Containers/Spacing'
import DependencyList from '../DependencyModal/DependencyList'
import EmptyPlaceholder from 'components/EmptyPlaceholder'

const MAX_MODAL_WIDTH = 560

const styles = ({ type, typography, colors, palette }) => ({
  scrollContent: {
    marginRight: 20
  },
  table: {
    border: '1px solid ' + colors.border[type],
    marginBottom: 16
  },
  tableHeaderCell: {
    paddingTop: '8px !important',
    fontWeight: 700,

    '& span': {
      padding: '0px !important',
      cursor: 'unset !important'
    }
  },
  name: {
    ...typography.darkAccent[type]
  },
  aliasWrapContent: {
    minWidth: '190px'
  },
  badgeWrapper: {
    width: 0,
    margin: '0px auto'
  },
  modalContent: {
    padding: '10px 0px 0px 20px !important'
  },
  paddingRight: {
    paddingRight: 20
  },
  scrollbarRoot: {
    height: 'min(400px, calc(100vh - 270px)) !important'
  },
  noDataMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    height: 'min(494px, calc(100vh - 270px)) !important',
    margin: 'auto',
    textAlign: 'center',
    paddingBottom: 60
  },
  accordionContent: {
    padding: 0
  },
  modalTitleRoot: {
    overflow: 'hidden'
  },
  modalTitleText: {
    color: palette[type].dialog.title,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  }
})

const CommonModelAlreadyUsedContent = ({ error, classes, isBulk }) => {
  const { t } = useTranslation('translations')

  const [selectedTab, setSelectedTab] = useState()

  const reduceData = useCallback(data => {
    const _data = {
      ...data
    }
    Object.keys(data).forEach(d => {
      if (
        [featureNames.SmartPlaylist, featureNames.InteractiveContent].includes(
          d
        )
      ) {
        _data[dependencyType.playlist] = _uniqBy(
          [...(_data[dependencyType.playlist] || []), ..._data[d]],
          'id'
        )

        delete _data[d]
      } else if (d === 'Modules') {
        _data[dependencyType.others] = [..._data['Modules']]

        delete _data['Modules']
      }
    })

    return _data
  }, [])

  const sortData = useCallback(
    data =>
      Object.fromEntries(
        Object.entries(data).map(([key, value]) => {
          let sortedValue = value

          if (key === dependencyType.user) {
            sortedValue = value.sort((a, b) =>
              a.lastAuthActivity?.activity_time <
              b.lastAuthActivity?.activity_time
                ? 1
                : -1
            )
          } else if (key !== dependencyType.client) {
            sortedValue = value.sort((a, b) =>
              a.updatedAt < b.updatedAt ? 1 : -1
            )
          }

          return [key, sortedValue]
        })
      ),
    []
  )

  const parseData = useMemo(() => {
    if (!error.data) {
      return []
    }

    if (isBulk) {
      const _data = {}
      Object.entries(error.data).forEach(([key, value]) => {
        _data[key] = sortData(reduceData(_cloneDeep(value)))
      })
      return _data
    }
    return sortData(reduceData(_cloneDeep(error.data)))
  }, [error, isBulk, reduceData, sortData])

  const tabsArr = useMemo(() => {
    if (isBulk) {
      const tab = new Set()
      Object.values(parseData).forEach(value => {
        Object.keys(value).forEach(key => tab.add(key))
      })
      return Array.from(tab)
    }
    return Object.keys(parseData)
  }, [parseData, isBulk])

  useEffect(() => {
    if (tabsArr) {
      setSelectedTab(tabsArr?.[0])
    }
  }, [tabsArr])

  const tabs = useMemo(() => {
    return tabsArr.map(key => {
      return createTab(t(capitalize(key)), key)
    })
  }, [tabsArr, t])

  const handleChangeTab = useCallback((e, tab) => {
    if (tab) {
      setSelectedTab(tab)
    }
  }, [])

  const getTabWidth = useCallback(tabs => {
    const maxWidth = MAX_MODAL_WIDTH / tabs.length - 5
    if (maxWidth > 150) return 150

    return maxWidth
  }, [])

  const tabData = useMemo(() => {
    if (!isBulk) {
      return parseData?.[selectedTab] || []
    }

    const _data = tabsArr.reduce((a, v) => ({ ...a, [v]: {} }), {})

    Object.entries(parseData).forEach(([key, value]) => {
      Object.entries(value).forEach(([_type, _val]) => {
        _data[_type][key] = _val
      })
    })

    return _data?.[selectedTab] || []
  }, [parseData, tabsArr, isBulk, selectedTab])

  return (
    <>
      <Spacing rootClassName={classes.paddingRight}>
        <TabsGroup
          value={selectedTab}
          onChange={handleChangeTab}
          tabs={tabs}
          tabWidth={getTabWidth(tabsArr)}
        />
      </Spacing>
      {!_isEmpty(tabData) ? (
        <Scrollbars className={classes.scrollbarRoot}>
          <Spacing variant={0} rootClassName={classes.paddingRight}>
            <DependencyList
              data={tabData}
              type={selectedTab}
              isBulk={isBulk}
              classes={classes}
            />
          </Spacing>
        </Scrollbars>
      ) : (
        selectedTab && (
          <EmptyPlaceholder
            text={t(
              'This item is not associated with any active associations',
              {
                currentItem: capitalize('Tag'),
                associatedItem: selectedTab
              }
            )}
            rootClassName={classes.noDataMessage}
          />
        )
      )}
      {tabsArr.includes(dependencyType.device) && <DevicePreviewModal />}
      {tabsArr.includes(dependencyType.media) && <ScreenPreviewModal />}
      {[
        dependencyType.playlist,
        dependencyType.attachPlaylist,
        dependencyType.detachPlaylist
      ].some(d => tabsArr.includes(d)) && (
        <ScreenPreviewModal targetReducer="playlist" />
      )}
      {tabsArr.includes(dependencyType.template) && (
        <ScreenPreviewModal targetReducer="template" />
      )}

      {[
        dependencyType.schedule,
        dependencyType.attachSchedule,
        dependencyType.detachSchedule
      ].some(d => tabsArr.includes(d)) && (
        <ScreenPreviewModal targetReducer="schedule" />
      )}
    </>
  )
}

export default withStyles(styles)(CommonModelAlreadyUsedContent)
