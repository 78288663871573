import React from 'react'
import { compose } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { Typography, Grid, withStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'

import { FABRIC_EXAMPLE_TEXT } from 'constants/canvasConstants'
import { _get } from 'utils/lodash'

const styles = ({ type, colors }) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'auto',
    color: type === 'dark' ? '#5c697f' : '#242424',
    overflow: 'hidden',
    lineHeight: 1,
    padding: 10,
    '&:hover': {
      color: type === 'dark' ? '#fff' : ''
    },
    backgroundColor: colors.background.designGallery[type]
  },
  title: {
    display: 'inline-flex',
    maxWidth: '100%',
    fontSize: '29px',
    lineHeight: '1',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '4px'
  },
  text: {
    display: 'inline-flex',
    maxWidth: '100%',
    fontSize: '14px',
    lineHeight: '19px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})

const FontPreview = ({ t, id, family, variants, classes }) => {
  // useFontLoader({
  //   font: {
  //     id,
  //     family,
  //     variants
  //   },
  //   regularOnly: false
  // })

  return (
    <Grid container direction="row">
      <Typography
        component="div"
        className={classes.wrapper}
        style={{
          fontFamily: family,
          fontStyle:
            _get(variants, '[0].style') === 'italic' ? 'italic' : 'normal'
        }}
      >
        <Box fontWeight={500} className={classes.title}>
          {family}
        </Box>
        <Box fontWeight={400} className={classes.text}>
          {t(FABRIC_EXAMPLE_TEXT)}
        </Box>
      </Typography>
    </Grid>
  )
}

export default compose(
  withTranslation('translations'),
  withStyles(styles)
)(FontPreview)
