import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'

import SelectImageDialog from 'components/Pages/AccountSettings/SettingsDetails/SelectImageDialog'
import useToggle from 'hooks/useToggle'
import { useLazyGetMediaLibraryItemQuery } from 'api/mediaApi'
import { KEY, messageTypes } from 'constants/mapplicConstants'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    border: 'none'
  }
}))

const MapplicAdmin = ({ onSave, mapJson, mapTitle = '' }) => {
  const classes = useStyles()
  const imageModal = useToggle()
  const [getMedia, { data: mediaData }] = useLazyGetMediaLibraryItemQuery()

  const localType = localStorage.getItem('theme')
  const ref = useRef(null)
  const [iFrameReady, setIFrameReady] = useState(false)
  const [responseData, setResponseData] = useState({})

  const postMessage = useCallback((type, data) => {
    if (ref.current?.contentWindow) {
      ref.current.contentWindow.postMessage({
        type,
        data
      })
    }
  }, [])

  const messageHandler = useCallback(
    ({ data }) => {
      if (
        data.type &&
        typeof data.type === 'string' &&
        data.type.startsWith(KEY)
      ) {
        switch (data.type) {
          case messageTypes.requestLayerImage:
            setResponseData({
              type: messageTypes.setLayerImage
            })
            imageModal.setTrue()
            break
          case messageTypes.requestImage:
            setResponseData({
              type: messageTypes.setImage,
              data: {
                property: data.data?.property
              }
            })
            imageModal.setTrue()
            break
          case messageTypes.saveMap:
            onSave(data.data)
            break
          default:
            break
        }
      }
    },
    [imageModal, onSave]
  )

  useEffect(() => {
    window.addEventListener('message', messageHandler)

    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [messageHandler])

  useEffect(() => {
    if (iFrameReady && mapJson) {
      postMessage(messageTypes.setMapJSON, mapJson)
    }
    // eslint-disable-next-line
  }, [mapJson, iFrameReady])

  useEffect(() => {
    if (iFrameReady && localType) {
      postMessage(messageTypes.theme, localType)
    }
    // eslint-disable-next-line
  }, [mapJson, iFrameReady])

  useEffect(() => {
    if (iFrameReady && mapTitle) {
      postMessage(messageTypes.setTitle, mapTitle)
    }
    // eslint-disable-next-line
  }, [mapTitle, iFrameReady])

  useEffect(() => {
    if (mediaData) {
      postMessage(responseData.type, {
        ...(responseData.data || {}),
        media: mediaData
      })
    }
    // eslint-disable-next-line
  }, [mediaData])

  return (
    <>
      <iframe
        ref={ref}
        title="mapplic-admin"
        className={classes.root}
        src="/embedded/mapplic/index.html"
        onLoad={() => setIFrameReady(true)}
      />
      <SelectImageDialog
        open={imageModal.toggled}
        onCloseModal={imageModal.setFalse}
        onSelect={({ imageId }) => {
          getMedia(imageId)
          imageModal.setFalse()
        }}
      />
    </>
  )
}

export default memo(MapplicAdmin)
