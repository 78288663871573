import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import 'openapi-explorer'

import { Text } from 'components/Typography'
import { useGetDocsQuery } from 'api/appApi'
import { _cloneDeep } from 'utils/lodash'

const Documentation = () => {
  const { data, isError } = useGetDocsQuery()

  useEffect(() => {
    if (data) {
      document
        .getElementsByTagName('openapi-explorer')[0]
        ?.loadSpec(_cloneDeep(data))
    }
  }, [data])

  useEffect(() => {
    const style = document.createElement('style')
    style.textContent = 'body > iframe { display: none; }'
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [])

  if (isError)
    return (
      <Grid container justifyContent="center">
        <Text>Unable to get documentation</Text>
      </Grid>
    )

  return (
    <openapi-explorer hide-console hide-authentication hide-server-selection />
  )
}

export default Documentation
