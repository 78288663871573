import moment from 'moment'
import uuidv4 from 'uuid/v4'

import { labelToSec, secToLabel } from './secToLabel'
import {
  expiresMediaStatuses,
  MEDIA_FALLBACK_TIME,
  MEDIA_MAX_TIME,
  MEDIA_MIN_TIME,
  unprocessableMediaStatuses
} from 'constants/media'
import {
  BACKEND_DATE_FORMAT,
  MEDIA_DATE_FORMAT,
  TIME_FORMAT
} from 'constants/dateTimeFormats'
import {
  NULL_DATE_BE_STRING,
  NULL_DATE_FE_STRING,
  allWorkingDays,
  playbackContentType,
  publishTabType,
  recurrenceIntervals,
  recurrenceMonthlyIntervals,
  recurrenceTypes
} from 'constants/schedule'
import ScheduleHelper from 'helpers/scheduleHelper'
import {
  getMediaDuration,
  getMediaStatus,
  getPlaylistMediaDuration
} from 'utils/mediaUtils'
import * as selectUtils from 'utils/select'
import { resourceTypes } from 'constants/api'
import {
  expiresPlaylistStatuses,
  playlistTypes,
  unprocessablePlaylistStatuses
} from 'constants/playlist'
import { getPlaylistStatus } from './playlistUtils'
import { getTemplateStatus } from './templateUtils'
import {
  expiresTemplateStatuses,
  unprocessableTemplateStatuses
} from '../constants/template'

const FALLBACK_MAX_HOURS = 24
const HOUR_MINUTES = 60
export const TIMELINE_CELL_WIDTH = 57

export const TIMELINE_MAX_VISIBLE_LINES = 2
export const TIMELINE_LINE_HEIGHT = 28
export const TIMELINE_LINE_OFFSET = 17
export const TIMELINE_LINE_PADDING = 10
export const MIN_ROW_HEIGHT = 67
export const TIMELINE_DEVICE_TITLE_WIDTH = 210
export const TIMELINE_LEFT_PADDING = 11
export const INITIAL_DEVICES_LIMIT = 15

export const calendarViews = {
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month'
}
export const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const isAllDaySchedule = ({ allTime, startTime, endTime }) => {
  return allTime || (startTime === MEDIA_MIN_TIME && endTime === MEDIA_MAX_TIME)
}

export const isDateInScheduleRange = (schedule, date) => {
  const targetDate = date.clone().utcOffset(0, true)
  const startDate = moment(schedule.startDate, MEDIA_DATE_FORMAT)
    .utcOffset(0, true)
    .startOf('day')

  //endDate: null === infinity schedule
  const endDate =
    schedule.endDate &&
    moment(schedule.endDate, MEDIA_DATE_FORMAT).utcOffset(0, true).endOf('day')

  return !(
    targetDate.isBefore(startDate) ||
    (endDate ? targetDate.isAfter(endDate) : false)
  )
}

export const isDateInSpecificDates = ({ specificDates }, date) => {
  return specificDates.includes(date.format(MEDIA_DATE_FORMAT))
}

export const isDateInWorkingDays = ({ allDay, workingDays }, date) => {
  return allDay || workingDays.includes(weekDays[date.day()])
}

export const filterSchedulesByDate = (schedules = [], date) => {
  return schedules.filter(item => {
    if (isDateInScheduleRange(item, date)) {
      if (item.allDate) {
        return true
      } else if (item.specificDates.length) {
        return isDateInSpecificDates(item, date)
      } else {
        return isDateInWorkingDays(item, date)
      }
    } else {
      return false
    }
  })
}

export const parseTimelineData = (response, filter) => {
  return filter
    ? response.filter(({ deviceTitle }) =>
        deviceTitle.toLowerCase().includes(filter.toLowerCase())
      )
    : response
}

export const convertTimeToNumber = time => {
  return time.hour() + time.minutes() / HOUR_MINUTES
}

export const getTimeInfo = schedule => {
  const { allTime, timeSlot } = schedule

  const timeSlotData = timeSlot?.map((slot, i) => ({
    startTime: moment(allTime ? MEDIA_MIN_TIME : slot.startTime, TIME_FORMAT),
    endTime: moment(allTime ? MEDIA_MAX_TIME : slot.endTime, TIME_FORMAT)
  }))

  return {
    timeSlot: timeSlotData
  }
}

export const prepareCloneData = values => {
  const {
    title,
    workingDays,
    allDay,
    allDate,
    allTime,
    endDate,
    deviceList,
    customRecurrence,
    status
  } = values

  return {
    title: `Clone of ${title}`,
    workingDays,
    allDay,
    allDate,
    allTime,
    endDate,
    deviceList: [deviceList],
    customRecurrence,
    status
  }
}

export const calendarQueryHelper = (currentDate, viewName) => {
  let start = moment(currentDate).startOf(viewName.toLowerCase())
  let end = start.clone().endOf(viewName.toLowerCase())

  if (viewName === calendarViews.MONTH) {
    start = moment(start).startOf('week')
    end = moment(end).endOf('week')
  }
  return {
    'startDate-from': start.format(MEDIA_DATE_FORMAT),
    'endDate-to': end.format(MEDIA_DATE_FORMAT)
  }
}

export const getScheduleContentDuration = (scheduleContent, toLabel = true) => {
  const seconds = scheduleContent
    .map(i =>
      i.playtime ? labelToSec(i.duration) * i.playtime : labelToSec(i.duration)
    )
    .reduce((a, b) => a + b, 0)

  return toLabel ? secToLabel(seconds) : seconds
}

export const additionOfHours = (hour1, hour2, handleGreaterThan24 = true) => {
  const total = hour1 + hour2

  if (!handleGreaterThan24 && total >= 24) {
    const parsedEndTime = moment(MEDIA_MAX_TIME, TIME_FORMAT)
    return convertTimeToNumber(parsedEndTime)
  }

  return total % 24
}

export const getStartTimelineHour = timeFormat => {
  const currentHour = new Date().getHours()
  const addHour = currentHour + timeFormat
  return addHour > 24 ? currentHour - (currentHour % timeFormat) : currentHour
}

export const getTimelineCellWidth = (
  timeFormat,
  baseCellWidth = TIMELINE_CELL_WIDTH
) => baseCellWidth * (FALLBACK_MAX_HOURS / timeFormat)

export const getValidDate = (dateString, dateFormat) => {
  return !dateString || dateString === NULL_DATE_BE_STRING
    ? NULL_DATE_FE_STRING
    : moment(dateString).isValid()
    ? moment(dateString, BACKEND_DATE_FORMAT).format(dateFormat)
    : NULL_DATE_FE_STRING
}

export const isSingleDayTable = timeFormat => timeFormat <= 24

export const isSchedulesInDates = (schedules, dates) => {
  return dates.some(date =>
    schedules.some(schedule => isDateInScheduleRange(schedule, date))
  )
}

export const getScheduleLineTopPosition = (index, total, expanded) => {
  const hiddenItemOffset = 2

  const top =
    total > TIMELINE_MAX_VISIBLE_LINES && !expanded
      ? total - index <= TIMELINE_MAX_VISIBLE_LINES
        ? hiddenItemOffset +
          (TIMELINE_MAX_VISIBLE_LINES - (total - index)) * TIMELINE_LINE_OFFSET
        : 0
      : index * TIMELINE_LINE_OFFSET

  return TIMELINE_LINE_PADDING + top
}

export const getScheduleDeviceStatusColor = status => {
  if (status === 'Created') {
    return 'rgba(66,165,245,1)'
  } else if (status === 'Delivered') {
    return 'rgba(255,202,40,1)'
  } else if (status === 'Live') {
    return 'rgba(239,83,80,1)'
  } else if (status === 'Completed') {
    return 'rgba(189,189,189,1)'
  }
}

export const prepareData = (values, dateFormat) => {
  const {
    hiddenContent = [],
    scheduleAudioBackgroundContent = [],
    hiddenAudioBackgroundContent = [],
    recurrenceOption,
    ...restValues
  } = values
  const { scheduleContent, group, tag } = restValues

  const weeksOfMonth =
    recurrenceOption !== 'monthly' ? null : values.weeksOfMonth

  return {
    ...restValues,
    ...ScheduleHelper.parseValuesToData(values),
    scheduleAudioBackgroundContent: [
      ...scheduleAudioBackgroundContent,
      ...hiddenAudioBackgroundContent
    ],
    scheduleContent: [...scheduleContent, ...hiddenContent].map(
      ({ transition, ...i }, index) => ({
        ...i,
        title: i.title,
        playbackContent: i.playbackContent,
        playbackContentId: i.playbackContentId ? i.playbackContentId : i.id,
        duration: getMediaDuration(i, true, MEDIA_FALLBACK_TIME),
        durationTotal: getMediaDuration(i, false, MEDIA_FALLBACK_TIME),
        daypartStartTime: i.daypartStartTime
          ? i.daypartStartTime
          : MEDIA_MIN_TIME,
        daypartEndTime: i.daypartEndTime ? i.daypartEndTime : MEDIA_MAX_TIME,
        repeatTime: i.playtime ? i.playtime : 1,
        sortOrder: index
      })
    ),
    ...(group?.length && {
      group: selectUtils.convertArr(group, selectUtils.fromChipObj)
    }),
    ...(tag?.length && {
      tag: selectUtils.convertArr(tag, selectUtils.fromChipObj)
    }),
    startDate: moment(values.startDate, dateFormat).format(MEDIA_DATE_FORMAT),
    endDate: moment(values.endDate, dateFormat).isValid()
      ? moment(values.endDate, dateFormat).format(MEDIA_DATE_FORMAT)
      : values.endDate,
    ...(values.recurrenceOption === recurrenceTypes.custom &&
      getWorkingDays(values.customRecurrence, values.workingDays)),
    hiddenAudioBackgroundContent: null,
    weeksOfMonth
  }
}

const getWorkingDays = (customRecurrence, workDays) => {
  const intervals = ['day', 'year']
  const checkIntervals = intervals.includes(customRecurrence.interval)
  const checkForMonth =
    customRecurrence.interval === recurrenceIntervals.month &&
    customRecurrence.monthlyRecurrence === recurrenceMonthlyIntervals.day

  const checkCondition = checkIntervals || checkForMonth

  const workingDays = !checkCondition
    ? workDays.toString()
    : allWorkingDays.toString()
  const customRecurrenceUpdated = !checkCondition
    ? { ...customRecurrence, daysOfWeek: workDays.toString() }
    : { ...customRecurrence, daysOfWeek: allWorkingDays.toString() }

  return {
    workingDays,
    customRecurrence: customRecurrenceUpdated
  }
}

export const parseEntityDataToScheduleContent = data => {
  switch (data.resource) {
    case resourceTypes.MediaResource:
    case resourceTypes.MediaGridViewEmbeddedResource:
      return {
        ...data,
        playbackContent: 'Media',
        duration: getPlaylistMediaDuration(data),
        uid: uuidv4()
      }
    case resourceTypes.PlaylistResource:
    case resourceTypes.PlaylistGridEmbeddedViewResource:
      return {
        ...data,
        playbackContent: 'Playlist',
        duration: getPlaylistMediaDuration(data),
        uid: uuidv4(),
        playlistType: data.playlistType || playlistTypes.Standard.name
      }
    case resourceTypes.TemplateResource:
      return {
        ...data,
        playbackContent: 'Template',
        duration: getPlaylistMediaDuration(data),
        uid: uuidv4()
      }
    default:
      return {
        ...data,
        playbackContent: 'Media',
        duration: getPlaylistMediaDuration(data),
        uid: uuidv4()
      }
  }
}

export const getStatusByPublishTab = (data = {}, tab) => {
  switch (tab) {
    case publishTabType.media:
      return getMediaStatus(data)
    case publishTabType.playlist:
      return getPlaylistStatus(data)
    case publishTabType.template:
      return getTemplateStatus(data)
    default:
      return ''
  }
}

export const getStatusByPlaybackContentType = (data = {}, type) => {
  switch (type) {
    case playbackContentType.media:
      return getMediaStatus(data)
    case playbackContentType.playlist:
      return getPlaylistStatus(data)
    case playbackContentType.template:
      return getTemplateStatus(data)
    default:
      return ''
  }
}

export const getUnprocessableStatusesByPublishTab = tab => {
  switch (tab) {
    case publishTabType.media:
      return unprocessableMediaStatuses
    case publishTabType.playlist:
      return unprocessableTemplateStatuses
    case publishTabType.template:
      return unprocessablePlaylistStatuses
    default:
      return []
  }
}

export const getUnprocessableStatusesByPlaybackContentType = type => {
  switch (type) {
    case playbackContentType.media:
      return unprocessableMediaStatuses
    case playbackContentType.playlist:
      return unprocessableTemplateStatuses
    case playbackContentType.template:
      return unprocessablePlaylistStatuses
    default:
      return []
  }
}

export const getPlaybackContentTypeByPublishTab = tab => {
  switch (tab) {
    case publishTabType.media:
      return playbackContentType.media
    case publishTabType.playlist:
      return playbackContentType.playlist
    case publishTabType.template:
      return playbackContentType.template
    default:
      return playbackContentType.media
  }
}

export const getExpiresStatusesByPlaybackContentType = type => {
  switch (type) {
    case playbackContentType.media:
      return expiresMediaStatuses
    case playbackContentType.playlist:
      return expiresPlaylistStatuses
    case playbackContentType.template:
      return expiresTemplateStatuses
    default:
      return []
  }
}

export const deviceParamsModifier = ({
  data,
  withoutNoDeviceAssigned = false
}) => {
  const device = []

  if (data?.length) {
    device.exact = data
      .filter(device => typeof device.value === 'number' && device.value !== -1)
      .map(device => device.value)
      .toString()
    device.search = data
      .filter(device => typeof device.value === 'string')
      .map(({ value }) => value)
      .join(',')
    device.noDevice =
      !withoutNoDeviceAssigned && data.some(({ value }) => value === -1)

    return device
  }
}

export const deviceQueryHelper = device => {
  return {
    ...(device.exact && {
      'device[exact]': device.exact
    }),
    ...(device.search && {
      'device[search]': device.search
    }),
    ...(device.noDevice && {
      'device[noDevice]': device.noDevice
    })
  }
}

export const isAutoAddedDevice = (id, scheduleDevice) => {
  if (!scheduleDevice) {
    return false
  }

  return scheduleDevice.some(
    ({ deviceId, autoAdded }) => deviceId === id && autoAdded
  )
}

export const preventPlaybackItemDelete = ({ target }) => {
  const targetClassNames =
    typeof target?.className === 'string' ? target.className : ''
  const parentClassNames =
    typeof target?.offsetParent?.className === 'string'
      ? target.offsetParent.className
      : ''

  return (
    parentClassNames.includes('MaterialPopupRoot') ||
    parentClassNames.includes('MuiPickersClock') ||
    parentClassNames.includes('MuiPopover') ||
    targetClassNames.includes('disabled') ||
    targetClassNames.includes('pickerInput') ||
    targetClassNames.includes('MuiInput') ||
    targetClassNames.includes('MuiPopover') ||
    ['INPUT', 'BUTTON', 'I', 'svg', 'path'].includes(target?.tagName) ||
    ('DIV' === target?.tagName && !targetClassNames)
  )
}

export const getContentSize = ({ fileSize, size }) => {
  // according to BE media has the 'fileSize' field
  if (fileSize !== undefined) {
    return fileSize
    // according to BE playlists and templates have the 'size' field
  } else if (size !== undefined) {
    return size
  }

  return 0
}

export const prepareTagList = ({ tagList }) => {
  let preparedTagList = []

  tagList.forEach(tag => {
    preparedTagList.push({
      block: tag.block,
      tag: tag.tag.map(item => ({ title: item.label, id: item.value })),
      tagId: tag.tag.map(item => item.value)
    })
  })

  return preparedTagList
}
