import { all, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'
import {
  impersonateSaga,
  loginOktaSaga,
  getSSODataSaga,
  logoutSaga,
  resetSaga,
  updateTokenSaga,
  getImpersonateToken
} from 'sagas/authenticationSaga'
import userSaga from './userSaga'
import mediaSaga from './mediaSaga'
import playlistSaga from './playlistSaga'
import templateSaga from './templateSaga'
import scheduleSaga from './scheduleSaga'
import deviceSaga from './deviceSaga'
import reportSaga from './reportSaga'
import clientSaga from './clientSaga'
import usersSaga from './usersSaga'
import fontsSaga from './fontsSaga'
import feedbackSaga from './feedbackSaga'
import dashboardSaga from './dashboardSaga'
import groupSaga from './groupSaga'
import configSaga from './configSaga'
import clientSettingsSaga from './clientSettingsSaga'
import settingsSaga from './settingsSaga'
import clientPackageSaga from './clientPackageSaga'
import HTMLContentSaga from './HTMLContentSaga'
import devicePackageSaga from './devicePackageSaga'
import * as types from '../actions'
import bandwidthPackageSaga from './bandwidthPackageSaga'
import emailTemplateSaga from './emailTemplateSaga'
import helpSaga from './helpSaga'
import contentAppHelpSaga from './contentAppHelpSaga'
import roleSaga from './roleSaga'
import preferenceSaga from './preferenceSaga'
import whiteLabelSaga from './whiteLabelSaga'
import smartPlaylistSaga from './smartPlaylistSaga'
import quoteSaga from './quoteSaga'
import alertSaga from './alertSaga'
import designGallerySaga from './designGallerySaga'
import resellerClientsSaga from './resellerClientsSaga'
import ipProvidersSaga from './ipProvidersSaga'
import menuMakerSaga from './menuMakerSaga'
import emailNotificationSaga from './emailNotificationSaga'
import resellerClientUserSaga from './resellerClientUserSaga'
import calendarAggregatorSaga from './calendarAggregatorSaga'
import deviceNocSaga from './deviceNocSaga'
import menuDesignSaga from './menuDesignSaga'
import postHogSaga from './postHogSaga'
import googleDocumentSaga from './googleDocumentSaga'
import mediaInvalidateCache from 'helpers/mediaInvalidateCache'

export function* watchIPProvidersSaga() {
  yield takeLatest(types.GET_IP_PROVIDERS, ipProvidersSaga.getIpProviders)
  yield takeLatest(types.PUT_IP_PROVIDERS, ipProvidersSaga.putIpProviders)
}

export function* watchOktaAuthentication() {
  yield takeLatest(types.LOGIN_USER_OKTA, loginOktaSaga)
  yield takeLatest(types.GET_SSO_DATA, getSSODataSaga)
}

export function* watchUser() {
  yield all([
    takeLatest(types.GET_USER_DETAILS, userSaga.getDetails),
    takeLatest(types.PUT_USER_DETAILS, userSaga.putDetails),
    takeLeading(types.GET_USER_IP, userSaga.getUserIp),
    takeLatest(types.PUT_REGIONAL_SETTINGS, userSaga.putRegionalSettings),
    takeLatest(
      types.GET_ORIGINAL_USER_DETAILS,
      userSaga.getOriginalUserDetails
    ),
    takeLatest(types.GET_USER_PROFILE, userSaga.getProfile),
    takeLatest(types.REGENERATE_PIN_CODE, userSaga.regeneratePIN),
    takeLatest(types.POST_USER_BULK, userSaga.postUserBulk)
  ])
}

export function* watchUserLogout() {
  yield takeLatest(types.LOGOUT_USER, logoutSaga)
}

export function* watchUpdateToken() {
  yield takeLatest(types.UPDATE_USER_TOKEN, updateTokenSaga)
}

export function* watchReset() {
  yield takeLatest(types.RESET_PASSWORD, resetSaga)
}

export function* watchImpersonateUser() {
  yield all([
    yield takeLatest(types.IMPERSONATE_USER, impersonateSaga),
    yield takeLatest(types.GET_IMPERSONATE_TOKEN, getImpersonateToken)
  ])
}

export function* watchMedia() {
  yield all([
    takeLatest(types.GET_MEDIA_ITEMS, mediaSaga.getItems),
    takeEvery(types.GET_MEDIA_BY_ID, mediaSaga.getItemById),
    takeLatest(types.GET_MEDIA_PREFERENCE, mediaSaga.getPreference),
    takeLatest(types.PUT_MEDIA_PREFERENCE, mediaSaga.putPreference),
    takeLatest(types.ADD_MEDIA, mediaSaga.addMedia),
    takeLatest(types.GET_MEDIA_PREVIEW, mediaSaga.getMediaPreview),
    takeLatest(types.GENERATE_MEDIA_PREVIEW, mediaSaga.generateMediaPreview),
    takeLatest(types.GET_MEDIA_GROUP_ITEMS, mediaSaga.getGroupItems),
    takeLatest(types.POST_MEDIA_GROUP_ITEM, mediaSaga.postGroupItem),
    takeLatest(types.DELETE_MEDIA_GROUP_ITEM, mediaSaga.deleteGroupItem),
    takeLatest(types.GET_MEDIA_ITEM_BY_ID, mediaSaga.getMediaItemById),
    takeLatest(types.PUT_MEDIA, mediaSaga.putMediaItemById),
    takeLatest(types.CLONE_MEDIA_AND_UPDATE, mediaSaga.cloneMediaAndUpdate),
    takeLatest(types.GET_FEATURE_MEDIA_ITEMS, mediaSaga.getFeatureMediaItems),
    takeEvery(
      types.PUT_ORGANIZE_FEATURE_MEDIA_ITEMS,
      mediaSaga.putOrganizeFeatureMediaItems
    ),
    takeLatest(types.POST_MEDIA_TO_PLAYLISTS, mediaSaga.addMediaToPlaylists),
    takeEvery(types.REQUEST_MEDIA_CAP_ALERT, mediaSaga.getMediaCapAlertWorker),
    takeEvery(types.MEDIA_IPAWS_ALERT, mediaSaga.getMediaIpawsAlert),
    takeLatest(types.POST_FAVORITE_MEDIA, mediaSaga.postFavoriteMedia),
    takeLatest(types.DELETE_SELECTED_MEDIA, mediaSaga.deleteSelectedMedia),
    takeLatest(types.SHARE_MEDIA, mediaSaga.shareMedia),
    takeLatest(types.PUBLISH_DESIGN, mediaSaga.publishDesign),
    takeLatest(types.COPY_MEDIA, mediaSaga.copyMedia),
    takeLatest(types.COPY_DESIGN_TO_SYSTEM, mediaSaga.copyDesignToSystem),
    takeLatest(types.POST_MEDIA_TO_PLAYLIST, mediaSaga.addMediaToPlaylist),
    takeLatest(types.POST_MEDIA_BULK, mediaSaga.postMediaBulk),
    takeLatest(types.CLONE_MEDIA_ITEM, mediaSaga.cloneMedia),
    takeLatest(types.GET_MEDIA_ASSOCIATIONS, mediaSaga.getMediaAssociations),
    takeLatest(types.GET_SFTP_FOLDER, mediaSaga.getSftpFolders),
    // remove after full implementation of RTK Query for Media Library page
    ...mediaInvalidateCache()
  ])
}

export function* watchPlaylist() {
  yield all([
    takeLatest(types.POST_PLAYLIST, playlistSaga.addPlaylist),
    takeLatest(types.PUT_PLAYLIST, playlistSaga.putPlaylistById),
    takeLatest(types.DELETE_PLAYLIST, playlistSaga.deletePlaylistById),
    takeLatest(types.APPROVE_PLAYLIST, playlistSaga.approvePlaylist),
    takeLatest(
      types.DELETE_SELECTED_PLAYLIST,
      playlistSaga.deleteSelectedPlaylist
    ),
    takeLatest(types.GET_PLAYLIST_BY_ID, playlistSaga.getPlaylistById),
    takeLatest(types.GET_PLAYLIST_ITEMS, playlistSaga.getItems),
    takeLatest(types.GET_PLAYLIST_PREFERENCE, playlistSaga.getPreference),
    takeLatest(types.PUT_PLAYLIST_PREFERENCE, playlistSaga.putPreference),
    takeLatest(types.GET_PLAYLIST_GROUP_ITEMS, playlistSaga.getGroupItems),
    takeLatest(types.POST_PLAYLIST_GROUP_ITEM, playlistSaga.postGroupItem),
    takeLatest(types.DELETE_PLAYLIST_GROUP_ITEM, playlistSaga.deleteGroupItem),
    takeLatest(types.CLONE_PLAYLIST, playlistSaga.clonePlaylist),
    takeLatest(types.GET_PLAYLIST_PREVIEW, playlistSaga.getPlaylistPreview),
    takeLatest(
      types.GENERATE_PLAYLIST_PREVIEW,
      playlistSaga.generatePlaylistPreview
    ),
    takeLatest(types.SHARE_PLAYLIST, playlistSaga.sharePlaylist),
    takeLatest(types.COPY_PLAYLIST, playlistSaga.copyPlaylist),
    takeLatest(
      types.GET_PLAYLIST_GROUP_PLAYLISTS,
      playlistSaga.getGroupPlaylists
    ),
    takeLatest(
      types.GET_ASSIGNABLE_PLAYLIST,
      playlistSaga.getAssignablePlaylist
    ),
    takeLatest(types.GET_MODAL_PLAYLIST_ITEMS, playlistSaga.getModalItems),
    takeLatest(
      types.GET_PLAYLIST_ASSOCIATIONS,
      playlistSaga.getPlaylistAssociations
    ),
    takeLatest(types.POST_PLAYLIST_BULK, playlistSaga.postPlaylistBulk)
  ])
}

export function* watchTemplate() {
  yield all([
    takeLatest(types.APPROVE_TEMPLATE, templateSaga.approveTemplate),
    takeLatest(types.POST_TEMPLATE, templateSaga.postTemplate),
    takeLatest(types.GET_TEMPLATE, templateSaga.getTemplate),
    takeLatest(types.PUT_TEMPLATE, templateSaga.editTemplate),
    takeLatest(types.GET_TEMPLATE_ITEMS, templateSaga.getItems),
    takeLatest(types.GET_TEMPLATE_PREFERENCE, templateSaga.getPreference),
    takeLatest(types.PUT_TEMPLATE_PREFERENCE, templateSaga.putPreference),
    takeLatest(types.GET_TEMPLATE_GROUP_ITEMS, templateSaga.getGroupItems),
    takeLatest(types.POST_TEMPLATE_GROUP_ITEM, templateSaga.postGroupItem),
    takeLatest(types.DELETE_TEMPLATE_GROUP_ITEM, templateSaga.deleteGroupItem),
    takeLatest(types.CLONE_TEMPLATE, templateSaga.cloneTemplate),
    takeLatest(
      types.DELETE_SELECTED_TEMPLATE,
      templateSaga.deleteSelectedTemplate
    ),
    takeLatest(types.DELETE_TEMPLATE, templateSaga.deleteTemplateById),
    takeLatest(types.GET_TEMPLATE_PREVIEW, templateSaga.getTemplatePreview),
    takeLatest(
      types.GENERATE_TEMPLATE_PREVIEW,
      templateSaga.generateTemplatePreview
    ),
    takeLatest(types.SHARE_TEMPLATE, templateSaga.shareTemplate),
    takeLatest(types.COPY_TEMPLATE, templateSaga.copyTemplate),
    takeLatest(
      types.GET_TEMPLATE_BACKGROUNDS,
      templateSaga.getTemplateBackgrounds
    ),
    takeLatest(
      types.GET_TEMPLATE_BACKGROUND,
      templateSaga.getTemplateBackground
    ),
    takeLatest(
      types.POST_TEMPLATE_BACKGROUND,
      templateSaga.postTemplateBackground
    ),
    takeLatest(
      types.PUT_TEMPLATE_BACKGROUND,
      templateSaga.putTemplateBackground
    ),
    takeLatest(
      types.GET_TEMPLATE_GROUP_TEMPLATES,
      templateSaga.getGroupTemplates
    ),
    takeEvery(types.GET_TEMPLATE_ITEM_PREVIEW, templateSaga.getItemPreview),
    takeEvery(
      types.GET_TEMPLATE_ZONE_PREVIEW,
      templateSaga.getTemplateZonePreview
    ),
    takeEvery(
      types.GENERATE_TEMPLATE_ITEM_PREVIEW,
      templateSaga.generateItemPreview
    ),
    takeLatest(types.GET_TEMPLATE_MEDIA, templateSaga.getTemplateMedia),
    takeLatest(types.GET_TEMPLATE_PLAYLIST, templateSaga.getTemplatePlaylist),
    takeLatest(
      types.GET_TEMPLATE_BACKGROUND_IMAGE,
      templateSaga.getTemplateBackgroundImage
    ),
    takeEvery(
      types.GET_TOUCH_ZONE_TEMPLATE_ITEM,
      templateSaga.getTouchZoneTemplateItem
    ),
    takeLatest(
      types.GET_TEMPLATE_ASSOCIATIONS,
      templateSaga.getTemplateAssociations
    ),
    takeLatest(
      types.BULK_UPDATE_TEMPLATE_ITEM,
      templateSaga.bulkUpdateTemplateItem
    ),
    takeLatest(
      types.GET_TEMPLATE_GALLERY_ITEMS,
      templateSaga.getTemplateGalleryItems
    ),
    takeLatest(
      types.GET_TEMPLATE_GALLERY_BY_ID,
      templateSaga.getTemplateGalleryById
    ),
    takeLatest(
      types.GET_TEMPLATE_GALLERY_PREVIEW,
      templateSaga.getTemplateGalleryPreview
    ),
    takeLatest(types.COPY_TEMPLATE_GALLERY, templateSaga.copyTemplateGallery),
    takeLatest(
      types.BULK_TEMPLATE_GALLERY_COPY,
      templateSaga.bulkTemplateGalleryCopy
    ),
    takeLatest(types.POST_TEMPLATE_BULK, templateSaga.postTemplateBulk)
  ])
}

export function* watchSchedule() {
  yield all([
    takeLatest(types.POST_SCHEDULE, scheduleSaga.postSchedule),
    takeLatest(types.GET_SCHEDULE_BY_ID, scheduleSaga.getSchedule),
    takeLatest(types.PUT_SCHEDULE, scheduleSaga.editSchedule),
    takeLatest(types.GET_SCHEDULE_ITEMS, scheduleSaga.getItems),
    takeLatest(types.GET_SCHEDULE_LISTS, scheduleSaga.getListSchedule),
    takeLatest(types.GET_SCHEDULE_TIMELINE, scheduleSaga.getTimeline),
    takeLatest(types.GET_SCHEDULE_PREFERENCE, scheduleSaga.getPreference),
    takeLatest(types.PUT_SCHEDULE_PREFERENCE, scheduleSaga.putPreference),
    takeLatest(types.GET_SCHEDULE_GROUP_ITEMS, scheduleSaga.getGroupItems),
    takeLatest(types.POST_SCHEDULE_GROUP_ITEM, scheduleSaga.postGroupItem),
    takeLatest(types.DELETE_SCHEDULE_GROUP_ITEM, scheduleSaga.deleteGroupItem),
    takeLatest(
      types.DELETE_SELECTED_SCHEDULES,
      scheduleSaga.deleteSelectedSchedules
    ),
    takeLatest(types.CLONE_SCHEDULE, scheduleSaga.cloneSchedule),
    takeLatest(types.COPY_SCHEDULE, scheduleSaga.copySchedule),
    takeLatest(types.DELETE_SCHEDULE, scheduleSaga.deleteScheduleById),
    takeLatest(types.APPROVE_SCHEDULE, scheduleSaga.approveSchedule),
    takeLatest(types.GET_SCHEDULE_PREVIEW, scheduleSaga.getSchedulePreview),
    takeLatest(
      types.GENERATE_SCHEDULE_PREVIEW,
      scheduleSaga.generateSchedulePreview
    ),
    takeLatest(
      types.GENERATE_SMART_SCHEDULE_PREVIEW,
      scheduleSaga.generateSmartSchedulePreview
    ),
    takeLatest(
      types.GET_SCHEDULE_GROUP_SCHEDULES,
      scheduleSaga.getGroupSchedules
    ),
    takeLatest(
      types.ASSIGN_DEVICES_SCHEDULE,
      scheduleSaga.assignDevicesSchedule
    ),
    takeLatest(
      types.GET_ASSIGNABLE_SCHEDULE,
      scheduleSaga.getAssignableSchedule
    ),
    takeLatest(types.PATCH_SCHEDULE_ITEM, scheduleSaga.patchScheduleItem),
    takeLatest(
      types.CLONE_SCHEDULE_TIMELINE,
      scheduleSaga.cloneScheduleTimeline
    ),
    takeLatest(types.GET_CALENDAR_SCHEDULES, scheduleSaga.getCalendarSchedules),
    takeLatest(types.POST_SCHEDULE_BULK, scheduleSaga.postScheduleBulk)
  ])
}

export function* watchDevice() {
  yield all([
    takeLatest(types.GET_DEVICE_RESOLUTION, deviceSaga.getDeviceResolutions),
    takeLatest(types.GET_DEVICE_ITEMS, deviceSaga.getItems),
    takeLatest(types.GET_DEVICE_PREFERENCE, deviceSaga.getPreference),
    takeLatest(types.PUT_DEVICE_PREFERENCE, deviceSaga.putPreference),
    takeLatest(types.GET_DEVICE_GROUPS, deviceSaga.getGroups),
    takeLatest(types.PUT_DEVICE_ITEM, deviceSaga.putItem),
    takeLatest(types.POST_DEVICE_ITEM, deviceSaga.postItem),
    takeLatest(types.GET_DEVICE_ITEM, deviceSaga.getItem),
    takeLatest(types.GET_DEVICE_PREVIEW, deviceSaga.getPreview),
    takeLatest(
      types.GET_DEVICE_LOCATION_CATEGORIES,
      deviceSaga.getDeviceLocationCategories
    ),
    takeLatest(types.GET_DEVICE_GROUP_ITEMS, deviceSaga.getGroupItems),
    takeLatest(types.POST_DEVICE_GROUP_ITEM, deviceSaga.postGroupItem),
    takeLatest(types.DELETE_DEVICE_GROUP_ITEM, deviceSaga.deleteGroupItem),
    takeLatest(types.GET_DEVICE_REBOOT, deviceSaga.getReboot),
    takeLatest(types.PUT_DEVICE_REBOOT, deviceSaga.putReboot),
    takeLatest(
      types.PUT_PERIODICAL_DEVICE_REBOOT,
      deviceSaga.putPeriodicalReboot
    ),
    takeLatest(
      types.GET_SCREEN_POWER_SETTINGS,
      deviceSaga.getScreenPowerSettings
    ),
    takeLatest(
      types.PUT_SCREEN_POWER_SETTINGS,
      deviceSaga.putScreenPowerSettings
    ),
    takeLatest(types.PUT_DEVICE_BULK_REBOOT, deviceSaga.putBulkReboot),
    takeLatest(
      types.PUT_DEVICE_BULK_PERIODICAL_REBOOT,
      deviceSaga.putBulkPeriodicalReboot
    ),
    takeLatest(types.GET_DEVICE_POWER_SCHEDULE, deviceSaga.getPowerSchedule),
    takeLatest(types.PUT_DEVICE_POWER_SCHEDULE, deviceSaga.putPowerSchedule),
    takeEvery(types.GET_DEVICE_NOTES, deviceSaga.getDeviceNotes),
    takeLatest(types.POST_DEVICE_NOTE, deviceSaga.postDeviceNote),
    takeLatest(types.POST_DEVICE_BULK, deviceSaga.postDeviceBulk),
    takeEvery(
      types.REQUEST_CAP_ALERT_DEVICES,
      deviceSaga.getCapAlertDevicesWorker
    ),
    takeLatest(types.GET_DEVICE_TYPES, deviceSaga.getDeviceTypes),
    takeLatest(types.GET_DEVICE_TYPE, deviceSaga.getDeviceType),
    takeLatest(types.PUT_DEVICE_TYPE, deviceSaga.putDeviceType),
    takeLatest(types.POST_DEVICE_TYPE, deviceSaga.postDeviceType),
    takeLatest(types.GET_DEVICE_MODELS, deviceSaga.getDeviceModels),
    takeLatest(types.GET_DEVICE_MODEL, deviceSaga.getDeviceModel),
    takeLatest(types.PUT_DEVICE_MODEL, deviceSaga.putDeviceModel),
    takeLatest(types.POST_DEVICE_MODEL, deviceSaga.postDeviceModel),
    takeLatest(types.GET_APP_VERSIONS, deviceSaga.getAppVersions),
    takeLatest(
      types.GET_RELEASE_APP_VERSIONS,
      deviceSaga.getReleaseAppVersions
    ),
    takeLatest(types.GET_APP_VERSION, deviceSaga.getAppVersion),
    takeLatest(types.PUT_APP_VERSION, deviceSaga.putAppVersion),
    takeLatest(types.POST_APP_VERSION, deviceSaga.postAppVersion),
    takeLatest(types.DELETE_APP_VERSION, deviceSaga.deleteAppVersion),
    takeLatest(
      types.DELETE_SELECTED_APP_VERSIONS,
      deviceSaga.deleteSelectedAppVersions
    ),
    takeLatest(types.POST_ASSIGN_APP_VERSION, deviceSaga.postAssignAppVersion),
    takeLatest(
      types.POST_ASSIGN_APP_VERSION_TO_DEVICE_TYPE,
      deviceSaga.postAssignAppVersionToDeviceType
    ),
    takeLatest(types.GET_DEVICE_HEALTH, deviceSaga.getDeviceHealth),
    takeLatest(
      types.GET_DEVICE_UPDATES_AVAILABLE,
      deviceSaga.getDeviceUpdatesAvailable
    ),
    takeLatest(types.GET_DEVICE_GROUP_DEVICES, deviceSaga.getGroupDevices),
    takeLatest(
      types.GET_DEVICES_OFFLINE_NOTIFICATION,
      deviceSaga.getOfflineNotificationDevices
    ),
    takeLatest(
      types.GET_DEVICE_ITEM_OFFLINE_NOTIFICATION,
      deviceSaga.getOfflineNotificationDeviceItem
    ),
    takeLatest(
      types.POST_DEVICES_OFFLINE_NOTIFICATION,
      deviceSaga.postOfflineNotificationDeviceItem
    ),
    takeLatest(types.GET_DEVICE_LOGS, deviceSaga.getDeviceLogs),
    takeLatest(types.DELETE_DEVICE_ITEM, deviceSaga.deleteItem),
    takeLatest(types.CLONE_DEVICE_ITEM, deviceSaga.cloneDeviceItem),
    takeLatest(types.UPDATE_SCHEDULE_DEVICE, deviceSaga.updateScheduleDevice),
    takeLatest(types.GET_DEVICES_BY_FOLDER, deviceSaga.getDevicesByFolder),
    takeLatest(types.GET_DEVICE_LOCATIONS, deviceSaga.getDeviceLocations),
    takeLatest(types.POST_DEVICE_LOCATION, deviceSaga.postDeviceLocation),
    takeLatest(types.PUT_DEVICE_LOCATION, deviceSaga.putDeviceLocation),
    takeLatest(types.DELETE_DEVICE_LOCATION, deviceSaga.deleteDeviceLocation),
    takeLatest(types.GET_DEVICES_BY_LOCATION, deviceSaga.getDevicesByLocation),
    takeLatest(
      types.GET_SCHEDULED_DEVICE_IDS,
      deviceSaga.getScheduledDeviceIds
    ),
    takeLatest(types.GET_DEVICE_CEC_SETTINGS, deviceSaga.getDeviceCecSettings),
    takeLatest(types.PUT_DEVICE_CEC_SETTINGS, deviceSaga.putDeviceCecSettings),
    takeLatest(
      types.GET_DEVICE_NETWORK_CONFIG,
      deviceSaga.getDeviceNetworkConfig
    ),
    takeLatest(
      types.PUT_DEVICE_NETWORK_CONFIG,
      deviceSaga.putDeviceNetworkConfig
    ),
    takeLatest(
      types.GET_DEVICE_VIRTUAL_PLAYER_STATS,
      deviceSaga.getVirtualPlayerStats
    ),
    takeLatest(types.POST_IP_CIDR, deviceSaga.postIpCIDR),
    takeLatest(types.PUT_IP_CIDR, deviceSaga.putIpCIDR),
    takeLatest(types.DELETE_IP_CIDR, deviceSaga.deleteIpCIDR),
    takeLatest(types.GET_IP_CIDR, deviceSaga.getIpCIDR),
    takeLatest(
      types.GET_DEVICE_DEPENDENCY_PACKAGES,
      deviceSaga.getDeviceDependencyPackages
    ),
    takeLatest(
      types.GET_DEVICE_GLOBAL_CONFIG,
      deviceSaga.getDeviceGlobalConfig
    ),
    takeLatest(
      types.PUT_DEVICE_GLOBAL_CONFIG,
      deviceSaga.putDeviceGlobalConfig
    ),
    takeLatest(
      types.GET_PROOF_OF_PLAY_DEVICES,
      deviceSaga.getProofOfPlayDevices
    ),
    takeLatest(
      types.POST_FEATURE_DEVICE_ASSOCIATE,
      deviceSaga.featureDeviceAssociate
    )
  ])
}

export function* watchReport() {
  yield all([
    takeLatest(types.GET_REPORT_ITEMS, reportSaga.getItems),
    takeLatest(types.GET_REPORT_TABLES, reportSaga.getReportTables),
    takeLatest(types.POST_RUN_REPORT, reportSaga.runReport),
    takeLatest(types.POST_REPORT, reportSaga.postReport),
    takeLatest(types.PUT_REPORT, reportSaga.putReport),
    takeLatest(types.GET_REPORT_ITEM, reportSaga.getReport),
    takeLatest(types.GET_REPORT_TEMPLATE, reportSaga.getTemplateReport),
    takeLatest(
      types.GET_TEMPLATE_WITH_REPORT_DATA,
      reportSaga.getTemplateWithReportData
    ),
    takeEvery(types.GET_COUNT_API_CALL, reportSaga.getCountApiCall),
    takeEvery(types.GET_LAST_FAILED_API_CALL, reportSaga.getLastFailedApiCall),
    takeLatest(
      types.GET_COUNT_CLIENT_API_CALL,
      reportSaga.getCountClientApiCall
    ),
    takeLatest(
      types.GET_COUNT_DEVICE_API_CALL,
      reportSaga.getCountDeviceApiCall
    ),
    takeLatest(types.GET_TOP_CLIENTS_API_CALL, reportSaga.getTopClientsApiCall),
    takeLatest(types.GET_TOP_DEVICES_API_CALL, reportSaga.getTopDevicesApiCall),
    takeLatest(types.GET_REPORT_SCHEDULE, reportSaga.getReportSchedule),
    takeLatest(types.POST_REPORT_SCHEDULE, reportSaga.postReportSchedule),
    takeEvery(types.GET_REPORT_LINK, reportSaga.getReportLink),
    takeLatest(types.GET_TOP_DEVICES_API_CALL, reportSaga.getTopDevicesApiCall),
    takeLatest(types.GET_EXPOSURE_COUNT, reportSaga.getExposureCount),
    takeLatest(types.GET_EXPOSURE_TIME, reportSaga.getExposureTime),
    takeLatest(types.GET_VERBOSE_DATA, reportSaga.getVerboseData)
  ])
}

export function* watchClient() {
  yield all([
    takeLatest(types.GET_CLIENTS, clientSaga.getClients),
    takeLatest(types.GET_CLIENT_BY_ID, clientSaga.getClientById),
    takeLatest(types.POST_CLIENT, clientSaga.postClient),
    takeLatest(types.PUT_CLIENT, clientSaga.putClient),
    takeLatest(types.GET_CLIENT_GROUP_ITEMS, clientSaga.getGroupItems),
    takeLatest(types.POST_CLIENT_GROUP_ITEM, clientSaga.postGroupItem),
    takeLatest(types.DELETE_CLIENT_GROUP_ITEM, clientSaga.deleteGroupItem),
    takeLatest(types.GET_CLIENT_NOTES, clientSaga.getClientNotes),
    takeLatest(types.POST_CLIENT_NOTE, clientSaga.postClientNote),
    takeLatest(types.GET_CLIENT_LICENSES, clientSaga.getClientLicenses),
    takeLatest(types.POST_CLIENT_LICENSE, clientSaga.postClientLicense),
    takeLatest(types.PUT_CLIENT_LICENSE, clientSaga.putClientLicenseById),
    takeLatest(types.DELETE_CLIENT_LICENSE, clientSaga.deleteClientLicenseById),
    takeLatest(types.PUT_CLIENT_BILLING, clientSaga.putClientBilling),
    takeLatest(
      types.EDIT_CLIENT_FEATURE_SETTINGS,
      clientSaga.editClientFeatureSettings
    ),
    takeLatest(types.POST_CLIENT_BULK, clientSaga.postClientBulk)
  ])
}

export function* watchUsers() {
  yield all([
    takeLatest(types.GET_USERS_ITEMS, usersSaga.getItems),
    takeLatest(types.POST_USERS_ITEM, usersSaga.postItem),
    takeLatest(types.DELETE_USERS_ITEM, usersSaga.deleteItem),
    takeLatest(types.DELETE_SELECTED_USERS, usersSaga.deleteSelectedItems),
    takeLatest(types.GET_USERS_ITEM, usersSaga.getItem),
    takeLatest(types.PUT_USERS_ITEM, usersSaga.putItem),
    takeLatest(types.GET_UNGROUPED_USERS, usersSaga.getUngroupedUsers),
    takeLatest(types.GET_USERS_GROUP_ITEMS, usersSaga.getUsersGroupItems),
    takeLatest(types.POST_USERS_GROUP_ITEM, usersSaga.postUsersGroupItem),
    takeLatest(types.DELETE_USERS_GROUP_ITEM, usersSaga.deleteUsersGroupItem),
    takeEvery(types.GET_USERS_PERMISSION, usersSaga.getUsersPermission),
    takeLatest(types.PUT_USERS_PERMISSION, usersSaga.putUsersPermission),
    takeLatest(types.REGENERATE_USER_PIN_CODE, usersSaga.regenerateUserPIN),
    takeEvery(
      types.GET_USERS_GROUP_PERMISSION,
      usersSaga.getUsersGroupPermission
    ),
    takeLatest(
      types.PUT_USERS_GROUP_PERMISSION,
      usersSaga.putUsersGroupPermission
    )
  ])
}

export function* watchResellerClients() {
  yield all([
    takeLatest(types.GET_RESELLER_CLIENTS, resellerClientsSaga.getItems),
    takeLatest(
      types.GET_ASSOCIATED_CLIENTS,
      resellerClientsSaga.getAssociatedClients
    ),
    takeEvery(
      types.POST_ASSOCIATED_CLIENTS,
      resellerClientsSaga.postAssociatedClients
    )
  ])
}

export function* watchResellerClientUsers() {
  yield all([
    takeLatest(
      types.GET_RESELLER_CLIENT_USERS,
      resellerClientUserSaga.getItems
    ),
    takeLatest(
      types.IMPERSONATE_RESELLER_CLIENT,
      resellerClientUserSaga.impersonateUser
    )
  ])
}

export function* watchFeedback() {
  yield all([takeLatest(types.POST_FEEDBACK, feedbackSaga.postInfo)])
}

export function* watchDashboard() {
  yield all([
    takeLatest(types.GET_DASHBOARD_INFO, dashboardSaga.getInfo),
    takeLatest(types.PUT_DASHBOARD_INFO, dashboardSaga.putInfo),
    takeLatest(types.GET_NEWS_INFO_FOR_DASHBOARD, dashboardSaga.getNewsInfo),
    takeLatest(
      types.GET_SUPPORT_INFO_FOR_DASHBOARD,
      dashboardSaga.getSupportInfo
    ),
    takeLatest(
      types.GET_DASHBOARD_DEVICE_LOCATIONS,
      dashboardSaga.getDashboardDeviceLocations
    ),
    takeLatest(
      types.GET_WEATHER_INFO_FOR_DASHBOARD,
      dashboardSaga.getWeatherInfo
    ),
    takeLatest(
      types.GET_DASHBOARD_TODAY_SCHEDULES,
      dashboardSaga.getTodaySchedules
    )
  ])
}

export function* watchGroups() {
  yield all([
    takeEvery(types.GET_GROUPS_BY_ENTITY, groupSaga.getGroupByEntity),
    takeLatest(types.POST_GROUP, groupSaga.postGroup),
    takeLatest(types.DELETE_GROUP, groupSaga.deleteGroup),
    takeLatest(types.PUT_GROUP, groupSaga.putGroup),
    takeLatest(types.GET_GROUP_PERMISSION, groupSaga.getGroupPermission),
    takeLatest(types.PUT_GROUP_PERMISSION, groupSaga.putGroupPermission),
    takeLatest(types.GET_DEFAULT_GROUP, groupSaga.getDefaultGroup)
  ])
}

export function* watchConfig() {
  yield all([
    takeLatest(types.GET_CONFIG_ORG_ROLE, configSaga.getConfigOrgRole),
    takeLatest(
      types.GET_CONFIG_ENTERPRISE_ROLE,
      configSaga.getConfigEnterpriseRole
    ),
    takeLatest(
      types.GET_CONFIG_RESELLER_ROLE,
      configSaga.getConfigResellerRole
    ),
    takeLatest(types.GET_CONFIG_SYSTEM_ROLE, configSaga.getConfigSystemRole),
    takeLatest(types.GET_CONFIG_CLIENT_TYPE, configSaga.getConfigClientType),
    takeLatest(types.GET_CONFIG_DEVICE_TYPE, configSaga.getConfigDeviceType),
    takeLatest(
      types.GET_CONFIG_FEATURE_CLIENT,
      configSaga.getConfigFeatureClient
    ),
    takeLatest(
      types.GET_CONFIG_FEATURE_DEVICE,
      configSaga.getConfigFeatureDevice
    ),
    takeLatest(
      types.GET_CONFIG_FEATURE_MEDIA,
      configSaga.getConfigFeatureMedia
    ),
    takeLeading(
      types.GET_CONFIG_MEDIA_CATEGORY,
      configSaga.getConfigMediaCategory
    ),
    takeLatest(
      types.GET_THEME_OF_MEDIA_FEATURE_BY_ID,
      configSaga.getThemeOfMediaFeatureById
    ),
    takeLatest(
      types.GET_CONTENT_SOURCE_OF_MEDIA_FEATURE_BY_ID,
      configSaga.getContentSourceOfMediaFeatureById
    ),
    takeLatest(types.GET_LOCATION, configSaga.getLocation),
    takeLatest(types.GET_CITY, configSaga.getCity),
    takeLatest(types.GET_CONFIG_TRANSITIONS, configSaga.getTransitions),
    takeLatest(types.GET_CONFIG_ALERT_TYPES, configSaga.getAlertTypes),
    takeLatest(types.GET_AIRLINES, configSaga.getAirlines),
    takeLatest(types.GET_AIRPORTS, configSaga.getAirports),
    takeLatest(types.GET_LOCATION_INFO, configSaga.getLocationInfo),
    takeLatest(types.GET_BACKGROUND_PATTERNS, configSaga.getBackgroundPattern),
    takeLatest(
      types.GET_BACKGROUND_PATTERN_BY_ID,
      configSaga.getBackgroundPatternById
    ),
    takeLatest(types.GET_CONFIG_CURRENCY, configSaga.getCurrency),
    takeLatest(types.GET_YOUTUBE_CAPTIONS, configSaga.getYoutubeCaptions),
    takeLatest(types.GET_OAUTH2_TOKENS, configSaga.getOauth2Tokens),
    takeLatest(types.POST_FACEBOOK_ACCOUNT, configSaga.postFacebookAccount),
    takeLatest(types.POST_INSTAGRAM_ACCOUNT, configSaga.postInstagramAccount),
    takeLatest(
      types.POST_GOOGLE_DOCS_ACCOUNT,
      configSaga.postGoogleDocsAccount
    ),
    takeLatest(types.PUT_OAUTH2_TOKEN, configSaga.putOauth2Token),
    takeLatest(types.DELETE_OAUTH2_TOKEN, configSaga.deleteOauth2Token),
    takeLatest(types.GET_POWERBI_TOKEN, configSaga.getPowerBiToken),
    takeLatest(types.POWERBI_LOGOUT, configSaga.powerBiLogout),
    takeLatest(types.GET_POWERBI_DASHBOARDS, configSaga.getPowerBiDashboards),
    takeLatest(types.GET_MS_TEAMS_CHAT_TOKEN, configSaga.getMsTeamsChatToken),
    takeLatest(
      types.GET_MS_TEAMS_CHAT_CHANNELS,
      configSaga.getMsTeamsChatChannels
    ),
    takeLatest(types.MS_TEAMS_CHAT_LOGOUT, configSaga.msTeamsChatLogout),

    takeLatest(types.GET_FACEBOOK_PAGES, configSaga.getFacebookPages),
    takeLatest(types.GET_APP_CONFIG, configSaga.getAppConfig),
    takeLatest(types.GET_FIPS_LOCATION, configSaga.getFipsLocation),
    takeLatest(types.GET_IPAWS_FEEDS, configSaga.getIpawsFeeds),

    takeLatest(types.GET_KLIPFOLIO_TOKEN, configSaga.getKlipfolioToken),
    takeLatest(types.KLIPFOLIO_LOGOUT, configSaga.klipfolioLogout),
    takeLatest(
      types.GET_KLIPFOLIO_DASHBOARDS,
      configSaga.getKlipfolioDashboards
    ),
    takeLatest(types.CONTENT_APPROVE, configSaga.approveContent),
    takeLatest(
      types.GET_ALL_CONFIG_FEATURE_MEDIA,
      configSaga.getAllConfigFeatureMedia
    ),
    takeLatest(types.GET_AIRPORT_GATE, configSaga.getAirportGate),
    takeLatest(types.GET_AIRPORT_BAGGAGE, configSaga.getAirportBaggage),
    takeLatest(types.GET_SHORELINE_EVENTS, configSaga.getShorelineEvents),
    takeLatest(types.GET_SHORELINE_PRICE, configSaga.getShorelinePrice),
    takeLatest(types.POST_SHORELINE_PRICE, configSaga.uploadShorelinePrice),
    takeLatest(
      types.POST_GOOGLE_CALENDAR_ACCOUNT,
      configSaga.postGoogleCalendarAccount
    ),
    takeLatest(
      types.POST_MICROSOFT_OUTLOOK_ACCOUNT,
      configSaga.postMicrosoftOutlookAccount
    ),
    takeLatest(types.GET_GEO_LOCATION_FROM_IP, configSaga.getGeoLocationFromIp),
    takeLatest(types.GET_GLOBAL_SEARCH_ITEMS, configSaga.getGlobalSearchItems),
    takeLatest(
      types.GET_CONTENT_MODERATION_LABELS,
      configSaga.getContentModerationLabels
    ),
    takeLatest(types.CONTENT_REJECT, configSaga.rejectContent),
    takeLatest(
      types.CONTENT_REJECT_AND_DELETE,
      configSaga.rejectAndDeleteContent
    ),
    takeLatest(
      types.GET_AI_VIDEO_AVATARS_LIST,
      configSaga.getAIVideoAvatarsList
    ),
    takeLatest(types.GET_AI_VIDEO_VOICES_LIST, configSaga.getAIVideoVoicesList),
    takeLatest(
      types.GET_AI_VIDEO_PRESET_BACKGROUNDS_LIST,
      configSaga.getAIVideoPresetBackgroundsList
    )
  ])
}

export function* watchFonts() {
  yield all([
    takeLatest(types.GET_GOOGLE_FONTS, fontsSaga.getGoogleFonts),
    takeLatest(types.GET_SAVED_FONTS, fontsSaga.getSavedFonts),
    takeLatest(types.GET_FONTS, fontsSaga.getFonts),
    takeLatest(types.DELETE_FONT, fontsSaga.deleteFont),
    takeLatest(types.DELETE_SELECTED_FONTS, fontsSaga.deleteSelectedFonts),
    takeLatest(types.POST_FONT, fontsSaga.postFont),
    takeLatest(types.FETCH_GOOGLE_FONTS, fontsSaga.fetchFonts)
  ])
}

export function* watchClientSettings() {
  yield all([
    takeLatest(types.PUT_CLIENT_SETTINGS, clientSettingsSaga.putClientSettings),
    takeLatest(
      types.PUT_CLIENT_FEATURE_SETTINGS,
      clientSettingsSaga.putClientFeatureSettings
    ),
    takeLatest(types.POST_SSO_DETAILS, clientSettingsSaga.postSsoDetails),
    takeLatest(types.PUT_SSO_DETAILS, clientSettingsSaga.putSsoDetails)
  ])
}

export function* watchSettings() {
  yield all([
    takeLatest(types.GET_SETTINGS, settingsSaga.getSettings),
    takeLatest(types.PUT_SETTINGS, settingsSaga.putSettings)
  ])
}

export function* watchHTMLContent() {
  yield all([
    takeLatest(types.GET_HTML_CONTENTS, HTMLContentSaga.getHTMLContents),
    takeLatest(types.POST_HTML_CONTENT, HTMLContentSaga.postHTMLContent),
    takeLatest(types.DELETE_HTML_CONTENT, HTMLContentSaga.deleteHTMLContent),
    takeLatest(
      types.DELETE_SELECTED_HTML_CONTENTS,
      HTMLContentSaga.deleteSelectedHTMLContents
    ),
    takeLatest(types.GET_HTML_CONTENT_BY_ID, HTMLContentSaga.getHTMLContent),
    takeLatest(types.PUT_HTML_CONTENT, HTMLContentSaga.putHTMLContent)
  ])
}

export function* watchClientPackage() {
  yield all([
    takeLatest(types.GET_CLIENT_PACKAGES, clientPackageSaga.getClientPackages),
    takeLatest(
      types.GET_CLIENT_PACKAGE_BY_ID,
      clientPackageSaga.getClientPackageById
    ),
    takeLatest(types.POST_CLIENT_PACKAGE, clientPackageSaga.postClientPackage),
    takeLatest(types.PUT_CLIENT_PACKAGE, clientPackageSaga.putClientPackage),
    takeLatest(
      types.DELETE_SELECTED_CLIENT_PACKAGES,
      clientPackageSaga.deleteSelectedClientPackages
    ),
    takeLatest(
      types.DELETE_CLIENT_PACKAGE,
      clientPackageSaga.deleteClientPackage
    )
  ])
}

export function* watchDevicePackage() {
  yield all([
    takeLatest(types.GET_DEVICE_PACKAGES, devicePackageSaga.getDevicePackages),
    takeLatest(
      types.GET_DEVICE_PACKAGE_BY_ID,
      devicePackageSaga.getDevicePackageById
    ),
    takeLatest(types.POST_DEVICE_PACKAGE, devicePackageSaga.postDevicePackage),
    takeLatest(types.PUT_DEVICE_PACKAGE, devicePackageSaga.putDevicePackage),
    takeLatest(
      types.DELETE_SELECTED_DEVICE_PACKAGES,
      devicePackageSaga.deleteSelectedDevicePackages
    ),
    takeLatest(
      types.DELETE_DEVICE_PACKAGE,
      devicePackageSaga.deleteDevicePackage
    )
  ])
}

export function* watchBandwidthPackage() {
  yield all([
    takeLatest(
      types.GET_BANDWIDTH_PACKAGES,
      bandwidthPackageSaga.getBandwidthPackages
    ),
    takeLatest(
      types.GET_BANDWIDTH_PACKAGE_BY_ID,
      bandwidthPackageSaga.getBandwidthPackageById
    ),
    takeLatest(
      types.POST_BANDWIDTH_PACKAGE,
      bandwidthPackageSaga.postBandwidthPackage
    ),
    takeLatest(
      types.PUT_BANDWIDTH_PACKAGE,
      bandwidthPackageSaga.putBandwidthPackage
    ),
    takeLatest(
      types.DELETE_SELECTED_BANDWIDTH_PACKAGES,
      bandwidthPackageSaga.deleteSelectedBandwidthPackages
    ),
    takeLatest(
      types.DELETE_BANDWIDTH_PACKAGE,
      bandwidthPackageSaga.deleteBandwidthPackage
    )
  ])
}

export function* watchEmailTemplate() {
  yield all([
    takeLatest(types.GET_EMAIL_TEMPLATES, emailTemplateSaga.getEmailTemplates),
    takeLatest(
      types.GET_EMAIL_TEMPLATE_BY_ID,
      emailTemplateSaga.getEmailTemplateById
    ),
    takeLatest(types.PUT_EMAIL_TEMPLATE, emailTemplateSaga.putEmailTemplate)
  ])
}

export function* watchHelp() {
  yield all([
    takeLatest(types.GET_HELPS, helpSaga.getHelps),
    takeEvery(types.GET_HELP, helpSaga.getHelp),
    takeLatest(types.PUT_HELP, helpSaga.putHelp)
  ])
}

export function* watchContentAppHelp() {
  yield all([
    takeLatest(types.GET_CONTENT_APP_HELPS, contentAppHelpSaga.getHelps),
    takeLatest(types.GET_CONTENT_APP_HELP, contentAppHelpSaga.getHelp),
    takeLatest(types.PUT_CONTENT_APP_HELP, contentAppHelpSaga.putHelp)
  ])
}

export function* watchRole() {
  yield all([
    takeLatest(types.GET_ROLES, roleSaga.getRoles),
    takeLatest(types.GET_ROLE_BY_ID, roleSaga.getRoleById),
    takeLatest(types.POST_ROLE, roleSaga.postRole),
    takeLatest(types.PUT_ROLE_BY_ID, roleSaga.putRoleById),
    takeLatest(types.GET_ROLE_PERMISSION_BY_ID, roleSaga.getRolePermissionById),
    takeLatest(types.PUT_ROLE_PERMISSION_BY_ID, roleSaga.putRolePermissionById)
  ])
}

export function* watchPreference() {
  yield all([
    takeEvery(
      types.GET_PREFERENCE_BY_ENTITY,
      preferenceSaga.getPreferenceByEntity
    ),
    takeLatest(
      types.PUT_PREFERENCE_BY_ENTITY,
      preferenceSaga.putPreferenceByEntity
    ),
    takeLatest(
      types.GET_PREFERENCE_PUBLIC_NOC,
      preferenceSaga.getPreferencePublicNoc
    ),
    takeLatest(
      types.PUT_PREFERENCE_PUBLIC_NOC,
      preferenceSaga.putPreferencePublicNoc
    )
  ])
}

export function* watchWhiteLabel() {
  yield all([takeLatest(types.GET_WHITE_LABEL, whiteLabelSaga.getWhiteLabel)])
}

export function* watchAlert() {
  yield all([
    takeLatest(types.GET_ALERT_DEVICES_BY_ID, alertSaga.getAlertDevicesById),
    takeLatest(types.POST_ALERT_TRIGGER, alertSaga.postAlertTrigger),
    takeLatest(
      types.GET_DEVICE_ACTIVE_EMERGENCY_ALERT,
      alertSaga.getDeviceActiveEmergencyAlert
    ),
    takeLatest(
      types.GET_DEVICE_MEDIA_EMERGENCY_ALERT,
      alertSaga.getDeviceMediaEmergencyAlert
    ),
    takeLatest(
      types.PUT_DEVICE_MEDIA_EMERGENCY_ALERT,
      alertSaga.putDeviceMediaEmergencyAlert
    ),
    takeLatest(
      types.REMOVE_DEVICE_MEDIA_EMERGENCY_ALERT,
      alertSaga.removeDeviceMediaEmergencyAlert
    ),
    takeLatest(
      types.GET_DEVICE_MEDIA_CAP_ALERT,
      alertSaga.getDeviceMediaCapAlert
    ),
    takeLatest(
      types.REMOVE_DEVICE_MEDIA_CAP_ALERT,
      alertSaga.removeDeviceMediaCapAlert
    ),
    takeLatest(
      types.PUT_DEVICE_MEDIA_CAP_ALERT,
      alertSaga.putDeviceMediaCapAlert
    ),
    takeLatest(types.DISABLE_ALERT, alertSaga.disableAlert),
    takeLatest(types.DISABLE_DEVICE_ALERT, alertSaga.disableDeviceAlert),
    takeEvery(
      types.REQUEST_ASSOCIATE_CAP_ALERT,
      alertSaga.associateCapAlertWorker
    ),
    takeEvery(types.GET_IPAWS_ALERT_DEVICES, alertSaga.getIpawsAlertDevices),
    takeEvery(types.ASSOCIATE_IPAWS_ALERT, alertSaga.associateIpawsAlert),
    takeLatest(
      types.REMOVE_DEVICE_MEDIA_IPAWS_ALERT,
      alertSaga.removeDeviceMediaIpawsAlert
    ),
    takeLatest(
      types.GET_DEVICE_MEDIA_IPAWS_ALERT,
      alertSaga.getDeviceMediaIpawsAlert
    ),
    takeLatest(
      types.PUT_DEVICE_MEDIA_IPAWS_ALERT,
      alertSaga.putDeviceMediaIpawsAlert
    )
  ])
}

export function* watchSmartPlaylist() {
  yield all([
    takeLatest(
      types.BUILD_SMART_PLAYLIST,
      smartPlaylistSaga.buildSmartPlaylist
    ),
    takeLatest(types.POST_SMART_PLAYLIST, smartPlaylistSaga.postSmartPlaylist),
    takeLatest(types.PUT_SMART_PLAYLIST, smartPlaylistSaga.putSmartPlaylist)
  ])
}

export function* watchDesignGallery() {
  yield all([
    takeLatest(types.GET_DESIGN_GALLERY, designGallerySaga.getDesignGallery),
    takeLatest(types.POST_DESIGN_GALLERY, designGallerySaga.postDesignGallery),
    takeLatest(types.PUT_DESIGN_GALLERY, designGallerySaga.putDesignGallery),
    takeLatest(
      types.GET_PEXEL_BACKGROUND_IMAGES,
      designGallerySaga.getPexelBackgroundImages
    ),
    takeLatest(
      types.GET_BACKGROUND_IMAGES,
      designGallerySaga.getBackgroundImages
    ),
    takeLatest(types.GET_PATTERNS, designGallerySaga.getPatterns),
    takeLatest(types.SET_SELECTED_BG, designGallerySaga.setSelectedBg),
    takeLatest(types.GET_SHAPES, designGallerySaga.getShapes),
    takeLatest(types.GET_ICONS, designGallerySaga.getIcons),
    takeLatest(types.GET_OBJECTS, designGallerySaga.getObjects),
    takeLatest(types.GET_EMOJIS, designGallerySaga.getEmojis),
    takeLatest(types.GET_STOCK_IMAGES, designGallerySaga.getStockImages),
    takeLatest(types.GET_ROYALTY_IMAGES, designGallerySaga.getRoyaltyImages),
    takeLatest(types.GET_DESIGNS, designGallerySaga.getDesigns),
    takeLatest(types.GET_STOCK_DESIGNS, designGallerySaga.getStockDesigns),
    takeLatest(types.GET_FAVORITE_DESIGNS, designGallerySaga.getFavoriteDesign),
    takeLatest(types.GET_SHARED_DESIGNS, designGallerySaga.getSharedDesigns),
    takeLatest(types.GET_ICONS_GROUP, designGallerySaga.getIconsGroup),
    takeLatest(types.GET_DESIGN_GALLERY_FONTS, designGallerySaga.getFonts)
  ])
}

export function* watchQuotes() {
  yield all([
    takeLatest(types.GET_QUOTES, quoteSaga.getQuotes),
    takeLatest(types.POST_QUOTE, quoteSaga.postQuote),
    takeLatest(types.PUT_QUOTE, quoteSaga.putQuote),
    takeLatest(types.DELETE_QUOTE, quoteSaga.deleteQuote),
    takeLatest(types.GET_QUOTE_CATEGORIES, quoteSaga.getQuoteCategories)
  ])
}

export function* watchMenuMaker() {
  yield all([
    takeLatest(types.GET_MENU_ITEMS, menuMakerSaga.getMenuItems),
    takeLatest(types.POST_MENU_ITEM, menuMakerSaga.postMenuItem),
    takeLatest(types.DELETE_MENU_ITEM, menuMakerSaga.deleteMenuItem),
    takeLatest(
      types.DELETE_SELECTED_MENU_ITEMS,
      menuMakerSaga.deleteSelectedMenuItems
    ),
    takeEvery(types.POST_MENU_ITEM_BULK, menuMakerSaga.postMenuItemBulk),
    takeLatest(types.GET_MENU_ITEM_BY_ID, menuMakerSaga.getMenuItem),
    takeLatest(types.PUT_MENU_ITEM, menuMakerSaga.putMenuItem),
    takeLatest(types.GET_MENU_LOCATIONS, menuMakerSaga.getMenuLocations),
    takeLatest(types.POST_MENU_LOCATION, menuMakerSaga.postMenuLocation),
    takeLatest(types.DELETE_MENU_LOCATION, menuMakerSaga.deleteMenuLocation),
    takeLatest(
      types.DELETE_SELECTED_MENU_LOCATIONS,
      menuMakerSaga.deleteSelectedMenuLocations
    ),
    takeLatest(types.GET_MENU_LOCATION_BY_ID, menuMakerSaga.getMenuLocation),
    takeLatest(types.PUT_MENU_LOCATION, menuMakerSaga.putMenuLocation),

    takeLatest(types.GET_MENU_STATIONS, menuMakerSaga.getMenuStations),
    takeLatest(types.POST_MENU_STATION, menuMakerSaga.postMenuStation),
    takeLatest(types.DELETE_MENU_STATION, menuMakerSaga.deleteMenuStation),
    takeLatest(
      types.DELETE_SELECTED_MENU_STATIONS,
      menuMakerSaga.deleteSelectedMenuStations
    ),
    takeLatest(types.GET_MENU_STATION_BY_ID, menuMakerSaga.getMenuStation),
    takeLatest(types.PUT_MENU_STATION, menuMakerSaga.putMenuStation),
    takeLatest(types.GET_MENU_INTEGRATIONS, menuMakerSaga.getMenuIntegrations),
    takeLatest(
      types.POST_MENU_INTEGRATION_CONNECT,
      menuMakerSaga.postMenuIntegrationConnect
    ),
    takeLatest(
      types.POST_MENU_INTEGRATION_DISCONNECT,
      menuMakerSaga.postMenuIntegrationDisconnect
    ),
    takeLatest(
      types.POST_MENU_INTEGRATION_SYNC,
      menuMakerSaga.postMenuIntegrationSync
    ),
    takeLatest(
      types.GET_MENU_MAKER_PROVIDERS,
      menuMakerSaga.getMenuMakerProviders
    ),
    takeLatest(
      types.POST_MENU_MAKER_ITEM_BULK,
      menuMakerSaga.postMenuMakerItemBulk
    )
  ])
}

export function* watchMenuDesign() {
  yield all([
    takeLatest(types.GET_MENU_PRESETS, menuDesignSaga.getMenuPresets),
    takeLatest(
      types.GET_STOCK_MENU_PRESETS,
      menuDesignSaga.getStockMenuPresets
    ),
    takeLatest(
      types.GET_SHARED_MENU_PRESETS,
      menuDesignSaga.getSharedMenuPresets
    ),
    takeLatest(types.GET_MENU_PRESET_ITEM, menuDesignSaga.getMenuPresetItem),
    takeLatest(types.POST_MENU_PRESET, menuDesignSaga.postMenuPreset),
    takeLatest(types.PUT_MENU_PRESET, menuDesignSaga.putMenuPreset),
    takeLatest(types.DELETE_MENU_PRESET, menuDesignSaga.deleteMenuPreset),
    takeLatest(types.SHARE_MENU_PRESET, menuDesignSaga.shareMenuPreset),
    takeLatest(types.GET_MENU_COMPONENTS, menuDesignSaga.getMenuComponents),
    takeLatest(
      types.GET_STOCK_MENU_COMPONENTS,
      menuDesignSaga.getStockMenuComponents
    ),
    takeLatest(
      types.GET_SHARED_MENU_COMPONENTS,
      menuDesignSaga.getSharedMenuComponents
    ),
    takeLatest(
      types.GET_MENU_COMPONENT_ITEM,
      menuDesignSaga.getMenuComponentItem
    ),
    takeLatest(types.POST_MENU_COMPONENT, menuDesignSaga.postMenuComponent),
    takeLatest(types.PUT_MENU_COMPONENT, menuDesignSaga.putMenuComponent),
    takeLatest(types.DELETE_MENU_COMPONENT, menuDesignSaga.deleteMenuComponent),
    takeLatest(types.SHARE_MENU_COMPONENT, menuDesignSaga.shareMenuComponent),
    takeLatest(types.GET_MENU_BOARD, menuDesignSaga.getMenuBoard),
    takeLatest(types.POST_MENU_BOARD, menuDesignSaga.postMenuBoard),
    takeLatest(types.POST_MENU_BOARD_MEDIA, menuDesignSaga.postMenuBoard),
    takeLatest(types.PUT_MENU_BOARD, menuDesignSaga.putMenuBoard),
    takeLatest(types.PUT_MENU_BOARD_MEDIA, menuDesignSaga.putMenuBoard),
    takeLatest(
      types.GET_MENU_MAKER_BACKGROUND_IMAGES,
      menuDesignSaga.getBackgroundImages
    ),
    takeLatest(types.GET_MENU_MAKER_PATTERNS, menuDesignSaga.getPatterns),
    takeLatest(types.GET_MENU_MAKER_SHAPES, menuDesignSaga.getShapes),
    takeLatest(types.GET_MENU_MAKER_ICONS, menuDesignSaga.getIcons),
    takeLatest(types.GET_MENU_MAKER_OBJECTS, menuDesignSaga.getObjects),
    takeLatest(types.GET_MENU_MAKER_EMOJIS, menuDesignSaga.getEmojis),
    takeLatest(
      types.GET_MENU_MAKER_STOCK_IMAGES,
      menuDesignSaga.getStockImages
    ),
    takeLatest(types.GET_MENU_MAKER_ICONS_GROUP, menuDesignSaga.getIconsGroup),
    takeLatest(
      types.GET_MENU_DESIGN_PREVIEW,
      menuDesignSaga.getMenuDesignPreview
    )
  ])
}

export function* watchEmailNotification() {
  yield all([
    takeLatest(
      types.GET_EMAIL_NOTIFICATION_ITEMS,
      emailNotificationSaga.getEmailNotificationItems
    ),
    takeLatest(
      types.GET_EMAIL_BUILDER_DATA,
      emailNotificationSaga.getEmailBuilderData
    ),
    takeLatest(
      types.GET_EMAIL_RECEIVER_DATA,
      emailNotificationSaga.getEmailReceiverData
    ),
    takeLatest(
      types.POST_EMAIL_NOTIFICATION_ITEM,
      emailNotificationSaga.postEmailNotificationItem
    ),
    takeLatest(
      types.GET_EMAIL_NOTIFICATION_BY_ID,
      emailNotificationSaga.getEmailNotificationById
    ),
    takeLatest(
      types.PUT_EMAIL_NOTIFICATION_ITEM,
      emailNotificationSaga.putEmailNotificationItem
    ),
    takeLatest(
      types.DELETE_EMAIL_NOTIFICATION_ITEM,
      emailNotificationSaga.deleteEmailNotificationItem
    ),
    takeLatest(
      types.DELETE_SELECTED_EMAIL_NOTIFICATION,
      emailNotificationSaga.deleteSelectedEmailNotification
    )
  ])
}

export function* watchDeviceNoc() {
  yield all([
    takeEvery(
      types.GET_DEVICE_NOC_GENERAL_ITEMS,
      deviceNocSaga.getDeviceNocGeneralItems
    ),
    takeEvery(
      types.GET_DEVICE_NOC_HEALTH_ITEMS,
      deviceNocSaga.getDeviceNocHealthItems
    ),
    takeLatest(
      types.GET_DEVICE_NOC_NETWORK_ITEMS,
      deviceNocSaga.getDeviceNocNetworkItems
    ),
    takeEvery(types.GET_DEVICE_ALARM_ITEMS, deviceNocSaga.getDeviceAlarmItems),
    takeEvery(
      types.GET_DEVICE_ALARM_ITEM_BY_ID,
      deviceNocSaga.getDeviceAlarmById
    ),
    takeEvery(types.POST_DEVICE_ALARM, deviceNocSaga.postDeviceAlarm),
    takeEvery(types.PUT_DEVICE_ALARM, deviceNocSaga.putDeviceAlarm),
    takeEvery(types.DELETE_DEVICE_ALARM, deviceNocSaga.deleteDeviceAlarm),
    takeEvery(
      types.GET_DEVICE_ALARM_COLUMN_LIST,
      deviceNocSaga.getDeviceAlarmColumnList
    )
  ])
}

export function* watchCalendarAggregatorSaga() {
  yield all([
    takeLatest(
      types.GET_REMOTE_CALENDARS,
      calendarAggregatorSaga.getRemoteCalendars
    )
  ])
}

export function* watchPostHogSaga() {
  yield all([
    takeLatest(types.GET_POSTHOG_EVENTS, postHogSaga.getPostHogEvents)
  ])
}

export function* watchGoogleDocumentSaga() {
  yield all([
    takeLatest(
      types.GET_GOOGLE_DOCUMENTS,
      googleDocumentSaga.getGoogleDocuments
    )
  ])
}
