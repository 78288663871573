import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { _uniqBy, _isEmpty } from 'utils/lodash'

import DefaultModal from 'components/Modal/DefaultModal'
import Scrollbars from 'components/Scrollbars'
import Spacing from 'components/Containers/Spacing'
import TabsGroup, { createTab } from 'components/Tabs/TabsGroup'
import { capitalize } from 'utils'
import DependencyList from './DependencyList'
import DevicePreviewModal from 'components/Modal/DevicePreviewModal'
import { ScreenPreviewModal } from 'components/Modal'
import { dependencyType } from 'constants/library'
import { Text } from 'components/Typography'
import EmptyPlaceholder from 'components/EmptyPlaceholder'

const MAX_MODAL_WIDTH = 560

const styles = ({ palette, type, typography }) => ({
  modalContent: {
    padding: '10px 0px 0px 20px !important'
  },
  paddingRight: {
    paddingRight: 20
  },
  scrollbarRoot: {
    height: 'min(400px, calc(100vh - 270px)) !important'
  },
  noDataMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    height: 'min(494px, calc(100vh - 270px)) !important',
    margin: 'auto',
    textAlign: 'center',
    paddingBottom: 60
  },
  accordionContent: {
    padding: 0
  },
  modalTitleRoot: {
    overflow: 'hidden'
  },
  modalTitleText: {
    color: palette[type].dialog.title,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  accordionRoot: {
    '& > div:last-child': {
      maxHeight: 189,
      overflow: 'auto',

      '&::-webkit-scrollbar': {
        width: 6,
        height: 6
      },
      '&::-webkit-scrollbar-thumb': {
        background: palette[type].scrollbar.background,
        borderRadius: 5
      }
    }
  },
  accordionHeader: {
    display: 'flex'
  },
  accordionHeaderEntity: {
    fontWeight: 'bold'
  },
  headerText: {
    ...typography.darkText[type],
    fontWeight: 'bold',
    fontSize: '15px'
  }
})

const DependencyModal = ({
  t,
  type,
  open,
  data,
  isBulk,
  name,
  onClose,
  onConfirm,
  modalTitle = '',
  noTabs = false,
  customTabs,
  headerComponent: HeaderComponent,
  headerText,
  modalConfirmBtnText,
  hasCancelBtn = true,
  titleHasQuestionMark = true,
  alertNotificationComponent,
  sortOrder,
  classes
}) => {
  const [selectedTab, setSelectedTab] = useState()

  const reduceData = useCallback(data => {
    const _data = {
      ...data
    }
    Object.keys(data).forEach(d => {
      if (['SmartPlaylist', 'InteractivePlaylist'].includes(d)) {
        _data[dependencyType.playlist] = _uniqBy(
          [...(_data[dependencyType.playlist] || []), ..._data[d]],
          'id'
        )

        delete _data[d]
      } else if (d === 'Modules') {
        _data[dependencyType.others] = [..._data['Modules']]

        delete _data['Modules']
      }
    })

    return _data
  }, [])

  const sortData = useCallback(data => {
    const _data = {
      ...data
    }
    Object.entries(data).forEach(([key, value]) => {
      if (key === dependencyType.user) {
        _data[key] = value.sort((a, b) =>
          a.lastAuthActivity?.activity_time < b.lastAuthActivity?.activity_time
            ? 1
            : -1
        )
      } else if (key !== dependencyType.client) {
        _data[key] = value.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1))
      }
    })

    return _data
  }, [])

  const parseData = useMemo(() => {
    if (!data) {
      return []
    }

    const _data = {}
    const dataToParse = isBulk
      ? data
      : {
          [name]: data
        }

    Object.entries(dataToParse).forEach(([key, value]) => {
      _data[key] = sortData(reduceData(value))
    })

    return _data
  }, [data, isBulk, reduceData, sortData, name])

  const tabsArr = useMemo(() => {
    const tab = new Set()
    Object.values(parseData).forEach(value => {
      Object.keys(value).forEach(key => tab.add(key))
    })

    if (sortOrder?.length) {
      return Array.from(tab).sort(
        (a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b)
      )
    }

    return Array.from(tab)
  }, [parseData, sortOrder])

  useEffect(() => {
    if (tabsArr) {
      setSelectedTab(tabsArr?.[0])
    }
  }, [tabsArr])

  const tabs = useMemo(() => {
    return tabsArr.map(key => {
      return createTab(t(capitalize(key)), key)
    })
  }, [tabsArr, t])

  const handleChangeTab = useCallback((e, tab) => {
    if (tab) {
      setSelectedTab(tab)
    }
  }, [])

  const getTabWidth = useCallback(tabs => {
    const maxWidth = MAX_MODAL_WIDTH / tabs.length - 5
    if (maxWidth > 150) return 150

    return maxWidth
  }, [])

  const tabData = useMemo(() => {
    const _data = tabsArr.reduce((a, v) => ({ ...a, [v]: {} }), {})

    Object.entries(parseData).forEach(([key, value]) => {
      Object.entries(value).forEach(([_type, _val]) => {
        _data[_type][key] = _val
      })
    })

    return _data?.[selectedTab] || []
  }, [parseData, tabsArr, selectedTab])

  const title = titleHasQuestionMark ? `${modalTitle}?` : modalTitle

  return (
    <DefaultModal
      open={open}
      onCloseModal={onClose}
      onClickSave={onConfirm}
      maxWidth="sm"
      buttonPrimaryText={modalConfirmBtnText || t('Delete')}
      hasCancelBtn={hasCancelBtn}
      buttonPrimaryDisabled={_isEmpty(tabData)}
      titleComponent={
        <Grid item className={classes.modalTitleRoot}>
          <Text
            variant="big"
            weight="bold"
            rootClassName={classes.modalTitleText}
            component="h2"
          >
            {title}
          </Text>
        </Grid>
      }
      contentClass={classes.modalContent}
    >
      {alertNotificationComponent &&
        !_isEmpty(tabData) &&
        alertNotificationComponent}

      {(HeaderComponent || !noTabs || headerText) && (
        <Spacing variant={0} rootClassName={classes.paddingRight}>
          {customTabs && (
            <TabsGroup
              value={selectedTab}
              onChange={handleChangeTab}
              tabs={customTabs}
              tabWidth={getTabWidth(customTabs)}
            />
          )}
          {!noTabs && !customTabs && (
            <TabsGroup
              value={selectedTab}
              onChange={handleChangeTab}
              tabs={tabs}
              tabWidth={getTabWidth(tabsArr)}
            />
          )}
          {HeaderComponent ? (
            <HeaderComponent selectedTab={selectedTab} />
          ) : headerText ? (
            <Text rootClassName={classes.headerText}>{headerText}</Text>
          ) : null}
        </Spacing>
      )}
      {!_isEmpty(tabData) ? (
        <Scrollbars className={classes.scrollbarRoot}>
          <Spacing variant={0} rootClassName={classes.paddingRight}>
            <DependencyList
              data={tabData}
              type={selectedTab}
              isBulk={isBulk}
              classes={classes}
              hasBulkView
            />
          </Spacing>
        </Scrollbars>
      ) : (
        type &&
        selectedTab && (
          <EmptyPlaceholder
            text={t(
              'This item is not associated with any active associations',
              {
                currentItem: capitalize(type),
                associatedItem: selectedTab
              }
            )}
            rootClassName={classes.noDataMessage}
          />
        )
      )}

      {tabsArr.includes(dependencyType.device) && <DevicePreviewModal />}
      {tabsArr.includes(dependencyType.media) && <ScreenPreviewModal />}
      {[
        dependencyType.playlist,
        dependencyType.attachPlaylist,
        dependencyType.detachPlaylist
      ].some(d => tabsArr.includes(d)) && (
        <ScreenPreviewModal targetReducer="playlist" />
      )}
      {tabsArr.includes(dependencyType.template) && (
        <ScreenPreviewModal targetReducer="template" />
      )}
      {[
        dependencyType.schedule,
        dependencyType.attachSchedule,
        dependencyType.detachSchedule
      ].some(d => tabsArr.includes(d)) && (
        <ScreenPreviewModal targetReducer="schedule" />
      )}
    </DefaultModal>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(DependencyModal)
)
