import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import GroupPreview from './GroupPreview'

const style = () => ({
  root: {
    display: 'grid',
    width: '100%',
    position: 'relative',
    gridTemplateColumns: '1fr',
    paddingRight: 7
  }
})

const PreviewGridsGroups = ({
  grids,
  isVisible,
  isLoading,
  loadingByType,
  loaderComponent,
  onPreviewClick,
  defaultTitle = '',
  onChangeTabs = f => f,
  colWidth = 3,
  withBorder,
  withBlur,
  classes,
  t
}) => {
  const renderGroups = useMemo(
    () =>
      Object.keys(grids).map((group, key) => (
        <GroupPreview
          title={defaultTitle ? `${defaultTitle} ${key + 1}` : t(group)}
          tabKey={group}
          grids={grids[group]}
          onGroupClick={onChangeTabs}
          onPreviewClick={onPreviewClick}
          key={key}
          withBorder={withBorder}
          withBlur={withBlur}
          colWidth={colWidth}
          isLoading={loadingByType ? loadingByType[group] : isLoading}
          loaderComponent={loaderComponent}
          isShapeSvg={group === 'Shapes'}
        />
      )),
    [
      grids,
      defaultTitle,
      withBorder,
      withBlur,
      isLoading,
      onChangeTabs,
      onPreviewClick,
      loadingByType,
      loaderComponent,
      colWidth,
      t
    ]
  )

  return <>{isVisible && <div className={classes.root}>{renderGroups}</div>}</>
}

PreviewGridsGroups.propTypes = {
  grids: PropTypes.object,
  isVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingByType: PropTypes.object,
  onPreviewClick: PropTypes.func,
  colWidth: PropTypes.number,
  withBlur: PropTypes.bool,
  loaderComponent: PropTypes.node,
  isShapeSvg: PropTypes.bool
}

export default withStyles(style)(memo(PreviewGridsGroups))
