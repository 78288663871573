import React, { useEffect, memo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withTranslation } from 'react-i18next'
import ResizeObserver from 'react-resize-observer'

import PreviewGridsItem from './PreviewGridsItem'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { withStyles } from '@material-ui/core'
import { SORTING_TYPES } from 'constants/canvasConstants'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import RectLoader from 'components/Loaders/RectLoader'
import '../Pages/DesignGallery/styles/_index.scss'

const styles = ({ spacing, typography, type, colors }) => ({
  root: {
    display: 'grid',
    gap: `${spacing(1)}px`,
    width: '100%',
    position: 'relative',
    paddingRight: 7
  },
  'cols-1': {
    gridTemplateColumns: '1fr'
  },
  'cols-2': {
    gridTemplateColumns: '1fr 1fr'
  },
  'cols-3': {
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '2px'
  },
  'cols-4': {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '2px'
  },
  'cols-5': {
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gap: '2px'
  },
  'cols-8': {
    gridTemplateColumns: 'repeat(8, 1fr)',
    gap: '2px'
  },
  textInfo: {
    ...typography.lightText[type],
    fontSize: 12,
    textAlign: 'center',
    padding: '10px'
  },
  ignoreColumns: {
    width: '100%',
    gridColumnStart: 1,
    gridColumnEnd: 'none'
  },
  positionRelative: {
    position: 'relative'
  },
  scrollBlocker: {
    position: 'absolute',
    inset: 0,
    zIndex: 1,
    backgroundColor: colors.background.designGallery[type],
    opacity: 0.7
  },
  smallVariant: {
    height: '15vh'
  }
})

const PreviewGrids = ({
  grids,
  isVisible,
  isLoading,
  onPreviewClick,
  cols,
  scrollPosition,
  onContentScrollEnd = f => f,
  withBorder,
  isPortrait,
  isOwnScroll = true,
  rootClassName,
  gridsRootClassName,
  loaderComponent: LoaderComponent,
  emptyData,
  selectedId,
  sortingType,
  triggerOnNoScroll = false,
  maxHeight = 0,
  previewImageRootClassName,
  renderActionComponent,
  showImagePlaceholder = false,
  initialLoading = true,
  getImageHeight = f => f,
  classes,
  t,
  isShapeSvg,
  imageErrorClass,
  containerClass,
  emptyGridClass,
  placeHolderClass,
  countData,
  showAddToFavorite,
  withPreviewComponent
}) => {
  const [initialized, setInitialized] = useState(false)
  const [columns, setColumns] = useState(cols)
  const [height, setHeight] = useState(0)
  const gridsRef = useBottomScrollListener(onContentScrollEnd, { offset: 1 })
  const [row, setRow] = useState(isPortrait ? 5 : 6)

  useEffect(() => {
    if (!grids.length) {
      setInitialized(false)
    }
  }, [grids])

  useEffect(() => {
    if (!Object.values(SORTING_TYPES).includes(sortingType)) {
      setColumns(cols)
    }
    // eslint-disable-next-line
  }, [cols])

  useEffect(() => {
    if (countData) {
      const rowCount = (countData % columns) + columns
      setRow(rowCount && rowCount)
    }
  }, [columns, countData])

  useEffect(() => {
    if (sortingType === SORTING_TYPES.row) {
      setColumns(1)
    } else if (sortingType === SORTING_TYPES.grids) {
      setColumns(2)
    }
  }, [sortingType])

  useEffect(
    () => {
      if (!initialized && !isLoading) {
        setInitialized(true)
      }
    },
    // eslint-disable-next-line
    [isLoading, grids]
  )

  useEffect(() => {
    if (gridsRef.current && scrollPosition === 0) {
      gridsRef.current.scrollTop = scrollPosition
    }
    // eslint-disable-next-line
  }, [scrollPosition])

  useEffect(() => {
    if (height && triggerOnNoScroll && maxHeight && !isLoading) {
      if (height <= maxHeight) {
        onContentScrollEnd()
      }
    }
    // eslint-disable-next-line
  }, [height, maxHeight, triggerOnNoScroll])

  const handleResizeContent = useCallback(
    ({ height }) => {
      if (gridsRef.current) {
        setHeight(height)
      }
    },
    [gridsRef]
  )

  return (
    <>
      {isVisible && (
        <>
          {isOwnScroll && isLoading && (
            <div className={classes.scrollBlocker} />
          )}
          <div
            ref={gridsRef}
            className={classNames(
              { 'scroll-container': isOwnScroll },
              containerClass
            )}
          >
            <div
              className={classNames(
                classes.root,
                classes[`cols-${columns}`],
                gridsRootClassName,
                { [classes.positionRelative]: isOwnScroll }
              )}
            >
              {!initialized && isLoading ? (
                <div className={classes.ignoreColumns}>{LoaderComponent}</div>
              ) : (
                grids.map((grid, key) => (
                  <PreviewGridsItem
                    key={key}
                    grid={grid}
                    onClick={onPreviewClick}
                    isLoading={isLoading}
                    isPortrait={isPortrait}
                    withBorder={withBorder}
                    rootClassName={rootClassName}
                    selectedId={selectedId}
                    imageRootClassName={previewImageRootClassName}
                    renderActionComponent={renderActionComponent}
                    showImagePlaceholder={showImagePlaceholder}
                    imageHeight={getImageHeight(grid)}
                    isShapeSvg={isShapeSvg}
                    imageErrorClass={imageErrorClass}
                    placeHolderClass={placeHolderClass}
                    showAddToFavorite={showAddToFavorite}
                    withPreviewComponent={withPreviewComponent}
                  />
                ))
              )}

              {(columns === 5 || columns === 4) &&
                !initialLoading &&
                isLoading &&
                Array.from({ length: row }, (_, index) => (
                  <RectLoader
                    key={index}
                    height={isPortrait ? 480 : 180}
                    width={isPortrait ? 260 : 330}
                    padding={0}
                  />
                ))}

              {!grids.length && !isLoading && (
                <EmptyPlaceholder
                  text={emptyData ? t(emptyData) : t('Nothing to show')}
                  rootClassName={classNames(
                    classes.textInfo,
                    classes.ignoreColumns,
                    emptyGridClass,
                    {
                      [classes.smallVariant]: emptyData
                    }
                  )}
                  variant={emptyData ? 'small' : 'normal'}
                  smallAlignment="center"
                />
              )}
              <ResizeObserver onResize={handleResizeContent} />
            </div>
          </div>
        </>
      )}
    </>
  )
}

PreviewGrids.propTypes = {
  grids: PropTypes.array,
  isVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  onPreviewClick: PropTypes.func,
  cols: PropTypes.oneOf([1, 2, 3]),
  withBorder: PropTypes.bool,
  isPortrait: PropTypes.bool,
  rootClassName: PropTypes.string,
  gridsRootClassName: PropTypes.string,
  loaderComponent: PropTypes.node,
  emptyData: PropTypes.string,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isShapeSvg: PropTypes.bool,
  imageErrorClass: PropTypes.string,
  containerClass: PropTypes.string,
  emptyGridClass: PropTypes.string
}

export default withTranslation('translations')(
  withStyles(styles)(memo(PreviewGrids))
)
