export const EMAIL_TEMPLATE = 'emailTemplate'
export const CUSTOM_EMAIL_TEMPLATE = 'customEmailTemplate'

export const HTML_CONTENT = 'HTMLContent'

export const BIG_NUMBER = 99999

export const fontType = {
  GOOGLE: 'google',
  CUSTOM: 'custom'
}

export const DEFAULT_GROUP_TITLE = 'Default'

export const libraryTypes = {
  device: 'device',
  media: 'media',
  schedule: 'schedule',
  user: 'user',
  playlist: 'playlist',
  template: 'template',
  menuItem: 'menuItem',
  client: 'client'
}

export const dependencyType = {
  media: 'Media',
  playlist: 'Playlist',
  template: 'Template',
  schedule: 'Schedule',
  device: 'Device',
  user: 'User',
  client: 'Client',
  attachPlaylist: 'Attach Playlist',
  detachPlaylist: 'Detach Playlist',
  others: 'Others',
  presets: 'Presets',
  menuMaker: 'MenuMaker',
  entities: 'Entities',
  baseClientResource: 'BaseClientResource',
  designGallery: 'DesignGallery',
  attachSchedule: 'Attach Schedule',
  detachSchedule: 'Detach Schedule'
}

export const libraryViews = {
  location: 'location',
  grid: 'grid',
  list: 'list',
  calendar: 'calendar',
  timeline: 'timeline',
  histogram: 'histogram',
  advancedList: 'advanced-list'
}

export const searchCategoryType = {
  media: 'Media',
  playlist: 'Playlist',
  template: 'Template',
  schedule: 'Schedule',
  device: 'Device',
  user: 'User',
  tag: 'Tag'
}

export const searchCategoryMapping = {
  media: searchCategoryType.media,
  devices: searchCategoryType.device,
  playlists: searchCategoryType.playlist,
  templates: searchCategoryType.template,
  schedules: searchCategoryType.schedule,
  users: searchCategoryType.user,
  tags: searchCategoryType.tag
}

export const searchTypeColorMapping = {
  [searchCategoryType.media]: 'rgb(236, 64, 122)',
  [searchCategoryType.playlist]: 'rgb(121, 134, 203)',
  [searchCategoryType.template]: 'rgb(186, 104, 200)',
  [searchCategoryType.schedule]: 'rgb(77, 208, 225)',
  [searchCategoryType.device]: 'rgb(100, 181, 246)',
  [searchCategoryType.user]: 'rgb(21, 101, 192)',
  [searchCategoryType.tag]: 'rgb(21, 101, 192)'
}

export const approveStatuses = {
  approve: 'Approve',
  disapprove: 'Disapprove',
  reject: 'Reject',
  waitingForApproval: 'Waiting for Approval'
}

export const librariesWithBulkEditSupport = [
  libraryTypes.device,
  libraryTypes.media,
  libraryTypes.user,
  libraryTypes.playlist,
  libraryTypes.template,
  libraryTypes.schedule,
  libraryTypes.menuItem,
  libraryTypes.client
]

export const librariesBulkEditArrayIds = [
  libraryTypes.user,
  libraryTypes.playlist,
  libraryTypes.template,
  libraryTypes.schedule,
  libraryTypes.menuItem,
  libraryTypes.client
]

export const librariesWithoutGroupsSupport = [libraryTypes.menuItem]
