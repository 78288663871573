import React, { memo } from 'react'
import classNames from 'classnames'
import { withStyles, Grid } from '@material-ui/core'

const styles = ({ type }) => ({
  root: {
    height: 75,
    backgroundColor: `${type === 'light' ? '#f1f1f1' : '#15263d'}`,
    overflow: 'hidden',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  image: {
    objectFit: 'contain',
    height: '100%',
    width: '56%'
  },
  blur: {
    filter: 'blur(5px)'
  },
  icon: {
    maskSize: '100%',
    maskRepeat: 'no-repeat',
    maskPosition: 'center',
    backgroundColor: '#9394a1'
  }
})

const SvgPreview = ({ src, isLoading, classes, isShapeSvg }) => {
  const previewSvg = src?.replaceAll(' ', '%20')
  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent="center"
      className={classes.root}
    >
      {isShapeSvg ? (
        <div
          className={classNames(classes.icon, classes.image, {
            [classes.blur]: isLoading
          })}
          style={{
            maskImage: `url(${previewSvg})`,
            WebkitMaskImage: `url(${previewSvg})`
          }}
        />
      ) : (
        <img
          crossOrigin="anonymous"
          src={previewSvg}
          alt={'item'}
          className={classNames(classes.image, {
            [classes.blur]: isLoading
          })}
        />
      )}
    </Grid>
  )
}

export default withStyles(styles)(memo(SvgPreview))
