import { createAppApi } from 'services/api'

export const appApi = createAppApi('appApiQuery', {
  endpoints: builder => ({
    ping: builder.query({
      query: () => ({
        method: 'GET',
        url: new URL(process.env.REACT_APP_API_URL).origin
      })
    }),
    appHealth: builder.query({
      query: (params = {}) => ({
        method: 'GET',
        url: '/health/history',
        params
      })
    }),
    getMe: builder.query({
      query: () => ({
        method: 'GET',
        url: '/me'
      })
    }),
    getDocs: builder.query({
      query: () => ({
        method: 'GET',
        url: `${
          new URL(process.env.REACT_APP_API_URL).origin
        }/request-docs/api`,
        params: {
          openapi: true,
          showHead: false
        }
      })
    })
  })
})

export const {
  useLazyPingQuery,
  useAppHealthQuery,
  useLazyGetMeQuery,
  useGetDocsQuery
} = appApi
