import React, { memo } from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'

const styles = ({ type, typography }) => ({
  navigation: {
    padding: '10px'
  },
  navigationText: {
    ...typography.lightText[type],
    '&:hover': {
      cursor: 'pointer'
    }
  },
  text: typography.darkText[type]
})

const PreviewGridsHeader = ({ isVisible, title, onBackClick, classes, t }) => {
  return (
    isVisible && (
      <Grid
        container
        justifyContent="space-between"
        className={classes.navigation}
      >
        <Typography className={classes.text}>{title}</Typography>
        <Typography onClick={onBackClick} className={classes.navigationText}>
          {t('Back')}
        </Typography>
      </Grid>
    )
  )
}

export default withStyles(styles)(memo(PreviewGridsHeader))
