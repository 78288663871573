import { useMemo } from 'react'

import { getFeatureIdByGroup } from 'utils/mediaUtils'
import useConfigMediaCategory from 'hooks/api/useConfigMediaCategory'

const useDetermineMediaFeatureId = group => {
  const configMediaCategory = useConfigMediaCategory()

  return useMemo(() => getFeatureIdByGroup(configMediaCategory, group), [
    configMediaCategory,
    group
  ])
}

export default useDetermineMediaFeatureId
