import React, { memo, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Tooltip, withStyles } from '@material-ui/core'
import ImagePreview from './ImagePreview'
import DrawerIcon from 'common/icons'
import Text from 'components/Typography/Text'
import { previewGridTypes } from 'constants/canvasConstants'
import ImageWithPlaceholder from 'components/Loaders/ImageWithPlaceholder'

const styles = () => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'relative',
    backgroundColor: '#f1f1f1',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  errorHeight: {
    minHeight: 80
  },
  errorPortraitHeight: {
    minHeight: 123
  },
  image: {
    objectFit: 'contain',
    width: '100%'
  },
  blur: {
    filter: 'blur(5px)'
  },
  fallbackElement: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '12px'
  }
})

const DesignPreview = ({
  type,
  src = '',
  name = '',
  isLoading,
  imageRootClassName,
  showImagePlaceholder = false,
  imageHeight,
  isPortrait,
  imageClassName,
  classes,
  imageErrorClass,
  placeHolderClass
}) => {
  const { t } = useTranslation()
  const [errorImage, setErrorImage] = useState(false)

  const handleErrorImage = () => {
    setErrorImage(true)
  }

  useEffect(() => {
    if (showImagePlaceholder) {
      setErrorImage(false)
    }
    // eslint-disable-next-line
  }, [src])

  return (
    <Tooltip arrow title={name}>
      <div
        className={classNames(classes.root, imageRootClassName, {
          [classes.errorHeight]: errorImage && !isPortrait,
          [classes.errorPortraitHeight]: errorImage && isPortrait,
          [imageErrorClass]: errorImage
        })}
      >
        {showImagePlaceholder ? (
          <ImagePreview
            imageHeight={imageHeight}
            src={src}
            isLoading={isLoading}
            isPortrait={isPortrait}
            imageRootClassName={imageRootClassName}
            imageClassName={imageClassName}
            onError={handleErrorImage}
            error={errorImage}
            {...(type === previewGridTypes.myDesign &&
              !src &&
              errorImage && {
                fallbackElement: (
                  <div className={classes.fallbackElement}>
                    <DrawerIcon hovering={true} />
                    <Text>{t('Processing')} ...</Text>
                  </div>
                )
              })}
          />
        ) : (
          <ImageWithPlaceholder
            src={src}
            alt={name}
            onError={handleErrorImage}
            placeHolderClass={placeHolderClass}
            className={classNames(classes.image, {
              [classes.blur]: isLoading
            })}
          />
        )}
      </div>
    </Tooltip>
  )
}

export default withStyles(styles)(memo(DesignPreview))
