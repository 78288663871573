import React, { memo, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getIconInfo } from 'utils/libraryUtils'
import UserPic from 'components/UserPic'
import { SCHEDULE_MAX_TIME, SCHEDULE_MIN_TIME } from 'constants/schedule'
import { dependencyType } from 'constants/library'
import DependencyRow from './DependencyRow'
import { getMediaPreview } from 'actions/mediaActions'
import { getPlaylistPreview } from 'actions/playlistActions'
import { getTemplatePreview } from 'actions/templateActions'
import { getSchedulePreview } from 'actions/scheduleActions'
import { openDevicePreviewModal } from 'actions/new/device'
import DeviceTableIcon from 'components/Pages/Admin/DeviceLibrary/DeviceTableIcon'
import Accordion from 'components/Accordions/Accordion'
import Spacing from 'components/Containers/Spacing'
import {
  designGalleryFeature,
  menuMakerFeature
} from 'constants/featureConstants'
import MenuIcon from 'components/Canvas/icons/MenuIcon'
import camelCaseToSplitCapitalize from 'utils/camelCaseToSplitCapitalize'
import ClientIcon from './ClientIcon'
import Text from 'components/Typography/Text'

const DependencyList = ({ data, type, isBulk, hasBulkView, classes }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const getCommonType = type => {
    if (
      [dependencyType.attachPlaylist, dependencyType.detachPlaylist].includes(
        type
      )
    ) {
      return dependencyType.playlist
    } else if (
      [dependencyType.attachSchedule, dependencyType.detachSchedule].includes(
        type
      )
    ) {
      return dependencyType.schedule
    }

    return type
  }

  const getParsedRow = useCallback(
    row => {
      const _type = getCommonType(type)
      const icon = getIconInfo(row, _type)
      switch (_type) {
        case dependencyType.media:
        case dependencyType.playlist:
        case dependencyType.template:
          return {
            icon,
            title: row.title,
            subTitle: row.duration,
            date: row.updatedAt
          }
        case dependencyType.schedule:
          return {
            icon,
            title: row.title,
            subTitle: row.allDay
              ? `${SCHEDULE_MIN_TIME} - ${SCHEDULE_MAX_TIME}`
              : `${row.startTime} - ${row.endTime}`,
            date: row.updatedAt,
            isSmartSchedule: row.isSmartSchedule
          }
        case dependencyType.device:
          const { deviceType } = row
          return {
            icon: {
              color: deviceType.color || 'transparent',
              style: deviceType.color ? {} : undefined,
              icon: deviceType.icon
                ? null
                : () => <DeviceTableIcon deviceType={row.deviceType} />,
              iconHelperClass: deviceType.icon,
              title: deviceType.name
            },
            title: row.name,
            subTitle: row.alias,
            date: row.updatedAt
          }
        case dependencyType.user:
          return {
            iconComponent: (
              <UserPic
                noStatus
                userName={`${row.firstName} ${row.lastName}`}
                imgSrc={row.profile}
                lastLogin={row.lastAuthActivity?.activity_time}
                small
              />
            ),
            title: `${row.firstName} ${row.lastName}`,
            subTitle: row.email,
            date: row.lastAuthActivity?.activity_time
          }
        case dependencyType.client:
          return {
            iconComponent: <UserPic noStatus userName={row.name} small />,
            title: row.name,
            subTitle: row.type?.title || '',
            noDate: true
          }
        case dependencyType.presets:
          return {
            icon: {
              color: '#1565C0',
              icon: MenuIcon,
              title: 'Menu Preset'
            },
            title: row.name,
            noDate: true
          }
        case dependencyType.others:
          switch (row.moduleName) {
            case dependencyType.menuMaker:
              return {
                icon: {
                  color: menuMakerFeature?.color,
                  iconHelperClass: menuMakerFeature?.icon,
                  title: menuMakerFeature?.alias || 'Menu Maker'
                },
                title: row.name,
                noDate: true
              }
            default:
              return {
                icon
              }
          }
        case dependencyType.designGallery:
          return {
            icon: {
              color: designGalleryFeature.color,
              iconHelperClass: designGalleryFeature.icon,
              title: designGalleryFeature.alias
            },
            title: row.name,
            noDate: true
          }
        case dependencyType.entities:
          switch (row.entity.resource) {
            case dependencyType.baseClientResource:
              return {
                iconComponent: (
                  <ClientIcon serviceLevel={row.entity.serviceLevel} />
                ),
                title: camelCaseToSplitCapitalize(row.mediaType),
                subTitle: row.entity.name,
                noDate: true
              }
            default:
              return {
                icon
              }
          }
        default:
          return {
            icon
          }
      }
    },
    [type]
  )

  const handlePreviewClick = useCallback(
    (id, orientation) => () => {
      switch (type) {
        case dependencyType.media:
          return dispatch(getMediaPreview(id))
        case dependencyType.playlist:
        case dependencyType.attachPlaylist:
        case dependencyType.detachPlaylist:
          return dispatch(getPlaylistPreview(id))
        case dependencyType.template:
          return dispatch(getTemplatePreview({ id, orientation }))
        case dependencyType.schedule:
        case dependencyType.attachSchedule:
        case dependencyType.detachSchedule:
          return dispatch(getSchedulePreview({ id, orientation }))
        case dependencyType.device:
          return dispatch(openDevicePreviewModal(id))
        default:
          return
      }
    },
    [type, dispatch]
  )

  const render = useMemo(() => {
    if (!isBulk && !hasBulkView) {
      return data.map((row, index) => (
        <DependencyRow
          key={`dependency-${type}-${row.id}-${index}`}
          item={getParsedRow(row)}
          onPreviewClick={handlePreviewClick(row.id)}
        />
      ))
    }

    return Object.entries(data).map(([key, values], index) => (
      <Spacing key={`dependency-${type}-${index}`}>
        <Accordion
          {...(hasBulkView
            ? {
                headerComponent: (
                  <div className={classes.accordionHeader}>
                    <Text>
                      <span className={classes.accordionHeaderEntity}>
                        {key}
                      </span>{' '}
                      {`${t('associated_with')}:`}
                    </Text>
                  </div>
                )
              }
            : {
                title: key
              })}
          initialOpen={index === 0}
          rootClass={classes.accordionRoot}
          contentClass={classes.accordionContent}
        >
          <Spacing variant={0}>
            {values.map(row => (
              <DependencyRow
                key={`dependency-${type}-${row.id}`}
                item={getParsedRow(row)}
                onPreviewClick={handlePreviewClick(
                  row.id,
                  row.orientation,
                  row.resolution
                )}
              />
            ))}
          </Spacing>
        </Accordion>
      </Spacing>
    ))
  }, [
    t,
    data,
    isBulk,
    type,
    getParsedRow,
    handlePreviewClick,
    hasBulkView,
    classes.accordionRoot,
    classes.accordionHeader,
    classes.accordionHeaderEntity,
    classes.accordionContent
  ])

  return render
}

export default memo(DependencyList)
