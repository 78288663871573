import React, { memo, useCallback, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from '@reduxjs/toolkit'
import { Typography, Grid, withStyles } from '@material-ui/core'
import classNames from 'classnames'

import PreviewGridsItem from './PreviewGridsItem'
import Text from '../Typography/Text'
import Spacing from '../Containers/Spacing'

const styles = ({ typography, type }) => ({
  groupBackground: {
    backgroundColor: `${type === 'light' ? '#f1f1f1' : '#15263d'}`
  },
  groupWrap: {
    margin: '5px 0 30px',
    borderRadius: `${type === 'light' ? '4px' : '0px'}`,
    display: 'grid'
  },
  'cols-2': {
    gridTemplateColumns: '1fr 1fr'
  },
  'cols-3': {
    gridTemplateColumns: '1fr 1fr 1fr'
  },
  'cols-1': {
    gridTemplateColumns: '1fr'
  },
  emptyGroup: {
    transition: 'height 0.2s',
    overflow: 'hidden',
    height: 52
  },
  emptyGroupCollapsed: {
    height: 0
  },
  lightText: {
    ...typography.lightText[type],
    '&:hover': {
      cursor: 'pointer'
    }
  },
  text: typography.darkText[type]
})

const GroupPreview = ({
  title,
  tabKey,
  grids,
  onGroupClick,
  onPreviewClick,
  withBorder,
  colWidth,
  withBlur,
  isLoading,
  loaderComponent: LoaderComponent,
  classes,
  t,
  isShapeSvg
}) => {
  const [initialized, setInitialized] = useState(false)
  const [collapsed, setCollapsed] = useState(false)

  const handleGroupClick = useCallback(() => {
    onGroupClick(tabKey)
  }, [onGroupClick, tabKey])

  const handleEmptyGroupClick = useCallback(() => {
    setCollapsed(prevState => !prevState)
  }, [])

  useEffect(
    () => {
      if (!initialized && !isLoading) {
        setCollapsed(!grids.length)
        setInitialized(true)
      }
    },
    // eslint-disable-next-line
    [isLoading, grids]
  )

  return (
    <>
      <Grid container justifyContent="space-between">
        <Typography className={classes.text}>{title}</Typography>
        <Typography
          className={classes.lightText}
          onClick={!grids.length ? handleEmptyGroupClick : handleGroupClick}
        >
          {t('Show All')}
        </Typography>
      </Grid>
      {isLoading ? (
        LoaderComponent
      ) : (
        <Grid
          container
          className={classNames(
            classes.groupWrap,
            classes[`cols-${colWidth}`],
            {
              [classes.groupBackground]: !isLoading,
              [classes['cols-1']]: isLoading || !grids.length,
              [classes.emptyGroup]: !grids.length,
              [classes.emptyGroupCollapsed]: !grids.length && collapsed
            }
          )}
        >
          {isLoading ? (
            LoaderComponent
          ) : grids.length ? (
            grids.map((grid, key) => (
              <PreviewGridsItem
                key={key}
                grid={grid}
                onClick={onPreviewClick}
                withBorder={withBorder}
                withBlur={withBlur}
                isLoading={isLoading}
                isShapeSvg={isShapeSvg}
              />
            ))
          ) : (
            <Spacing paddingVert={2} alignItems="center" variant={0}>
              <Text variant="small">{t('Nothing to show')}</Text>
            </Spacing>
          )}
        </Grid>
      )}
    </>
  )
}

export default compose(
  withTranslation('translations'),
  withStyles(styles),
  memo
)(GroupPreview)
