import { createAppApi } from 'services/api'
import apiTags from 'constants/apiTags'
import { createSuccessInvalidator } from 'utils/apiUtils'

export const smartSchedulesApi = createAppApi('smartSchedulesQuery', {
  tagTypes: [apiTags.smartSchedules],
  endpoints: builder => ({
    postSmartSchedule: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.smartSchedules]),
      query: data => ({
        method: 'POST',
        url: '/schedule/smart',
        data
      })
    }),
    putSmartSchedule: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.smartSchedules]),
      query: ({ id, data }) => ({
        method: 'PUT',
        url: `/schedule/smart/${id}`,
        data
      })
    }),
    buildSmartSchedule: builder.mutation({
      invalidatesTags: createSuccessInvalidator([apiTags.smartSchedules]),
      query: data => ({
        method: 'POST',
        url: '/schedule/smart/build',
        data
      })
    })
  })
})

export const {
  usePostSmartScheduleMutation,
  usePutSmartScheduleMutation,
  useBuildSmartScheduleMutation
} = smartSchedulesApi
