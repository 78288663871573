import {
  mediaService,
  playlistService,
  groupsService,
  templateService,
  scheduleService,
  reportService,
  clientLicensesService,
  clientsService,
  clientPackageService,
  bandwidthPackageService,
  devicePackageService,
  HTMLContentService,
  fontsService,
  deviceService,
  usersService,
  quoteService,
  configService,
  menuMakerService,
  emailNotificationService,
  resellerClientsService,
  resellerClientUserService
} from 'services'
import { _get, _uniqBy } from 'utils/lodash'
import { setFontOptions } from 'actions/fontsActions'
import * as types from 'actions'
import {
  transformClientNameWithIdToOptions,
  transformDataToOptions
} from 'utils/transformToOptionsUtils'
import { insetColons, removeColons } from 'utils/macAddressUtils'

export const services = {
  clientPackage: {
    getItems: clientPackageService.getClientPackage
  },
  devicePackage: {
    getItems: devicePackageService.getDevicePackage
  },
  bandwidthPackage: {
    getItems: bandwidthPackageService.getBandwidthPackage
  },
  client: {
    getItems: clientsService.getClients
  },
  clientLicense: {
    getItems: clientLicensesService.getClientLicense
  },
  user: {
    getItems: usersService.getItems
  },
  HTMLContent: {
    getItems: HTMLContentService.getHTMLContents
  },
  schedule: {
    getItems: scheduleService.getItems
  },
  template: {
    getItems: templateService.getItems
  },
  report: {
    getItems: reportService.getItems
  },
  playlist: {
    getItems: playlistService.getPlaylistGridEmbeddedItems
  },
  media: {
    getItems: mediaService.getMediaGridViewEmbeddedItems
  },
  font: {
    getItems: fontsService.getFonts
  },
  deviceTypes: {
    getItems: deviceService.getDeviceTypes
  },
  appVersions: {
    getItems: deviceService.getAppVersions
  },
  quote: {
    getItems: quoteService.getQuotes
  },
  device: {
    getItems: deviceService.getItems,
    getDeviceLocations: deviceService.getDeviceLocations
  },
  templateBackground: {
    getItems: templateService.getTemplateBackgrounds
  },
  configFipsLocation: {
    getItems: configService.getFipsLocation
  },
  emailNotification: {
    getItems: emailNotificationService.getEmailNotificationItems
  },
  menuMaker: {
    menuItems: {
      getItems: menuMakerService.getMenuItems
    },
    menuLocations: {
      getItems: menuMakerService.getMenuLocations
    },
    menuStations: {
      getItems: menuMakerService.getMenuStations
    }
  },
  configClientTypes: {
    getItems: configService.getConfigClientType
  },
  configFeatureDevice: {
    getItems: configService.getConfigFeatureDevice
  },
  configFeatureClient: {
    getItems: configService.getConfigFeatureClient
  },
  resellerClient: {
    getItems: resellerClientsService.getItems
  },
  resellerClientUser: {
    getItems: resellerClientUserService.getItems
  },
  configOrgRole: {
    getItems: configService.getConfigOrgRole
  },
  clientLicenses: {
    getItems: clientsService.getClientLicenses
  },
  configAirportCities: {
    getItems: configService.getAirportCities
  }
}

export const getGroupOptionsByEntity = entity => async (value, limit) =>
  await groupsService
    .getGroupByEntity(
      entity,
      {
        fields: 'title,color',
        title: value.length > 1 ? value : undefined,
        sort: 'title',
        order: 'asc',
        limit
      },
      undefined,
      true
    )
    .then(response =>
      _get(response, 'data', []).map(({ title, color }) => ({
        value: title,
        label: title,
        color
      }))
    )
    .catch(() => [])

export const getOptionsByFieldAndEntity = (
  field,
  entity,
  transformData,
  silent,
  options = {},
  search = null,
  sort = null,
  impersonated = false
) => {
  const searchField = search || (Array.isArray(field) ? field[0] : field)
  return async (value, limit) =>
    await _get(services, entity)
      .getItems(
        {
          fields: Array.isArray(field) ? field.join(',') : field,
          [searchField]: value || undefined,
          sort: sort || searchField,
          order: 'asc',
          limit,
          ...options
        },
        silent,
        impersonated
      )
      .then(response => {
        const data =
          _get(response, 'data') || (Array.isArray(response) ? response : [])
        return transformData
          ? transformData(data, field)
          : data
              .map(item => item[searchField])
              .filter(
                (value, index, array) => array.indexOf(value) === index && value
              )
              .map(value => ({
                value,
                label: value
              }))
      })
}

export const getOptionsByFieldAndEntityRTKQ = ({
  field,
  transformData,
  silent,
  options = {},
  search = null,
  sort = null,
  impersonated = false,
  fetcher
}) => {
  const searchField = search || (Array.isArray(field) ? field[0] : field)
  return async (value, limit) =>
    await fetcher(
      {
        fields: Array.isArray(field) ? field.join(',') : field,
        [searchField]: value || undefined,
        sort: sort || searchField,
        order: 'asc',
        limit,
        ...options
      },
      silent,
      impersonated
    )
      .unwrap()
      .then(res => {
        const data = _get(res, 'data') || (Array.isArray(res) ? res : [])
        return transformData
          ? transformData(data, field)
          : data
              .map(item => item[searchField])
              .filter(
                (value, index, array) => array.indexOf(value) === index && value
              )
              .map(value => ({
                value,
                label: value
              }))
      })
}

export const getDistinctOptionsByFieldAndEntity = (
  field,
  entity,
  transformData,
  silent,
  options = {},
  ...args
) => {
  const opt = {
    distinct: true,
    ...options
  }
  return getOptionsByFieldAndEntity(
    field,
    entity,
    transformData,
    silent,
    opt,
    ...args
  )
}

export const getDeviceLocationOptions = async (value, limit) => {
  return services.device
    .getItems({
      fields: 'city,state',
      location: value,
      sort: 'city',
      order: 'asc',
      distinct: true,
      limit
    })
    .then(response => {
      const data = _get(response, 'data', [])
      return transformDataToOptions('city', ['city', 'state'])(data)
    })
}

export const getDeviceLocationViewOptions = async limit => {
  return services.device
    .getDeviceLocations({
      limit
    })
    .then(response => {
      const data = _get(response, 'data', [])
      return transformDataToOptions('id', ['name'])(data)
    })
}

export const getUsersByFieldAndEntity = () => {
  return async (value, limit) => {
    const firstNamesOptions = await services['user']
      .getItems({
        firstName: value || undefined,
        sort: 'firstName',
        order: 'asc',
        limit
      })
      .then(response =>
        _get(response, 'data', []).map(item => ({
          value: `${item['firstName']} ${item['lastName']}`,
          label: `${item['firstName']} ${item['lastName']}`,
          id: item.id
        }))
      )
    const lastNameOptions = await services['user']
      .getItems({
        lastName: value || undefined,
        sort: 'firstName',
        order: 'asc',
        limit
      })
      .then(response =>
        _get(response, 'data', []).map(item => ({
          value: `${item['firstName']} ${item['lastName']}`,
          label: `${item['firstName']} ${item['lastName']}`,
          id: item.id
        }))
      )
    const result = [...firstNamesOptions]
    ;(await lastNameOptions.length) &&
      lastNameOptions.forEach(item => {
        if (!firstNamesOptions.find(i => i.id === item.id)) {
          result.push(item)
        }
      })
    return result
  }
}

export const getMediaContentTitleByFeature = data => {
  return (
    data?.map(({ name }) => ({
      value: name,
      label: name
    })) || []
  )
}

export const getMediaContentOptionsByFieldAndFeature = (
  data,
  fields,
  searchField,
  transformData
) => {
  const targetField = searchField || fields[0]

  return transformData
    ? transformData(data, fields)
    : transformDataToOptions(targetField, fields)(data)
}

export const getOptionsByFieldAndFeature = (field, featureId, options = {}) => {
  return async (value, limit) =>
    await mediaService
      .getMediaLibraryItems({
        fields: field,
        [field]: value || undefined,
        order: 'asc',
        sort: field,
        featureId,
        limit,
        ...options
      })
      .then(response =>
        _get(response, 'data', []).map(item => ({
          value: item[field],
          label: item[field]
        }))
      )
}

export const getFontOptions = dispatch => async (value = '', params) => {
  try {
    const response = await services.font.getItems({
      family: value || undefined,
      sort: 'family',
      order: 'asc',
      ...params
    })

    const options = _uniqBy(
      _get(response, 'data', []).map(font => ({
        value: font.family,
        label: font.family,
        font
      })),
      'value'
    )
    const result = {
      data: options,
      meta: {
        ...response.meta,
        value
      }
    }

    dispatch({ type: types.GET_FONTS_SUCCESS, payload: response })
    dispatch(setFontOptions(result))
    return result
  } catch (e) {
    return { error: e.message }
  }
}

export const getDeviceTypeOptions = async (value, client_id, limit, isOrg) => {
  return await services.deviceTypes
    .getItems(
      {
        fields: 'name',
        name: value || undefined,
        sort: 'name',
        order: 'asc',
        limit,
        client_id
      },
      true,
      isOrg
    )
    .then(response => {
      const values = []
      _get(response, 'data', response).map(item => values.push(item.name))
      return values.map(value => ({
        value,
        label: value
      }))
    })
}

export const getDeviceTypeOptionsForOrgFilter = async (value, limit) => {
  return await services.device
    .getItems(
      {
        fields: 'deviceType,deviceTypeId',
        name: value || undefined,
        sort: 'deviceType',
        order: 'asc',
        limit,
        distinct: true
      },
      true
    )
    .then(response => {
      const values = []
      _get(response, 'data', response).map(
        item =>
          _get(item, 'deviceType.name') &&
          values.push(_get(item, 'deviceType.name'))
      )
      return values.map(value => ({
        value,
        label: value
      }))
    })
}

export const getMacAddressOptions = async (value, limit) => {
  return await services.device
    .getItems({
      fields: 'macAddress',
      macAddress: removeColons(value) || undefined,
      sort: 'macAddress',
      order: 'desc',
      limit,
      distinct: true
    })
    .then(response => {
      const values = []
      _get(response, 'data', []).forEach(item =>
        (_get(item, 'macAddress', []) || []).forEach(({ macAddress }) =>
          values.push(macAddress)
        )
      )
      return values
        .filter(
          (value, index, array) => array.indexOf(value) === index && value
        )
        .map(value => ({
          value,
          label: insetColons(value)
        }))
    })
}

export const getFirmWareOptions = async (value, limit) => {
  const response = services.device.getItems(
    {
      fields: 'firmware',
      firmware: value || undefined,
      sort: 'firmWare',
      order: 'asc',
      limit,
      distinct: true,
      cancelable: true
    },
    true
  )
  const values = []
  _get(await response, 'data', []).map(
    item =>
      item.firmware &&
      values.push({
        value: item.firmware,
        label: item.firmware
      })
  )

  return values
}

export const getTeamViewerOptions = async (value, limit) => {
  return await services.device
    .getItems(
      {
        fields: 'teamviewerId',
        teamviewerId: value || undefined,
        sort: 'teamviewerId',
        order: 'asc',
        limit,
        distinct: true
      },
      true
    )
    .then(response => {
      const values = []
      _get(response, 'data', []).map(item => values.push(item.teamviewerId))
      return values.map(value => ({
        value,
        label: value
      }))
    })
}

export const getDeviceOptionsByField = async (field, value, limit) => {
  return await services.device
    .getItems(
      {
        fields: field,
        [field]: value || undefined,
        sort: field,
        order: 'asc',
        limit,
        distinct: true
      },
      true
    )
    .then(response => {
      const values = []
      _get(response, 'data', []).map(item => values.push(item[field]))
      return values
        .filter(value => value)
        .map(value => ({
          value,
          label: value
        }))
    })
}

export const getClientsOptions = async (value, limit) => {
  try {
    const nameOptions = getOptionsByFieldAndEntity(
      'clientName',
      'client',
      transformClientNameWithIdToOptions,
      null,
      null,
      'clientName'
    )(value, limit)

    let idOptions = new Promise(resolve => resolve([]))

    if (value && !isNaN(value)) {
      idOptions = getOptionsByFieldAndEntity(
        'clientName',
        'client',
        transformClientNameWithIdToOptions,
        null,
        null,
        'id'
      )(value, limit)
    }

    return _uniqBy([...(await nameOptions), ...(await idOptions)], 'value')
  } catch (e) {
    return []
  }
}

export const getEmailReceiverOptions = async (roleId, value, params) => {
  return await emailNotificationService
    .getEmailReceiverData(roleId, {
      [isNaN(value) ? 'name' : 'id']: value || undefined,
      order: 'asc',
      ...params
    })
    .then(response => {
      const data = _get(response, 'data', []).map(
        ({ id, firstName, lastName }) => ({
          value: id,
          label: `${firstName} ${lastName}`
        })
      )
      return {
        meta: {
          ...response.meta,
          value
        },
        data
      }
    })
}

export const getMenuMakerLocationOptions = async (value, params) => {
  return await services.menuMaker.menuLocations
    .getItems({
      name: value || undefined,
      order: 'asc',
      distinct: true,
      ...params
    })
    .then(response => {
      const data = _get(response, 'data', []).map(item => ({
        value: item.id,
        label: item.name
      }))

      return {
        ...response,
        data
      }
    })
}

export const getMenuMakerStationOptions = async (value, params) => {
  return await services.menuMaker.menuStations
    .getItems({
      name: value || undefined,
      order: 'asc',
      distinct: true,
      ...params
    })
    .then(response => {
      const data = _get(response, 'data', []).map(item => ({
        value: item.id,
        label: item.name
      }))

      return {
        ...response,
        data
      }
    })
}

export const getTimeZoneOptionsForEmailNotification = async () => {
  return await emailNotificationService
    .getEmailNotificationTimeZones()
    .then(data => {
      return Object.entries(data).map(([key, val]) => ({
        label: key,
        value: val
      }))
    })
}
