export const KEY = 'mapplic-admin-'

export const messageTypes = {
  setMapJSON: `${KEY}set-map-json`,
  setTitle: `${KEY}set-title`,
  setImage: `${KEY}set-image`,
  setLayerImage: `${KEY}set-layer-image`,
  requestImage: `${KEY}request-image`,
  requestLayerImage: `${KEY}request-layer-image`,
  saveMap: `${KEY}save-map`,
  theme: `${KEY}get-theme`
}

export const initialMapConfig = {
  layers: [
    {
      name: 'map',
      file: '',
      id: 'map'
    }
  ],
  locations: [],
  breakpoints: [
    {
      below: 9000,
      container: 600,
      name: 'all-screens'
    },
    {
      name: 'mobile',
      portrait: true,
      below: 480
    }
  ],
  settings: {
    zoomButtons: 'bottom-right',
    mapWidth: 1000,
    resetButton: 'bottom-right',
    mapHeight: 600,
    layerSwitcher: 'top-right',
    maxZoom: 3,
    hoverTooltip: true,
    layer: 'map',
    zoom: true
  },
  groups: [],
  styles: [],
  filters: [],
  samples: [],
  routes: []
}
