import { _get, _uniqBy } from 'utils/lodash'

import {
  mediaService,
  playlistService,
  templateService,
  scheduleService,
  reportService,
  clientsService,
  clientPackageService,
  bandwidthPackageService,
  devicePackageService,
  HTMLContentService,
  fontsService,
  deviceService,
  usersService,
  quoteService,
  configService,
  resellerClientsService,
  groupsService
} from 'services'
import {
  transformClientNameWithIdToOptions,
  transformDataToOptions,
  transformMigrationToClientNameOptions
} from 'utils/transformToOptionsUtils'
import { sortByLabel } from 'utils/libraryUtils'
import { selectUtils } from 'utils/index'
import { getSearchParams } from 'utils/filterUtils'
import { entityGroupsConstants } from '../constants'

export const serviceEntities = {
  HTMLContent: 'HTMLContent',
  appVersions: 'appVersions',
  bandwidthPackage: 'bandwidthPackage',
  client: 'client',
  clientPackage: 'clientPackage',
  clientUser: 'clientUser',
  device: 'device',
  devicePackage: 'devicePackage',
  deviceTypes: 'deviceTypes',
  font: 'font',
  media: 'media',
  migration: 'migration',
  playlist: 'playlist',
  quote: 'quote',
  report: 'report',
  resellerClient: 'resellerClient',
  schedule: 'schedule',
  template: 'template',
  templateBackground: 'templateBackground',
  user: 'user',
  authToken: 'authToken',
  menuItem: 'menuItem'
}

export const services = {
  [serviceEntities.clientPackage]: {
    getItems: clientPackageService.getClientPackage
  },
  [serviceEntities.devicePackage]: {
    getItems: devicePackageService.getDevicePackage
  },
  [serviceEntities.bandwidthPackage]: {
    getItems: bandwidthPackageService.getBandwidthPackage
  },
  [serviceEntities.client]: {
    getItems: clientsService.getClients
  },
  [serviceEntities.user]: {
    getItems: usersService.getItems
  },
  [serviceEntities.HTMLContent]: {
    getItems: HTMLContentService.getHTMLContents
  },
  [serviceEntities.schedule]: {
    getItems: scheduleService.getItems
  },
  [serviceEntities.template]: {
    getItems: templateService.getItems
  },
  [serviceEntities.report]: {
    getItems: reportService.getItems
  },
  [serviceEntities.authToken]: {
    getItems: configService.getOauth2Tokens
  },
  [serviceEntities.playlist]: {
    getItems: playlistService.getItems
  },
  [serviceEntities.media]: {
    getItems: mediaService.getMediaLibraryItems
  },
  [serviceEntities.font]: {
    getItems: fontsService.getFonts
  },
  [serviceEntities.deviceTypes]: {
    getItems: deviceService.getDeviceTypes
  },
  [serviceEntities.appVersions]: {
    getItems: deviceService.getAppVersions
  },
  [serviceEntities.quote]: {
    getItems: quoteService.getQuotes
  },
  [serviceEntities.device]: {
    getItems: deviceService.getItems,
    getLocations: deviceService.getDeviceLocations
  },
  [serviceEntities.templateBackground]: {
    getItems: templateService.getTemplateBackgrounds
  },
  [serviceEntities.resellerClient]: {
    getItems: resellerClientsService.getItems
  },
  group: {
    getItems: groupsService.getGroupByEntity
  },
  shorelinePrice: {
    getItems: configService.getShorelinePrice
  }
}

export const createSimpleOptionsGetter = (
  entity,
  valueName,
  defaultParams = {},
  dataTransformer
) => async (value, params) => {
  try {
    const { data, meta } = await services[entity].getItems(
      {
        [valueName]: value || undefined,
        sort: 'title',
        order: 'asc',
        ...defaultParams,
        ...params
      },
      true
    )

    const options = dataTransformer
      ? dataTransformer(data)
      : transformDataToOptions(valueName)(data)

    return {
      data: options,
      meta
    }
  } catch (e) {
    return { error: e.message }
  }
}

export const getClientsOptions = (
  staticParams = {},
  resellerClients = false
) => async (value, params) => {
  try {
    const { impersonated, ...restStaticParams } = staticParams
    const nameResponse = services[
      resellerClients ? 'resellerClient' : 'client'
    ].getItems(
      {
        ...restStaticParams,
        ...getSearchParams(value, 'clientName'),
        sort: 'clientName',
        order: 'asc',
        fields: 'clientName',
        cancelable: true,
        ...params
      },
      true,
      impersonated
    )

    let idResponse = new Promise(resolve => resolve({ data: [], meta: {} }))

    if (value && !isNaN(value)) {
      idResponse = services.client.getItems(
        {
          ...restStaticParams,
          id: value || undefined,
          sort: 'clientName',
          order: 'asc',
          fields: 'clientName',
          ...params
        },
        true,
        impersonated
      )
    }

    const options = sortByLabel(
      _uniqBy(
        [
          ...transformClientNameWithIdToOptions(
            _get(await nameResponse, 'data', [])
          ),
          ...transformClientNameWithIdToOptions(
            _get(await idResponse, 'data', [])
          )
        ],
        'value'
      )
    )

    const meta = {
      currentPage: Math.max(
        _get(await nameResponse, 'meta.currentPage', 1),
        _get(await idResponse, 'meta.currentPage', 1)
      ),
      lastPage: Math.max(
        _get(await nameResponse, 'meta.lastPage', 1),
        _get(await idResponse, 'meta.lastPage', 1)
      ),
      value
    }

    return {
      data: options,
      meta
    }
  } catch (e) {
    return { error: e.message }
  }
}

export const getDeviceGroupOptions = async (value, params) => {
  try {
    const { data, meta } = await services.group.getItems(
      entityGroupsConstants.Device,
      {
        title: value || undefined,
        sort: 'title',
        order: 'asc',
        ...params
      },
      undefined,
      true
    )

    const options = transformDataToOptions('title')(data)

    return {
      data: options,
      meta
    }
  } catch (e) {
    return { error: e.message }
  }
}

export const getGroupOptions = (
  entity,
  hasWritePermission,
  impersonated
) => async (value, params) => {
  try {
    const { data, meta } = await services.group.getItems(
      entity,
      {
        title: value || undefined,
        sort: 'title',
        order: 'asc',
        ...(hasWritePermission && { hasWritePermission: 1 }),
        ...params
      },
      undefined,
      true,
      impersonated
    )

    const options = data.map(group => selectUtils.toGroupChipObj(group))

    return {
      data: options,
      meta
    }
  } catch (e) {
    return { error: e.message }
  }
}

export const getLocationOptions = async (value, params) => {
  try {
    const { data, meta } = await services.device.getLocations({
      name: value || undefined,
      sort: 'name',
      order: 'asc',
      ...params
    })

    const options = transformDataToOptions('name')(data)

    return {
      data: options,
      meta
    }
  } catch (e) {
    return { error: e.message }
  }
}

export const getMigrationOptions = (staticParams = {}) => async (
  value,
  params
) => {
  try {
    const { impersonated, ...restStaticParams } = staticParams
    const nameResponse = services.migration.getItems(
      {
        ...restStaticParams,
        clientName: value || undefined,
        sort: 'clientName',
        order: 'asc',
        fields: 'clientName,clientId,client',
        ...params
      },
      true,
      impersonated
    )

    let idResponse = new Promise(resolve => resolve({ data: [], meta: {} }))

    if (value && !isNaN(value)) {
      idResponse = services.migration.getItems(
        {
          ...restStaticParams,
          clientId: value || undefined,
          sort: 'clientName',
          order: 'asc',
          fields: 'clientName,clientId,client',
          ...params
        },
        true,
        impersonated
      )
    }

    const options = sortByLabel(
      _uniqBy(
        [
          ...transformMigrationToClientNameOptions(
            _get(await nameResponse, 'data', [])
          ),
          ...transformMigrationToClientNameOptions(
            _get(await idResponse, 'data', [])
          )
        ],
        'value'
      )
    )

    const meta = {
      currentPage: Math.max(
        _get(await nameResponse, 'meta.currentPage', 1),
        _get(await idResponse, 'meta.currentPage', 1)
      ),
      lastPage: Math.max(
        _get(await nameResponse, 'meta.lastPage', 1),
        _get(await idResponse, 'meta.lastPage', 1)
      ),
      value
    }

    return {
      data: options,
      meta
    }
  } catch (e) {
    return { error: e.message }
  }
}

export const createOptionsGetter = (
  entity,
  field,
  staticParams = {},
  dataTransformer
) => async (value, params) => {
  try {
    const { data, meta } = await services[entity].getItems({
      [field]: value || undefined,
      sort: field,
      order: 'asc',
      distinct: true,
      ...staticParams,
      ...params
    })

    const options = dataTransformer
      ? dataTransformer(data)
      : transformDataToOptions(field)(data)

    return {
      data: options,
      meta
    }
  } catch (e) {
    return { error: e.message }
  }
}

export const getDeviceTypeOptionsWithMeta = async (
  value,
  client_id,
  params,
  isOrg
) => {
  return await services.deviceTypes
    .getItems(
      {
        fields: 'name,id',
        name: value || undefined,
        sort: 'name',
        order: 'asc',
        ...params,
        client_id
      },
      true,
      isOrg
    )
    .then(response => {
      const values = []
      _get(response, 'data', response).map(item =>
        values.push({ name: item.name, id: item.id })
      )

      const options = values.map(value => ({
        value: value.id,
        label: value.name
      }))

      const defaultMeta = {
        currentPage: 1,
        count: options.length,
        total: options.length
      }

      return {
        data: options,
        meta: _get(response, 'meta') || defaultMeta
      }
    })
}

export const getDeviceOptionsByFieldWithMeta = async ({
  field,
  value,
  params,
  label = 'name'
}) => {
  return await services.device
    .getItems(
      {
        fields: field,
        [field]: value || undefined,
        sort: field,
        order: 'asc',
        distinct: true,
        ...params
      },
      true
    )
    .then(response => {
      const values = []
      _get(response, 'data', response).map(item =>
        values.push({ name: item.name, id: item.id })
      )

      const options = values.map(value => ({
        value: value.id,
        label: value[label]
      }))

      const defaultMeta = {
        currentPage: 1,
        count: options.length,
        total: options.length
      }

      return {
        data: options,
        meta: _get(response, 'meta') || defaultMeta
      }
    })
}

export const createToolkitOptionsGetter = ({
  getItems,
  field,
  dataTransformer
}) => async (value, params) => {
  try {
    const { data } = await getItems({
      [field]: value || undefined,
      sort: field,
      order: 'asc',
      distinct: true,
      ...params
    })

    const options = dataTransformer
      ? dataTransformer(data)
      : transformDataToOptions(field)(data.data)

    return {
      data: options,
      meta: data.meta
    }
  } catch (e) {
    return { error: e.message }
  }
}
