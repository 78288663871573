import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { withStyles, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Tooltip from 'components/Tooltip'

const styles = ({ spacing }) => ({
  root: {
    width: '100%',
    position: 'relative',
    backgroundColor: '#f1f1f1',
    overflow: 'hidden',
    borderRadius: '4px',
    height: 80,
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  image: {
    maxHeight: '100%',
    width: '100%'
  },
  portrait: {
    height: 123,
    maxHeight: 123
  },
  deleteIcon: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  blur: {
    filter: 'blur(5px)'
  },
  error: {
    textAlign: 'center',
    padding: spacing(1)
  }
})

const ImagePreview = ({
  src = '',
  isLoading,
  isPortrait,
  classes,
  imageRootClassName = '',
  imageClassName = '',
  onError = f => f,
  imageHeight,
  tooltip,
  fallbackElement,
  error
}) => {
  const { t } = useTranslation('translations')
  const [imgError, setimgError] = useState(error)

  useEffect(() => {
    if (!src) {
      onError()
    }
    // eslint-disable-next-line
  }, [])

  const handleError = useCallback(() => {
    setimgError(true)
  }, [])

  const renderImage = useMemo(
    () => (
      <img
        src={src}
        crossOrigin="anonymous"
        alt={'item'}
        onError={handleError}
        className={classNames(classes.image, imageClassName, {
          [classes.blur]: isLoading,
          [classes.portrait]: isPortrait
        })}
        style={{ height: imageHeight }}
      />
    ),
    [
      src,
      classes,
      imageHeight,
      imageClassName,
      isLoading,
      isPortrait,
      handleError
    ]
  )

  return (
    <Grid
      container
      alignItems={'center'}
      justifyContent="center"
      className={classNames(classes.root, imageRootClassName, {
        [classes.portrait]: isPortrait
      })}
    >
      {!imgError ? (
        <>
          {tooltip ? (
            <Tooltip arrow title={tooltip}>
              {renderImage}
            </Tooltip>
          ) : (
            renderImage
          )}
        </>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classNames(classes.image, imageClassName, {
            [classes.blur]: isLoading,
            [classes.portrait]: isPortrait,
            [classes.error]: error
          })}
          style={{ height: imageHeight }}
        >
          {!src && fallbackElement ? fallbackElement : t('No Image')}
        </Grid>
      )}
    </Grid>
  )
}

export default withStyles(styles)(memo(ImagePreview))
