import { useCallback, useMemo, useState } from 'react'

export default function useToggle(initialState = false) {
  const [toggled, setToggled] = useState(initialState)

  const setTrue = useCallback(() => {
    setToggled(true)
  }, [])

  const setFalse = useCallback(() => {
    setToggled(false)
  }, [])

  const toggle = useCallback(() => {
    setToggled(prevState => !prevState)
  }, [])

  return useMemo(
    () => ({
      toggled,
      toggle,
      setTrue,
      setFalse
    }),
    [toggled, toggle, setTrue, setFalse]
  )
}
