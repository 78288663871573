import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { compose } from '@reduxjs/toolkit'
import { BottomScrollListener } from 'react-bottom-scroll-listener'
import { withStyles, CircularProgress, DialogActions } from '@material-ui/core'
import classNames from 'classnames'
import { _debounce } from 'utils/lodash'

import DefaultModal from 'components/Modal/DefaultModal'
import { BlueButton } from 'components/Buttons'
import { FormControlInput } from 'components/Form'
import MediaPreviewItem from './MediaPreviewItem'
import { useLazyGetMediaGridEmbeddedItemsQuery } from 'api/mediaApi'
import { IMAGE_FEATURE_ID } from 'constants/featureConstants'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import { approveStatuses } from 'constants/library'
import { initialMeta } from 'constants/api'

const style = ({ palette, type }) => ({
  list: {
    maxHeight: '470px',
    minHeight: '77px',
    height: 'calc(100vh - 300px)',
    padding: '10px',
    margin: '0 10px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '10px',
    overflowY: 'hidden',

    '&:hover': {
      overflowY: 'auto !important',
      paddingRight: '5px'
    },

    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: palette[type].scrollbar.background,
      borderRadius: '5px'
    }
  },
  contentWrap: {
    position: 'relative',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  loaderWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette[type].loader.backgroundColor
  },
  searchInput: {
    width: '200px',
    borderRight: '1px solid #e4e9f3',
    padding: '5px 25px 5px 0',
    marginRight: '10px'
  },
  header: {
    padding: '10px 20px',
    height: '60px'
  },
  actionBar: {
    display: 'flex',
    background: palette[type].dialog.header.background,
    borderTop: `solid 1px ${palette[type].dialog.border}`,
    padding: '0 20px',
    height: '55px',
    margin: '0'
  },
  emptyContentWrap: {
    height: 470
  }
})

const sortOptions = {
  sort: 'updatedAt',
  order: 'desc'
}

const SelectImageDialog = ({
  open,
  imageId,
  onCloseModal,
  onSelect,
  multiSelection = false,
  onlyContent = false,
  maintainImageRatio = false,
  searchText = '',
  classes,
  t
}) => {
  const [
    getMediaItemsAction,
    mediaQuery
  ] = useLazyGetMediaGridEmbeddedItemsQuery()
  const { response, meta } = useMemo(
    () => ({
      response: mediaQuery.data?.data || [],
      meta: {
        ...(mediaQuery.data?.meta || initialMeta),
        isLoading: mediaQuery.isFetching || mediaQuery.isUninitialized
      }
    }),
    [mediaQuery]
  )

  const [data, setData] = useState([])
  const [selectedMedia, setSelectedMedia] = useState(multiSelection ? [] : {})
  const [page, setPage] = useState(1)
  const [searchName, setSearchName] = useState('')
  const [endPage, setEndPage] = useState(null)

  const scrollRef = useRef(null)

  const onContentScrollEnd = useCallback(() => {
    if (page < endPage) {
      setPage(prev => prev + 1)
    }
  }, [page, endPage])

  const handleSelectedMediaChange = useCallback(
    media => {
      if (multiSelection) {
        const mediaIndex = selectedMedia.findIndex(
          ({ imageId }) => imageId === media.id
        )
        if (mediaIndex > -1) {
          const medias = [...selectedMedia]
          medias.splice(mediaIndex, 1)
          setSelectedMedia([...medias])
        } else {
          setSelectedMedia([
            ...selectedMedia,
            {
              imageId: media.id,
              imageTitle: media.title,
              imageSize: media.size,
              imageUrl: media.mediaUrl || media.thumbnail
            }
          ])
        }
      } else {
        setSelectedMedia({
          imageId: media.id,
          imageTitle: media.title,
          imageSize: media.size
        })
      }
    },
    [selectedMedia, multiSelection]
  )

  const handleClickSelect = useCallback(() => {
    onSelect(selectedMedia)
  }, [selectedMedia, onSelect])

  const onSearchInput = useCallback(e => {
    setSearchName(e.target.value)
  }, [])

  const getData = useCallback(
    params => {
      getMediaItemsAction({
        ...sortOptions,
        page,
        limit: 15,
        featureId: IMAGE_FEATURE_ID,
        approvedStatus: approveStatuses.approve,
        ...params
      })
    },
    [page, getMediaItemsAction]
  )

  const handleSearch = useMemo(
    () =>
      _debounce(val => {
        if (val.length !== 1) {
          if (scrollRef.current) {
            scrollRef.current.scrollTop = 0
          }
          if (page === 1) {
            getData({ title: val ? val : null })
          } else setPage(1)
        }
      }, 1000),
    [page, getData, scrollRef]
  )

  useEffect(
    () => handleSearch(searchName),
    // eslint-disable-next-line
    [searchName]
  )

  useEffect(
    () => setSearchName(searchText),
    // eslint-disable-next-line
    [searchText]
  )

  useEffect(() => {
    if (meta && meta.lastPage !== endPage) setEndPage(meta.lastPage)
    // eslint-disable-next-line
  }, [meta])

  useEffect(
    () => getData({ title: searchName.length < 2 ? null : searchName }),
    // eslint-disable-next-line
    [page]
  )

  useEffect(() => {
    if (open) {
      multiSelection
        ? setSelectedMedia([
            ...(imageId.length || []).map(id => ({
              imageId: id
            }))
          ])
        : setSelectedMedia({ imageId: imageId })
    } else {
      setSearchName('')
      setPage(1)
      setSelectedMedia(multiSelection ? [] : {})
    }
    // eslint-disable-next-line
  }, [open])

  useEffect(() => {
    if (response) {
      setData(prev => (page === 1 ? response : [...prev, ...response]))
    }
    // eslint-disable-next-line
  }, [response])

  useEffect(() => {
    if (page !== 1 && scrollRef.current) {
      scrollRef.current.scroll({
        top: scrollRef.current.scrollTop + 300,
        behavior: 'smooth'
      })
    }
    // eslint-disable-next-line
  }, [data])

  const renderData = useCallback(
    listRef => {
      scrollRef.current = listRef.current

      const noDataPlaceholder =
        meta.isLoading && !data.length ? (
          <div className={classes.emptyContentWrap} />
        ) : (
          <EmptyPlaceholder
            text={t('Nothing to show')}
            rootClassName={classes.emptyContentWrap}
          />
        )
      return (
        <div
          ref={listRef}
          className={
            data.length > 0 &&
            classNames(classes.list, 'tabPane scroll-container')
          }
        >
          {data.length > 0
            ? data.map(media => (
                <MediaPreviewItem
                  key={media.id}
                  media={media}
                  selected={
                    multiSelection
                      ? selectedMedia.find(
                          ({ imageId }) => media.id === imageId
                        )
                      : media.id === selectedMedia.imageId
                  }
                  onClick={() => handleSelectedMediaChange(media)}
                  maintainImageRatio={maintainImageRatio}
                />
              ))
            : noDataPlaceholder}
        </div>
      )
    },
    [
      t,
      classes,
      data,
      meta.isLoading,
      selectedMedia,
      handleSelectedMediaChange,
      multiSelection,
      maintainImageRatio
    ]
  )

  const isSelectButtonDisabled = useMemo(() => {
    if (multiSelection) {
      return !selectedMedia.length
    }

    return !selectedMedia?.imageId
  }, [multiSelection, selectedMedia])

  const renderContent = useMemo(
    () => (
      <>
        <div className={classes.contentWrap}>
          {meta.isLoading && (
            <div className={classes.loaderWrapper}>
              <CircularProgress size={30} thickness={5} />
            </div>
          )}
          <BottomScrollListener onBottom={onContentScrollEnd}>
            {renderData}
          </BottomScrollListener>
        </div>
        {onlyContent && (
          <DialogActions className={classes.actionBar}>
            <BlueButton
              disabled={isSelectButtonDisabled}
              onClick={handleClickSelect}
              iconClassName="fa-regular fa-folder-image"
            >
              {t('Select')}
            </BlueButton>
          </DialogActions>
        )}
      </>
    ),
    [
      isSelectButtonDisabled,
      meta.isLoading,
      classes,
      renderData,
      onContentScrollEnd,
      handleClickSelect,
      onlyContent,
      t
    ]
  )

  return onlyContent ? (
    renderContent
  ) : (
    <DefaultModal
      open={open}
      modalTitle={t('Select image')}
      onCloseModal={onCloseModal}
      useDialogContent={false}
      headerClassName={classes.header}
      actions={
        <BlueButton
          disabled={isSelectButtonDisabled}
          onClick={handleClickSelect}
          iconClassName="fa-regular fa-folder-image"
        >
          {t('Select')}
        </BlueButton>
      }
      titleComponent={
        <FormControlInput
          fullWidth={false}
          placeholder={t('Search')}
          marginBottom={false}
          value={searchName}
          handleChange={onSearchInput}
          formControlRootClass={classes.searchInput}
        />
      }
    >
      {renderContent}
    </DefaultModal>
  )
}

SelectImageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClickSave: PropTypes.func,
  onCloseModal: PropTypes.func,
  multiSelection: PropTypes.bool,
  onlyContent: PropTypes.bool,
  maintainImageRatio: PropTypes.bool
}

export default compose(
  withTranslation('translations'),
  withStyles(style)
)(SelectImageDialog)
