import React, { memo } from 'react'
import { withTranslation } from 'react-i18next'
import { withStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { TextWithTooltip } from 'components/Typography'
import { getThumbnail } from '../../../../utils/mediaUtils'

const styles = ({ transitions, palette, type, typography }) => ({
  root: {
    width: '100%',
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    transition: transitions.create(['border-color', 'box-shadow']),
    borderRadius: 4,
    border: palette[type].deviceCardPreview.border,
    boxShadow: palette[type].deviceCardPreview.boxShadow,
    cursor: 'pointer'
  },
  selected: {
    borderColor: '#80bdff',
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
  },
  image: {
    width: '100%',
    maxHeight: '100%',
    paddingTop: '25px'
  },
  noPreview: {
    width: '100%',
    height: '100%',
    fontSize: '13px',
    lineHeight: '200px',
    color: typography.darkAccent[type].color,
    textAlign: 'center'
  },
  title: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: 'fit-content',
    fontSize: '12px',
    lineHeight: '14px',
    padding: '5px 10px',
    backgroundColor: palette[type].deviceCardPreview.background,
    color: typography.darkAccent[type].color
  },
  ratioImage: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto'
  }
})

const MediaPreviewItem = ({
  t,
  classes,
  media,
  selected,
  onClick,
  maintainImageRatio = false
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(classes.root, {
        [classes.selected]: selected
      })}
    >
      <TextWithTooltip maxWidth={300} rootClassName={classes.title}>
        {media.title}
      </TextWithTooltip>
      {getThumbnail(media) ? (
        <img
          className={classNames(classes.image, {
            [classes.ratioImage]: maintainImageRatio
          })}
          src={getThumbnail(media)}
          alt={media.name || t('Preview')}
        />
      ) : (
        <Typography className={classes.noPreview}>
          {t('No preview available')}
        </Typography>
      )}
    </div>
  )
}

export default withTranslation('translations')(
  withStyles(styles)(memo(MediaPreviewItem))
)
