import { initialMapConfig } from 'constants/mapplicConstants'

export const createNewMapJson = imageMedia => ({
  ...initialMapConfig,
  layers: [
    ...initialMapConfig.layers.map(layer => ({
      ...layer,
      file: imageMedia.mediaUrl,
      mediaId: imageMedia.id,
      mediaTitle: imageMedia.title
    }))
  ]
})
