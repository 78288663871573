import React, { useEffect, useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { _isFinite } from 'utils/lodash'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { Close as CloseIcon, GridOn, List } from '@material-ui/icons'
import { IconButton, Typography, withStyles } from '@material-ui/core'
import TabButtonsGroup from './TabButtonsGroup'
import { setOpenLeftSidebar } from 'actions/designGalleryActions'

import 'components/Pages/DesignGallery/styles/_leftSidebarPanel.scss'
import CanvasControlSearch from './CanvasControlSearch'
import Grid from '@material-ui/core/Grid'
import { SORTING_TYPES } from '../../constants/canvasConstants'
import { CircleIconButton } from '../Buttons'
import classNames from 'classnames'

const styles = ({ type, typography, palette }) => {
  return {
    transitionInputClass: {
      border: 'none',
      boxShadow: 'none',
      color: '#9394A1',
      letterSpacing: '-0.02px;',
      marginRight: 2,
      fontSize: 12,
      lineHeight: '14px',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      paddingRight: '17px',
      background: 'transparent',
      paddingTop: 0,
      paddingBottom: 0,
      position: 'relative',
      '&:hover': {
        background: 'transparent',
        color: '#494949'
      }
    },
    placeholder: {
      color: '#9394A1',
      fontFamily: 'inherit',
      fontSize: 'inherit'
    },
    transitionInputIconClass: {
      color: '#808B9C',
      fontSize: 24,
      lineHeight: 1,
      position: 'absolute',
      top: '50%',
      right: '-7px',
      marginTop: '-1px',
      transform: 'translateY(-50%)'
    },
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      cursor: 'pointer'
    },
    sortingButton: {
      padding: 0,
      marginRight: 5,
      '&:hover': {
        color: '#1481CE',
        opacity: '.8',
        backgroundColor: 'transparent'
      },
      '&:last-child': {
        marginRight: 15
      }
    },
    sortingButtonActive: {
      color: '#1481CE'
    },
    sortingIcon: {
      fontSize: 'inherit'
    },
    circleIcon: {
      padding: 10,
      marginTop: -5,
      color: '#9394a1'
    },
    fileUploadIcon: {
      fontSize: '1.4rem',
      marginLeft: 10
    },
    title: {
      ...typography.darkText[type],
      fontSize: '1.125rem'
    },
    searchContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      height: 32,
      marginBottom: 16
    }
  }
}

const LeftSidebarPanel = props => {
  const {
    classes,
    title,
    content,
    placeholder,
    onChangeSearch,
    withTabs,
    tabButtons,
    onChangeTabs,
    activeTab,
    searchTerm,
    onContentScrollEnd,
    scrollPosition,
    rightComponent: RightComponent,
    withSorting,
    onSortingChange,
    sortingBy
  } = props

  const dispatch = useDispatch()

  const contentRef = useBottomScrollListener(onContentScrollEnd)

  // ---- methods
  const handleChangeTabs = useCallback(
    (e, value) => {
      value && onChangeTabs(value)
    },
    [onChangeTabs]
  )

  const handleCloseTab = useCallback(() => {
    dispatch(setOpenLeftSidebar(false))
    // eslint-disable-next-line
  }, [])

  // ---- effects

  useEffect(() => {
    if (contentRef && _isFinite(scrollPosition)) {
      contentRef.current.scrollTop = scrollPosition
    }
    // eslint-disable-next-line
  }, [scrollPosition])

  return (
    <div className={'tabPane'}>
      <div className={'tabPane-header'}>
        <div className={'tabPane-title'}>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </div>
        <IconButton
          className={'tabPane-header__close'}
          onClick={handleCloseTab}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <Grid container className={classes.searchContainer}>
        <CanvasControlSearch
          placeholder={placeholder}
          value={searchTerm}
          onChange={onChangeSearch}
        />

        {RightComponent && <RightComponent />}
      </Grid>

      {withTabs && (
        <div className={'tabPane-tabs'}>
          <TabButtonsGroup
            fullWidth
            value={activeTab}
            onChange={handleChangeTabs}
            buttons={tabButtons}
          />
        </div>
      )}

      {withSorting && (
        <Grid container justifyContent={'flex-end'}>
          <CircleIconButton
            className={classNames('hvr-grow', classes.sortingButton, {
              [classes.sortingButtonActive]: sortingBy === SORTING_TYPES.grids
            })}
            onClick={() => onSortingChange(SORTING_TYPES.grids)}
          >
            <GridOn />
          </CircleIconButton>
          <CircleIconButton
            className={classNames('hvr-grow', classes.sortingButton, {
              [classes.sortingButtonActive]: sortingBy === SORTING_TYPES.row
            })}
            onClick={() => onSortingChange(SORTING_TYPES.row)}
          >
            <List />
          </CircleIconButton>
        </Grid>
      )}

      {content && (
        <div ref={contentRef} className={'tabPane-content scroll-container'}>
          {content}
        </div>
      )}
    </div>
  )
}

LeftSidebarPanel.propTypes = {
  title: PropTypes.string,
  content: PropTypes.element,
  placeholder: PropTypes.string,
  onChangeSearch: PropTypes.func,
  withTabs: PropTypes.bool,
  tabButtons: PropTypes.array,
  withFilter: PropTypes.bool,
  onChangeTabs: PropTypes.func,
  activeTab: PropTypes.string,
  searchTerm: PropTypes.string,
  onContentScrollEnd: PropTypes.func,
  isLoading: PropTypes.bool,
  isDesignsTab: PropTypes.bool,
  onDesignUpload: PropTypes.func
}

export default memo(withStyles(styles)(LeftSidebarPanel))
