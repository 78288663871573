import {
  _get,
  _isArray,
  _keys,
  _filter,
  _head,
  _isNumber,
  _uniq
} from 'utils/lodash'
import moment from 'moment'
import { objectToFormData } from 'object-to-formdata'

import { parseTxtForMedia, selectUtils } from 'utils/index'
import { isAdd, isClone, isEditOrClone, isMediaInfo } from 'utils/compare'
import {
  isNumber,
  length,
  removeSpacing,
  typesToMimeTypes
} from 'utils/generalUtils'
import { namesToTouched, errorsToTouched } from 'utils/formik'
import {
  mediaConstants,
  playlistConstants,
  templateConstants
} from 'constants/index'
import { publishTabType } from 'constants/schedule'
import { createTab } from 'components/Tabs/TabsGroup'
import { labelToSec, secToLabel } from './secToLabel'
import { ENTERPRISE_ROLE, mediaFileSubTypes, SYSTEM_ROLE } from 'constants/api'
import getMediaUrlName from './getMediaUrlName'
import {
  FAVORITE_CATEGORY_NAME,
  featureNames
} from '../constants/featureConstants'
import { isApproved, sortBySortOrder } from './libraryUtils'
import {
  mediaSortingTypes,
  MEDIA_FALLBACK_TIME,
  MEDIA_MIN_TIME,
  TIMER,
  TIMER_COMPLETE_TEXT_DURATION,
  FILE,
  excludeAlert,
  CAP_ALERT_URL_BACKEND_ERROR_MESSAGE,
  CAP_ALERT_URL_ERROR_MESSAGE,
  DESIGN_GALLERY
} from 'constants/media'
import snakeCaseToSplitCapitalize from './snakeCaseToSplitCapitalize'
import { DATE_VIEW_FORMAT } from 'constants/dateTimeFormats'
import { approveStatuses } from 'constants/library'
import { getMediaCategoryUrlName } from './urlUtils'
import isEmpty from 'utils/isEmpty'
import { secondsToDurationString } from './time'
import { CLONE_MEDIA_TITLE } from 'constants/localStorage'
import { whiteColor } from 'constants/colorConstants'
import getUserRoleLevel from 'utils/getUserRoleLevel'
import { colors } from 'theme'
import {
  DOC,
  DOCX,
  GIF,
  googleMimes,
  JPEG,
  MP3,
  MPEG,
  MPGA,
  PNG,
  PPT,
  PPTX,
  WEBM,
  XLS,
  XLSX
} from 'constants/mimeTypes'
import {
  audioTypes,
  folderMimeType,
  imageTypes,
  pdfTypes,
  videoTypes
} from './media/upload'

const csvConverter = require('csv-string')
const xmlConverter = require('xml-js')

export const getAllowedFeatureId = (mediaCategories, category, group) => {
  const allowedOptions = _get(mediaCategories, 'response', [])
  const allowedWeb = allowedOptions.find(m => m.name === category)
  const allowedFeature = _get(allowedWeb, 'feature', [])
  const allowedItem = allowedFeature.find(f => f.name === group)
  return _get(allowedItem, 'id', false)
}

export const getFeatureIdByGroup = (mediaCategories, group) => {
  const allowedOptions = _get(mediaCategories, 'response', [])
  const allowedItem =
    allowedOptions.length &&
    allowedOptions
      .find(category =>
        category.feature.find(
          feature => feature.name.toLowerCase() === group.toLowerCase()
        )
      )
      .feature.find(
        feature => feature.name.toLowerCase() === group.toLowerCase()
      )
  return _get(allowedItem, 'id', false)
}

export const getFeatureNameById = (mediaCategorys, id) => {
  const allowedOptions = _get(mediaCategorys, 'response', [])
  const feature = allowedOptions.find(
    el =>
      el.name !== FAVORITE_CATEGORY_NAME && el.feature.find(f => f.id === id)
  )
  return _get(feature, 'name', '').toLowerCase()
}

export const getVimeoIdByLink = url => {
  const vimeoUrl = 'vimeo.com'
  const index = url.indexOf(vimeoUrl) + vimeoUrl.length + 1
  return url.substring(index, url.length)
}

export const getVideoDuration = duration => {
  duration = Number(duration)
  let h = Math.floor(duration / 3600)
  let m = Math.floor((duration % 3600) / 60)
  let s = Math.floor((duration % 3600) % 60)

  let hDisplay = h > 0 ? h + 'h ' : ''
  let mDisplay = m > 0 ? m + 'min ' : ''
  let sDisplay = s > 0 ? s + 'sec' : ''
  return hDisplay + mDisplay + sDisplay
}

export const ObjectToFormData = obj =>
  objectToFormData(obj, { indices: true, nullsAsUndefineds: true })

export const createMediaPostData = (values, mode, addDuration = false) => {
  const {
    title,
    activeDate,
    expireDate,
    isRetain,
    priority,
    group,
    tags,
    categories,
    duration
  } = values

  const postData = {
    title,
    mediaPriority: +priority,
    attributes: {}
  }
  const postDataGroup = selectUtils.convertArr(group, selectUtils.fromChipObj)
  const postDataTag = selectUtils.convertArr(tags, selectUtils.fromChipObj)
  const postDataCategories = selectUtils.convertArr(categories, obj => ({
    name: obj.label,
    id: obj.value
  }))

  if (
    (isAdd(mode) && postDataGroup.length) ||
    (isEditOrClone(mode) && _isArray(postDataGroup))
  ) {
    postData.group = postDataGroup
  }
  if (
    (isAdd(mode) && postDataTag.length) ||
    (isEditOrClone(mode) && _isArray(postDataTag))
  ) {
    postData.tag = postDataTag
  }
  if (
    (isAdd(mode) && postDataCategories.length) ||
    (isEditOrClone(mode) && _isArray(postDataCategories))
  ) {
    postData.categories = postDataCategories
  }

  if (activeDate) postData.activateOn = activeDate
  if (expireDate) postData.expireOn = expireDate
  if (addDuration) postData.duration = labelToSec(duration)
  if (values.hasOwnProperty('isRetain') && !!expireDate)
    postData.isRetain = isRetain
  return postData
}

export const getMediaInfoFromBackendData = (
  backendData,
  ignoreValidityDates = false,
  mode
) => {
  const {
    title,
    mediaPriority,
    group,
    tag,
    activateOn,
    expireOn,
    duration,
    isRetain
  } = backendData

  return {
    title: isClone(mode) ? localStorage.getItem(CLONE_MEDIA_TITLE) : title,
    group: selectUtils.convertArr(group, selectUtils.toGroupChipObj),
    tags: selectUtils.convertArr(tag, selectUtils.tagToChipObj),
    priority: !!mediaPriority,
    activeDate:
      activateOn && !ignoreValidityDates ? moment(activateOn).format() : null,
    duration: typeof duration === 'number' ? secToLabel(duration) : duration,
    expireDate:
      expireOn && !ignoreValidityDates ? moment(expireOn).format() : null,
    isRetain
  }
}

export const getThemeType = (themeId, DataThemes) => {
  const { Legacy, Modern } = DataThemes
  if (Legacy && Legacy.length) {
    if (Legacy.find(({ id }) => id === themeId)) {
      return 'Legacy'
    }
  }
  if (Modern && Modern.length) {
    if (Modern.find(({ id }) => id === themeId)) {
      return 'Modern'
    }
  }
  return ''
}

export const getMediaThemesSettings = (
  customProperties,
  isDefaultSetting,
  themeSettingsPath = 'theme_settings'
) => {
  const parseSettings = (key, setting, allowedObject) => {
    if (
      typeof setting === 'string' &&
      setting.includes('[') &&
      setting.includes(']')
    ) {
      const params = setting
        .slice(setting.indexOf('[') + 1, setting.indexOf(']'))
        .split('-')
      allowedObject[`min_${key}`] = +params[0]
      allowedObject[`max_${key}`] = +params[1]
      allowedObject[key] = setting
    } else if (typeof setting === 'string' && setting.includes('|')) {
      allowedObject[key] = setting.split('|')
    } else if (key === 'click_effect') {
      allowedObject[key] = Object.keys(setting)
    } else {
      allowedObject[key] = setting
    }
  }

  const settings = isDefaultSetting
    ? _get(customProperties, 'other.default_values')
    : themeSettingsPath
    ? _get(customProperties, themeSettingsPath)
    : customProperties

  const allowedSettings = {}

  if (!settings) return

  Object.keys(settings).forEach(key => {
    const prop = settings[key]
    if (
      typeof prop === 'object' &&
      Object.keys(prop).length &&
      !Array.isArray(prop)
    ) {
      const propAllowedSettings = {}
      Object.keys(prop).forEach(propKey => {
        parseSettings(propKey, prop[propKey], propAllowedSettings)
      })
      allowedSettings[key] = propAllowedSettings
    } else {
      parseSettings(key, prop, allowedSettings)
    }
  })

  return allowedSettings
}

export const getNestedAllowedValues = (allowedThemeSettings, path) => {
  const options = _get(allowedThemeSettings, path, [])

  return Array.isArray(options) ? options : (options || '').split('|')
}

export const csvJSON = csv => {
  const rowLines = csv.split('\n')
  rowLines.splice(rowLines.length - 1, 1)
  const lines = rowLines
  const result = []
  const headers = lines[0].split(',')

  for (let i = 1; i < lines.length; i++) {
    const obj = {}
    const currentLine = lines[i].split(',')

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentLine[j]
    }

    result.push(obj)
  }

  return result
}

export function parseMediaErrors(errors = [], options = {}) {
  return errors.reduce((acc, { name, value }) => {
    const message = _head(value)
    let propName = ''

    switch (name) {
      case 'title':
      case 'name':
        return { ...acc, 'mediaInfo.title': message }
      case 'group':
        return { ...acc, 'mediaInfo.group': message }
      case 'attributes':
        if (message.includes('attributes.cap')) {
          return {
            ...acc,
            cap_alert_url:
              message === CAP_ALERT_URL_BACKEND_ERROR_MESSAGE
                ? CAP_ALERT_URL_ERROR_MESSAGE
                : message
          }
        }
      // eslint-disable-next-line
      case 'attributes.cap_alert_url':
        return {
          ...acc,
          cap_alert_url:
            message === CAP_ALERT_URL_BACKEND_ERROR_MESSAGE
              ? CAP_ALERT_URL_ERROR_MESSAGE
              : message
        }
      case 'attributes.flickr_user_id':
        return { ...acc, flickr_user_id: message }
      case 'attributes.user_name':
        return { ...acc, user_name: message }
      case 'attributes.duration':
        return { ...acc, duration: message }
      case 'attributes.is_custom':
        return { ...acc, is_custom: message }
      case 'attributes.prezi_id':
        return { ...acc, prezi_id: message }
      case 'attributes.width':
        return { ...acc, width: message }
      case 'attributes.height':
        return { ...acc, height: message }
      case 'attribute.number_of_videos':
        return { ...acc, number_of_videos: message }
      case 'attribute.youtube_id':
        return { ...acc, youtube_id: message }
      case 'attributes.network':
        return { ...acc, network: message }
      case 'attributes.label':
        return { ...acc, label: message }
      case 'file':
        return { ...acc, files: message }
      case 'attributes.content':
        return { ...acc, content: message }
      case 'attributes.size':
        return { ...acc, size: message }
      case 'attributes.background_color':
        return { ...acc, bgColor: message }
      case 'attributes.fill_color':
        return { ...acc, fillColor: message }
      case 'attributes.twitter_handle':
        return { ...acc, twitter_handle: message }
      case 'attributes.google_document_url':
        return { ...acc, url: message }
      case 'attributes.hide_toolbar':
        return { ...acc, hideToolbar: message }
      case 'attributes.refresh_every':
        return { ...acc, refreshEvery: message }
      case 'attributes.media_rss_feed_url':
        return { ...acc, media_rss_feed_url: message }
      case 'attributes.report_url':
        return { ...acc, report_url: message }
      case 'attributes.video_id':
        return { ...acc, id: message }
      case 'attributes.end_time':
        propName = 'end_time'
        if (options.selectedTab === 'suncity') {
          propName = 'endDate'
        }
        return { ...acc, [propName]: message }
      case 'attributes.start_time':
        propName = 'start_time'
        if (options.selectedTab === 'suncity') {
          propName = 'startDate'
        }
        return { ...acc, [propName]: message }
      case 'attributes.web_page_url':
        return { ...acc, url: message }
      case 'attributes.allow_scrolling':
        return { ...acc, allowScroll: message }
      case 'attributes.parameters_enabled':
        return { ...acc, allowParams: message }
      case 'attributes.animation_duration':
        return { ...acc, animation_duration: message }
      case 'attributes.transition':
        return { ...acc, transition: message }
      case 'attributes.transition_duration':
        return { ...acc, transition_duration: message }
      case 'attributes.theme_settings.font_family':
        return { ...acc, font_family: message }
      case 'attributes.theme_settings.text_color':
        return { ...acc, text_color: message }
      case 'attributes.device_ids':
        return { ...acc, device_ids: message }
      case 'attributes.emails':
        return { ...acc, emails: message }
      case 'attributes.media_file_url':
        propName = 'media_file_url'
        if (options.type === 'local') {
          propName = 'localFileUrl'
        } else if (options.type === 'web') {
          propName = 'webFileUrl'
        }
        return { ...acc, [propName]: message }
      case 'attributes.category':
        propName = options.provider ? options.provider : 'category'
        return { ...acc, [propName]: message }
      default:
        if (options.doNotRemoveAttributes) {
          return { ...acc, [name]: message }
        } else {
          return { ...acc, [name.split('attributes.')[1] || name]: message }
        }
    }
  }, {})
}

export const parseMediaInfoFromBE = ({
  title,
  group,
  tag,
  activateOn,
  expireOn,
  isRetain,
  mediaPriority,
  mode,
  duration
}) => {
  return {
    title: isClone(mode) ? localStorage.getItem(CLONE_MEDIA_TITLE) : title,
    group: selectUtils.convertArr(group, selectUtils.toGroupChipObj),
    tags: selectUtils.convertArr(tag, selectUtils.tagToChipObj),
    activeDate: activateOn ? moment(activateOn).format() : null,
    expireDate: expireOn ? moment(expireOn).format() : null,
    priority: mediaPriority,
    duration: isNumber(duration) ? secToLabel(duration) : duration,
    isRetain
  }
}

export async function validatePreview(
  validateForm,
  setTouched,
  isDeep = false,
  customErrors,
  excludeCustomErrorsKeys
) {
  const errors = await validateForm()
  const parsedErrors = _filter(_keys(errors), key => !isMediaInfo(key))

  if (setTouched) {
    if (customErrors) {
      const data = {
        ...errorsToTouched(errors, excludeCustomErrorsKeys),
        ...customErrors
      }
      setTouched(data)
      return false
    } else if (length(parsedErrors)) {
      setTouched(
        isDeep
          ? errorsToTouched(errors, ['mediaInfo'])
          : namesToTouched(parsedErrors)
      )
      return false
    }
  } else {
    return !length(parsedErrors)
  }

  return true
}

export const prepareForSetTouched = err => {
  const flattened = {}

  const flattenNested = (obj, prefix = '') => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
        flattenNested(obj[key], prefix + key + '.')
      } else {
        flattened[prefix + key] = obj[key]
      }
    }
  }

  flattenNested(err)
  return flattened
}

export async function validateSubmit(
  validateForm,
  setTouched,
  customErrors,
  excludeKeys,
  isDeep = true
) {
  const errors = await validateForm()
  const parsedErrors = _filter(_keys(errors), key => key)

  if (length(parsedErrors)) {
    setTouched(
      customErrors
        ? { ...errorsToTouched(errors, excludeKeys), ...customErrors }
        : isDeep
        ? errorsToTouched(errors)
        : namesToTouched(parsedErrors)
    )
    return false
  }

  return true
}

export function createMediaPostPayload(mediaName, tabName, data) {
  return { mediaName, tabName, data }
}

export function getIconInfo(media, type) {
  switch (type.toLowerCase()) {
    case publishTabType.media:
      return {
        color: _get(media, 'feature.color'),
        iconHelperClass: _get(media, 'feature.icon'),
        title: _get(media, 'feature.alias', 'Media')
      }
    case publishTabType.playlist:
      return playlistConstants.playlistTypes[
        _get(media, 'playlistType', 'Standard')
      ]
    case publishTabType.template:
      return templateConstants.templateTypes[
        _get(media, 'templateType', 'Standard')
      ]
    default:
      return {
        title: 'Unknown'
      }
  }
}

export const initialMappingData = {
  header: [],
  rows: []
}

export const getCsvData = data => {
  if (!data) {
    return
  }
  const [header, ...rows] = csvConverter.parse(data)
  return {
    header: header || [],
    rows: rows || []
  }
}

export const getXmlData = data => {
  if (!data) {
    return
  }
  const { _declaration, ...rest } = xmlConverter.xml2js(data, { compact: true })
  const items = Object.values(Object.values(rest)[0])[0]
  return {
    header: Object.keys(items[0]),
    rows: items.map(item => Object.values(item).map(({ _text }) => _text))
  }
}

export const getJsonData = data => {
  if (!data) {
    return
  }
  const parsedData = JSON.parse(data)
  const items = Array.isArray(parsedData)
    ? parsedData
    : Object.values(parsedData)[0]

  return {
    header: Object.keys(items[0]),
    rows: items.map(item => Object.values(item))
  }
}

export const getPlainTextData = data => {
  const parsedData = parseTxtForMedia(data)
  return {
    header: Object.keys(parsedData[0]),
    rows: parsedData.map(row => Object.values(row))
  }
}

export const simplePreloadedDataParser = data => {
  const dataArray = Array.isArray(data)
    ? data
    : _get(data, 'data', _get(data, 'entry', []))

  return Object.fromEntries(
    Object.entries(dataArray[0] || {}).map(([key, val]) => [
      key,
      Array.isArray(val) && typeof val === 'object' ? '' : val
    ])
  )
}

export const preloadedDataParser = data => {
  const dataArray = Array.isArray(data)
    ? data
    : _get(data, 'data', _get(data, 'entry', []))

  return {
    header: Object.keys(dataArray[0]),
    rows: dataArray.map(row => Object.values(row))
  }
}

export const getCardMappedProperty = (
  target,
  label,
  csvRowValues = [],
  selected_mapping_value = []
) => {
  const index = selected_mapping_value.findIndex(
    ({ select_value }) => select_value === target
  )

  if (index !== -1) {
    let value = _get(
      csvRowValues,
      `[${index}]`,
      selected_mapping_value[index].default_value
    )

    if (value === '0') value = 0
    if (value === 'false') value = false
    if (value === 'null') value = null
    if (value === 'undefined') value = undefined

    const isDateField = label.toLowerCase().includes('date')
    if (isDateField) {
      value = moment(value).format(DATE_VIEW_FORMAT)
    }

    return { label, value }
  } else {
    return { label, value: '' }
  }
}

export const getThemeTabOptions = (themeSettings = {}, endsWith = '') => {
  return Object.keys(themeSettings)
    .filter(key => key.endsWith(endsWith))
    .map(field =>
      createTab(
        field
          .replace(endsWith, '')
          .split('_')
          .map(name => `${name[0].toUpperCase()}${name.substring(1)}`)
          .join(' '),
        field
      )
    )
}

const getMediaDurationByFeature = media => {
  switch (_get(media, 'feature.name')) {
    case TIMER:
      return media.durationTotal
        ? media.durationTotal
        : _get(media, 'attributes.duration')
        ? labelToSec(media.attributes.duration) + TIMER_COMPLETE_TEXT_DURATION
        : media.durationTotal
    default:
      return media?.durationTotal
  }
}

export const getMediaDuration = (media, asLabel = false, fallBack) => {
  const duration = getMediaDurationByFeature(media) || fallBack

  if (!duration && duration !== 0) {
    return
  }

  if (asLabel) {
    return _isNumber(duration) ? secToLabel(duration) : duration
  } else {
    return _isNumber(duration) ? duration : labelToSec(duration)
  }
}

export const getDuration = (data, asLabel = false, fallBack) => {
  const duration = data.durationTotal || fallBack

  if (!duration && duration !== 0) {
    return
  }
  if (asLabel) {
    return _isNumber(duration) ? secToLabel(duration) : duration
  } else {
    return _isNumber(duration) ? duration : labelToSec(duration)
  }
}

export const getMediaDurationDisplayValue = (media, asLabel, fallBack) => {
  const { processing, failed, unavailable } = mediaConstants.mediaStatus
  const processingStatus = _get(media, 'processingStatus')
  const previewAbility = _get(media, 'previewAbility')

  if (processingStatus === processing) return 'Processing'
  if (previewAbility === unavailable) return 'Unavailable'
  if (processingStatus === failed) return 'Failed'

  return getMediaDuration(media, asLabel, fallBack)
}

export const getPlaylistMediaDuration = item => {
  const duration = getMediaDuration(item, true, MEDIA_FALLBACK_TIME)
  return duration === MEDIA_MIN_TIME ? MEDIA_FALLBACK_TIME : duration
}

export const isMediaDeauthorized = media =>
  media.hasOwnProperty('isFeatureAuthenticationEnabled') &&
  media.hasOwnProperty('isFeatureAuthenticated') &&
  media.isFeatureAuthenticationEnabled &&
  !media.isFeatureAuthenticated

export const getMediaStatus = ({
  status,
  expireOn,
  activateOn,
  feature,
  processingStatus,
  previewAbility,
  ...rest
}) => {
  const {
    active,
    inactive,
    disabled,
    expired,
    expires,
    pending,
    notApproved,
    processing,
    failed,
    unavailable,
    rejected,
    deauthorized
  } = mediaConstants.mediaStatus

  if (processingStatus === processing) return processing
  if (processingStatus === failed) return failed
  if (previewAbility === unavailable) return unavailable

  if (feature?.status === inactive) return disabled

  if (expireOn && moment(expireOn).isBefore(moment())) {
    return expired
  }

  if (!isApproved(rest)) {
    if (rest.approvedStatus === approveStatuses.disapprove) {
      return rejected
    }
    return notApproved
  }

  if (moment().isBefore(moment(activateOn))) return pending
  if (isMediaDeauthorized(rest)) return deauthorized

  if (expireOn) {
    return expires
  }

  if (status === active) return active
  if (status === inactive) return inactive
  if (status === disabled) return disabled
}

export const arrayToOptions = (array = []) => {
  return array.map(value => ({ label: value, value }))
}

export const objectToOptions = (optionsObject = {}, t) => {
  return Object.entries(optionsObject).map(([value, label]) => ({
    value,
    label: t ? t(label) : label
  }))
}

const getMediaFullUrl = (
  rootUrl,
  configMediaCategory,
  featureId,
  featureName,
  mediaId
) => {
  let category = ''
  let feature = ''
  const isFileType =
    mediaFileSubTypes.includes(featureName) || featureName === FILE

  if (isFileType) {
    category = 'general'
    feature = 'file'
  } else if (featureName === 'CapAlert') {
    /* This clause treats CAPAlert media as AlertSystem media
    Reason: CAPAlert is a subset of AlertSystem (as a tab option)
            and does not have mediaCategory feature definition  */
    category = 'premium'
    feature = 'alertsystem'
  } else if (featureName === DESIGN_GALLERY) {
    return rootUrl
  } else {
    category = getFeatureNameById(configMediaCategory, featureId)
    feature = getMediaUrlName(featureName)
  }
  return `${rootUrl}/${category.replace(' ', '_')}/${feature}${
    mediaId ? `/${mediaId}` : ''
  }`
}

export const getEditMediaLink = (media, configMediaCategory, rootUrl) => {
  if (!media.id) {
    return ''
  }
  const { id: mediaId, feature, designGalleryId } = media
  const { id: featureId, name } = feature
  const baseUrl =
    name === DESIGN_GALLERY
      ? `${rootUrl}/design-gallery/${designGalleryId}`
      : `${rootUrl}/media/edit`

  return getMediaFullUrl(baseUrl, configMediaCategory, featureId, name, mediaId)
}

export const getAddMediaLink = (configMediaCategory, featureId, rootUrl) => {
  const baseUrl = `${rootUrl}/media/add`
  let featureName = FILE

  _get(configMediaCategory, 'response', [])
    .filter(({ name }) => name !== 'Favorite')
    .some(({ feature }) =>
      feature.some(({ name, id }) => {
        if (id === featureId) {
          featureName = name
          return true
        } else {
          return false
        }
      })
    )

  return getMediaFullUrl(baseUrl, configMediaCategory, featureId, featureName)
}

export const getSortedMediaCategories = configMediaCategoryResponse => {
  if (!configMediaCategoryResponse) {
    return []
  }

  const tabs = sortBySortOrder(configMediaCategoryResponse).map(({ name }) =>
    getMediaCategoryUrlName(name)
  )

  return tabs.includes('favorite')
    ? [...tabs.filter(tab => tab !== 'favorite'), 'favorite']
    : tabs
}

export const createDataTransformer = (
  allowedDataTypes,
  ignoreMapping,
  preloadedDataParser,
  ...parsers
) => {
  const parsersByMime = allowedDataTypes
    .filter(mime => !ignoreMapping.includes(mime))
    .reduce(
      (acc, next, index) => ({
        ...acc,
        [next]: _get(parsers, `[${index}]`, f => f)
      }),
      {}
    )
  const parserKeys = Object.keys(parsersByMime)
  return ({ data, type, preloaded }) => {
    const key = parserKeys.find(key => type.includes(key))
    if (preloaded) {
      return preloadedDataParser(data)
    } else if (key) {
      return parsersByMime[key](data)
    } else {
      throw new Error('Unable to parse data')
    }
  }
}

export const isMappedAndValid = (type, isFormValid, isMappingValid) =>
  ['file', 'web'].includes(type) ? isFormValid && isMappingValid : isFormValid

const sortByDateAtField = ({ asc = true, field = 'createdAt' }) => (a, b) => {
  if (a[field] === b[field]) {
    return asc ? (a.id > b.id ? 1 : -1) : a.id < b.id ? 1 : -1
  }
  return asc ? (a[field] > b[field] ? 1 : -1) : a[field] < b[field] ? 1 : -1
}

const sortByField = ({ asc = true, field }) => (a, b) => {
  if (a[field].localeCompare(b[field]) === 0) {
    return asc ? (a.id > b.id ? 1 : -1) : a.id < b.id ? 1 : -1
  }
  return asc
    ? a[field].localeCompare(b[field])
    : b[field].localeCompare(a[field])
}

export const sortMediaByType = (
  mediaList,
  type,
  field = 'title',
  sortDateField = 'createdAt'
) => {
  const list = [...mediaList]
  switch (type) {
    case mediaSortingTypes.ALPHABETICAL:
      return list.sort(sortByField({ field }))
    case mediaSortingTypes.ALPHABETICAL_REVERSE:
      return list.sort(sortByField({ asc: false, field }))
    case mediaSortingTypes.NEWEST_TO_OLDEST:
      return list.sort(sortByDateAtField({ asc: false, field: sortDateField }))
    case mediaSortingTypes.OLDEST_TO_NEWEST:
      return list.sort(sortByDateAtField({ field: sortDateField }))
    default:
      return mediaList
  }
}

export const isMediaFeatureAvailable = (
  featureName,
  userDetailsResponse,
  roleInfo
) => {
  const features = userDetailsResponse?.client?.feature || []

  const isAvailable = roleInfo.system
    ? !excludeAlert.includes(featureName)
    : features.some(({ name }) => name === featureName)

  return isAvailable
}

const getLabel = (name, t) => {
  const patterns = [
    {
      regExp: /Arrow [0-9]+ Background Color/,
      getTranslation: name =>
        t('Arrow n Background', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Arrow Background [0-9]+ Color/,
      getTranslation: name =>
        t('Arrow Background n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Background Gradient [0-9]+ Color/,
      getTranslation: name =>
        t('Background Gradient n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Text Highlight [0-9]+ Color/,
      getTranslation: name =>
        t('Text Highlight n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Highlight [0-9]+ Color/,
      getTranslation: name =>
        t('Highlight n', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Restriction Background [0-9]+ Color/,
      getTranslation: name =>
        t('Restriction BG n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Inner [0-9]+ Background Color/,
      getTranslation: name =>
        t('Inner n Background', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Card [0-9]+ Background Color/,
      getTranslation: name =>
        t('Card n Background', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Card Background [0-9]+/,
      getTranslation: name =>
        t('Card Background n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Card [0-9]+ Title Background Color/,
      getTranslation: name =>
        t('Card n Title BG', {
          count: Number(name.split(' ')[1])
        })
    },
    {
      regExp: /Date [0-9]+ Background Color/,
      getTranslation: name =>
        t('Date n Background', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Header Background [0-9]+ Color/,
      getTranslation: name =>
        t('Header Background n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Row [0-9]+ Background Color/,
      getTranslation: name =>
        t('Row n Background', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Outer Gradient [0-9]+ Color/,
      getTranslation: name =>
        t('Outer Gradient n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Inner Gradient [0-9]+ Color/,
      getTranslation: name =>
        t('Inner Gradient n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Outer [0-9]+ Background Color/,
      getTranslation: name =>
        t('Outer n Background', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Title [0-9]+ Background Color/,
      getTranslation: name =>
        t('Title n Background', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Progress Background [0-9]+/,
      getTranslation: name =>
        t('Progress Background n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Row Background [0-9]+/,
      getTranslation: name =>
        t('Row Background n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Header Background [0-9]+/,
      getTranslation: name =>
        t('Header Background n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Outer Background [0-9]+ Color/,
      getTranslation: name =>
        t('Outer Background n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Progress [0-9]+ Background Color/,
      getTranslation: name =>
        t('Progress n Background', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Progress Gradient [0-9]+/,
      getTranslation: name =>
        t('Progress Gradient n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Scale Strip [0-9]+/,
      getTranslation: name =>
        t('Scale Strip n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Filled Arc Gradient [0-9]+ Color/,
      getTranslation: name =>
        t('Filled Arc Gradient n', { count: Number(name.split(' ')[3]) })
    },
    {
      regExp: /Left Background [0-9]+ Color/,
      getTranslation: name =>
        t('Left Background n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Header Background [0-9]+/,
      getTranslation: name =>
        t('Header Background n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Description Background [0-9]+ Color/,
      getTranslation: name =>
        t('Description BG n', {
          count: Number(name.split(' ')[2])
        })
    },
    {
      regExp: /Card Background [0-9]+ Color/,
      getTranslation: name =>
        t('Card BG n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Message Background [0-9]+ Color/,
      getTranslation: name =>
        t('Message BG n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Popup Background [0-9]+ Color/,
      getTranslation: name =>
        t('Popup BG n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Title [0-9]+ Color/,
      getTranslation: name =>
        t('Title n', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Card [0-9]+ Title/,
      getTranslation: name =>
        t('Card n Title', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Card [0-9]+ Content/,
      getTranslation: name =>
        t('Card n Content', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Card [0-9]+ Reviewer Name/,
      getTranslation: name =>
        t('Card n Reviewer Name', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Card [0-9]+ Reviewer Title/,
      getTranslation: name =>
        t('Card n Reviewer Title', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Card [0-9]+ Location/,
      getTranslation: name =>
        t('Card n Location', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Left Background [0-9]+ Color/,
      getTranslation: name =>
        t('Left Background n', { count: Number(name.split(' ')[2]) })
    },
    {
      regExp: /Card [0-9]+ Text/,
      getTranslation: name =>
        t('Card n Text', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Card [0-9]+ Top Background/,
      getTranslation: name =>
        t('Card n Top BG', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Card [0-9]+ Bottom Background/,
      getTranslation: name =>
        t('Card n Bottom BG', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Card [0-9]+ Image Frame/,
      getTranslation: name =>
        t('Card n Image Frame', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Card [0-9]+ Top Title/,
      getTranslation: name =>
        t('Card n Top Title', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Card [0-9]+ Bottom Title/,
      getTranslation: name =>
        t('Card n Bottom Title', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Card [0-9]+ Top Text/,
      getTranslation: name =>
        t('Card n Top Text', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Card [0-9]+ Bottom Text/,
      getTranslation: name =>
        t('Card n Bottom Text', { count: Number(name.split(' ')[1]) })
    },
    {
      regExp: /Color$/,
      getTranslation: name => {
        let _name = name.replace(' Color', '')
        return _name.length > 20
          ? t(_name.replace('Background', 'BG'))
          : t(_name)
      }
    },
    {
      regExp: /Family$/,
      getTranslation: name => {
        return t(name.replace(' Family', ''))
      }
    },
    {
      regExp: /Size$/,
      getTranslation: name => {
        return t(name.replace(' Size', ''))
      }
    }
  ]
  const pattern = patterns.find(({ regExp }) => name.match(regExp))
  return pattern ? pattern.getTranslation(name) : t(name)
}

export const labelByPath = (nameWithPath, t) => {
  const nameArray = nameWithPath.split('.')
  const name = nameArray[nameArray.length - 1]
  return getLabel(snakeCaseToSplitCapitalize(name) || '', t)
}

export const getMediaThemeColors = (
  themes,
  themeId,
  themePath = '',
  withBackgroundColors = false
) => {
  let theme
  Object.values(themes).find(data => {
    if (Array.isArray(data)) {
      return (
        data?.length &&
        data.find(_theme => {
          if (_theme.id === themeId) {
            theme = _theme
            return true
          }
          return false
        })
      )
    } else if (!isEmpty(data)) {
      let _theme = data
      if (_theme.id === themeId) {
        theme = _theme
        return true
      }
      return false
    }
    return false
  })

  if (!theme) return []
  const defaultValues = _get(
    theme,
    `customProperties.other.default_values${themePath ? `.${themePath}` : ''}`,
    {}
  )
  const colors = []
  Object.entries(defaultValues).forEach(([key, value]) => {
    if (key.endsWith('_color') && !!value) {
      colors.push(value)
    } else if (
      withBackgroundColors &&
      key.endsWith('_background') &&
      value.hasOwnProperty('color') &&
      !!value.color
    ) {
      colors.push(value.color)
    }
  })
  return _uniq(colors)
}

export const getAutoTags = media => {
  const { tag } = media

  return (tag || []).filter(({ isAutoTag }) => isAutoTag)
}

export const getAvailableTagLabels = media => {
  const { labels, feature } = media || {}

  if (
    ![featureNames.Image, featureNames.DesignGallery].includes(feature?.name) ||
    !labels
  ) {
    return []
  }

  const autoTags = getAutoTags(media)

  if (!autoTags.length) {
    return labels
  }

  return labels.filter(
    ({ label }) => !autoTags.some(({ title }) => title === label)
  )
}

export const getLastCopyToSystemInfo = (media = {}) => {
  const info = {
    copiedAt: null,
    copiedBy: {}
  }

  const { copiedTo } = media

  if (!copiedTo) {
    return info
  }

  const copiedToSystem = copiedTo.filter(({ clientId }) => !clientId)

  if (!copiedToSystem.length) {
    return info
  }

  const latestData = copiedToSystem
    .sort((a, b) => {
      return moment(a.copiedAt).isAfter(moment(b.copiedAt)) ? 1 : -1
    })
    .pop()

  return {
    copiedAt: latestData.copiedAt,
    copiedBy: latestData.copiedBy || {}
  }
}

export const checkFeature = (media, featureName) =>
  media?.feature?.name === featureName

export const getThumbnail = ({ feature, thumbnail, thumbUrl, mediaUrl }) =>
  thumbnail ||
  thumbUrl ||
  ([featureNames.Image, featureNames.Canva].includes(feature?.name)
    ? mediaUrl
    : undefined)

export const getDurationString = media =>
  secondsToDurationString(media.durationTotal)

export const getSize = media => media.size && +parseFloat(media.size).toFixed(2)

export const getResolution = media => {
  if (media.resolution && media.resolution !== 'x') {
    return media.resolution
  }
}

export const isPublishedDesign = media =>
  checkFeature(media, featureNames.DesignGallery) &&
  media?.designGallery?.type === 'Stock'

export const prepareSampleData = ({ sampleData = [], initialData = {} }) => {
  if (!!sampleData.length) {
    return sampleData.map((row, index) => ({
      ...row,
      id: index + 1
    }))
  } else {
    return [initialData]
  }
}

export const prepareBrandColorThemeProperties = ({
  properties = {},
  isEnabledBrandGuide,
  brandGuideColors,
  isMandatory,
  mode
}) => {
  if (isEnabledBrandGuide && isMandatory && isAdd(mode)) {
    return Object.assign(
      {},
      ...Object.entries(properties)
        .filter(
          ([key, value]) =>
            key.endsWith('_color') && removeSpacing(value) !== whiteColor
        )
        .map(([k]) => ({ [k]: brandGuideColors[0] }))
    )
  }

  return null
}

export const isFixedDuration = media => {
  const { fixedDuration, feature, playbackContentModel } = media

  if (
    feature?.name === featureNames.YouTube &&
    media.hasOwnProperty('fixedDuration')
  ) {
    return fixedDuration
  } else if (
    playbackContentModel &&
    playbackContentModel.hasOwnProperty('fixedDuration') &&
    _get(playbackContentModel, 'feature.name') === featureNames.YouTube
  ) {
    return playbackContentModel.fixedDuration
  } else if (feature && feature.hasOwnProperty('isFixedDuration')) {
    return feature.isFixedDuration
  } else if (!feature && playbackContentModel) {
    if (playbackContentModel?.feature?.hasOwnProperty('isFixedDuration')) {
      return playbackContentModel.feature.isFixedDuration
    } else if (playbackContentModel.hasOwnProperty('fixedDuration')) {
      return playbackContentModel.fixedDuration
    }
  }

  return fixedDuration
}

export const getTypeIconData = ({ type }) => {
  switch (type) {
    case 'pdf':
      return {
        color: 'rgba(255, 87, 34, 1)',
        icon: 'fa-regular fa-file-pdf'
      }
    case 'image':
      return {
        color: 'rgba(136, 14, 79, 1)',
        icon: 'icon-folder-image'
      }
    case 'video':
      return {
        color: 'rgba(183, 28, 28, 1)',
        icon: 'fa-regular fa-clapperboard-play'
      }
    case 'folder':
      return {
        icon: 'fa-regular fa-folder-open',
        color: colors.light
      }
    default:
      return {}
  }
}

export const emailToScreenFeatureDisabled = ({ row }) => {
  const isEmailContentMedia = checkFeature(row, featureNames.EmailContent)

  return (
    [ENTERPRISE_ROLE, SYSTEM_ROLE].includes(getUserRoleLevel()) &&
    isEmailContentMedia
  )
}

export const convertDesignGalleryCategories = ({ categories = [] }) => {
  if (!categories?.length) return []

  return categories.map(({ id, name }) => ({
    id,
    title: name
  }))
}

const primaryColor = '#d8372e'
const docColor = '#4584f1'
const spreadsheetColor = '#159c5a'
const imageColor = '#880E4F'

export const getMimeTypeIconData = (mime, featuresByType = {}) => {
  if ([WEBM.mime, MPEG.mime].includes(mime)) {
    return { icon: 'fa-solid fa-clapperboard', color: primaryColor }
  } else if ([JPEG.mime, PNG.mime, GIF.mime].includes(mime)) {
    return { icon: 'icon-folder-image', color: imageColor }
  } else if ([MPGA.mime, MP3.mime].includes(mime)) {
    return {
      icon: 'fa-regular fa-headphones',
      color: primaryColor
    }
  } else if ([XLS.mime, XLSX.mime, googleMimes.spreadsheet].includes(mime)) {
    return { icon: 'fa-regular fa-file-spreadsheet', color: spreadsheetColor }
  } else if ([DOC.mime, DOCX.mime, googleMimes.document].includes(mime)) {
    return { icon: 'fa-regular fa-file-lines', color: docColor }
  } else if ([PPT.mime, PPTX.mime, googleMimes.presentation].includes(mime)) {
    return {
      icon: 'fa-regular fa-file-powerpoint',
      color: primaryColor
    }
  } else if (folderMimeType === mime) {
    return {
      icon: 'fa-regular fa-folder-open',
      color: colors.light
    }
  } else {
    return { icon: 'fa fa-file-o' }
  }
}

export const getFeatureIconByMime = (mime, featuresByType = {}) => {
  if (typesToMimeTypes(audioTypes).includes(mime)) {
    return featuresByType.audio
  } else if (typesToMimeTypes(videoTypes).includes(mime)) {
    return featuresByType.video
  } else if (typesToMimeTypes(imageTypes).includes(mime)) {
    return featuresByType.image
  } else if (typesToMimeTypes(pdfTypes).includes(mime)) {
    return featuresByType.pdf
  } else if (Object.values(googleMimes).includes(mime)) {
    return featuresByType.googleDocs
  }
}
