import { featureNames } from './featureConstants'
import { SYSTEM_ROLE, ORG_ROLE, ENTERPRISE_ROLE, RESELLER_ROLE } from './api'

export const disabledPreviewMediaFeatures = [featureNames.VideoInput]

export const youtubeLinkTypeParts = {
  video: '/watch?v=',
  playlist: 'list=',
  channel: '/user/',
  shorts: '/shorts/',
  custom_channel_new_format: '/@',
  custom_channel: '/c/',
  live_channel: '/channel/',
  base: 'https://www.youtube.com'
}

export const canvaButton = {
  designMin: 200,
  designMax: 8000
}

export const TWITCH_BASE_URL = 'https://www.twitch.tv/'

export const twitchLinkTypeParts = {
  clip: '/clip/',
  video: '/videos/',
  collection: 'collection='
}

export const mediaStatus = {
  active: 'Active',
  inactive: 'Inactive',
  pending: 'Pending',
  notApproved: 'Not Approved',
  rejected: 'Rejected',
  expired: 'Expired',
  expires: 'Expires',
  disabled: 'Disabled',
  deauthorized: 'Deauthorized',
  // previewAbility values:
  available: 'AVAILABLE',
  unavailable: 'UNAVAILABLE',
  preliminary: 'PRELIMINARY',
  // processingStatus values:
  successful: 'SUCCESSFUL',
  processing: 'PROCESSING',
  failed: 'FAILED'
}

export const MVIX_DIGITAL_SIGNAGE = 'Mvix Digital Signage'

export const MEDIA_MIN_TIME = '00:00:00'
export const MEDIA_MAX_TIME = '23:59:59'
export const MEDIA_FALLBACK_TIME = '00:00:10'
export const PLAYLIST_FALLBACK_TRANSITION_ID = 25
export const SCHEDULE_FALLBACK_TRANSITION_ID = 25
export const MEDIA_DEFAULT_TRANSITION = 'simpleFade'
export const TIMER_COMPLETE_TEXT_DURATION = 10
export const MEDIA_MIN_DURATION = '00:00:00'

export const KIOSK_CATEGORY_ID = 8

//TODO move to feature constants
export const CURRENCY_CONVERTER = 'CurrencyConverter'
export const DOCKETS = 'Dockets'
export const PINTEREST = 'Pinterest'
export const EVENTS_LISTING = 'Events'
export const TIMER = 'Timer'
export const MS_OFFICE = 'MSOffice'
export const DESIGN_GALLERY = 'DesignGallery'
export const FILE = 'File'
export const NEWS = 'News'

export const excludeAlert = [
  featureNames.AlertSystem,
  featureNames.CapAlert,
  featureNames.IPAWS
]

export const mediaInfoInitvalue = {
  title: '',
  group: [],
  tags: [],
  activeDate: '',
  expireDate: '',
  duration: MEDIA_FALLBACK_TIME,
  priority: false,
  isRetain: true
}

export const featuresToExclude = [
  featureNames.AlertSystem,
  featureNames.CapAlert,
  featureNames.IPAWS,
  featureNames.EmailContent,
  featureNames.Wayfinding
]

export const hiddenFeatures = [featureNames.Flash, featureNames.Videowall]

export const featuresToExcludeByRole = {
  [ORG_ROLE]: [],
  [RESELLER_ROLE]: [featureNames.ShorelineSightseeing],
  [ENTERPRISE_ROLE]: [
    featureNames.ShorelineSightseeing,
    featureNames.EmailContent,
    featureNames.SpaceBooking
  ],
  [SYSTEM_ROLE]: [
    ...excludeAlert,
    featureNames.ShorelineSightseeing,
    featureNames.EmailContent,
    featureNames.SpaceBooking
  ]
}

export const supportedZoomToFitFeatures = [
  'Image',
  'Video',
  'Canva',
  'Smugmug',
  'StockPhotos',
  'WorkplacePosters'
]

export const mediaType = {
  inline: 'inline_editor',
  web: 'web',
  file: 'file'
}

export const themeTypes = {
  MODERN: 'Modern',
  LEGACY: 'Legacy'
}

export const touchSettingsLocations = {
  POPUP_WINDOW: 'popup_window',
  POPUP_BUBBLE: 'popup_bubble',
  EXISTING_ZONE: 'existing_zone',
  FULL_SCREEN: 'full_screen'
}

export const touchSettingsBubbleLocations = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
}

export const mediaSortingTypes = {
  RANDOM: 'random',
  NEWEST_TO_OLDEST: 'nto',
  OLDEST_TO_NEWEST: 'otn',
  ALPHABETICAL: 'atz',
  ALPHABETICAL_REVERSE: 'zta'
}

export const CAP_ALERT_URL_BACKEND_ERROR_MESSAGE =
  'The selected attributes.cap alert url is invalid.'

export const CAP_ALERT_URL_ERROR_MESSAGE =
  'There was an error loading the website address – check the link and try again'

export const inputTypes = {
  text: 'text',
  font: 'font',
  textSize: 'textSize',
  textMultiline: 'textMultiline',
  select: 'select',
  selectMultiple: 'selectMultiple',
  autocompleteMultiple: 'autocompleteMultiple',
  switch: 'switch',
  color: 'color',
  background: 'background',
  backgroundImage: 'backgroundImage',
  dateTimeSelectMultiple: 'dateTimeSelectMultiple'
}

export const sectionTypes = {
  branding: 'branding',
  advanceSettings: 'advanceSettings'
}

export const backgroundTypes = {
  image: 'image',
  pattern: 'pattern',
  none: 'none',
  default: 'default',
  defaultImage: 'default_image',
  video: 'video',
  color: 'color'
}

export const unprocessableMediaStatuses = [
  mediaStatus.expired,
  mediaStatus.notApproved,
  mediaStatus.disabled,
  mediaStatus.processing,
  mediaStatus.failed,
  mediaStatus.unavailable,
  mediaStatus.rejected,
  mediaStatus.deauthorized
]

export const expiresMediaStatuses = [mediaStatus.expires, mediaStatus.expired]

export const MAX_REFRESH_EVERY = 3600
export const MIN_REFRESH_EVERY = 300
export const DEFAULT_REFRESH_EVERY = 900

export const deauthorizationTranslations = t => ({
  modalTitle: t('Confirm Deauthorization'),
  alertNotification: t(
    'Deauthorizing this account by logging out will also deauthorize all associated Media within Playlists, Templates and Schedules. Device Playback will be adjusted accordingly. Proceed?'
  ),
  modalConfirmBtn: t('Confirm')
})

export const mediaPreviewNotAvailableException =
  'MediaPreviewNotAvailableErrorException'

export const mediaNotificationExceptions = [mediaPreviewNotAvailableException]
