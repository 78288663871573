import React, { memo } from 'react'
import { Typography, Grid, withStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'

const styles = ({ type, colors }) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 'auto',
    color: type === 'dark' ? '#5c697f' : '#242424',
    overflow: 'hidden',
    lineHeight: 1,
    padding: 10,
    '&:hover': {
      color: type === 'dark' ? '#fff' : ''
    },
    backgroundColor: colors.background.designGallery[type],
    width: '100%'
  },
  title: {
    display: 'inline-flex',
    maxWidth: '100%',
    fontSize: '25px',
    lineHeight: '1',
    overflow: 'hidden',
    marginBottom: '4px'
  },
  text: {
    display: 'inline-flex',
    maxWidth: '100%',
    fontSize: '14px',
    lineHeight: '19px',
    overflow: 'hidden'
  }
})

const FontCombinationPreview = ({
  main,
  secondary,
  secondaryText,
  classes
}) => {
  return (
    <Grid container direction="row">
      <Typography component="div" className={classes.wrapper}>
        <Box
          fontWeight={500}
          className={classes.title}
          style={{ fontFamily: main }}
        >
          {main + ' & ' + secondary}
        </Box>
        <Box
          fontWeight={400}
          className={classes.text}
          style={{ fontFamily: secondary }}
        >
          {secondaryText}
        </Box>
      </Typography>
    </Grid>
  )
}

export default withStyles(styles)(memo(FontCombinationPreview))
