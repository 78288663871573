import React from 'react'
import { InputBase, Grid, withStyles } from '@material-ui/core'
import classNames from 'classnames'

import { Search as SearchIcon } from '@material-ui/icons'

const styles = ({ palette, type }) => ({
  formGroup: {
    position: 'relative'
  },
  searchControl: {
    width: '100%'
  },
  iconWrapper: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 16,
    width: 12,
    height: 12,
    opacity: 0.6
  },
  icon: {
    width: '100%',
    height: '100%',
    color: '#808b9c'
  },
  formControl: {
    width: '100%',
    height: 32,
    borderRadius: 4,
    backgroundColor: palette[type].formControls.input.background,
    border: `1px solid ${palette[type].formControls.input.border}`,
    color: palette[type].formControls.input.color,
    padding: '0 40px 0 16px',
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '-0.02px',

    '&::-webkit-input-placeholder': {
      color: '#9394a1'
    },

    '&::-moz-placeholder': {
      color: '#9394a1',
      opacity: 1
    }
  },
  inputBase: {
    width: '100%'
  }
})

const Search = ({
  placeholder = 'Search…',
  onChange,
  value = '',
  classes,
  inputClass
}) => {
  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.formGroup}
      >
        <Grid item xs={12} className={classes.searchControl}>
          <InputBase
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            classes={{
              root: classes.inputBase,
              input: classNames(classes.formControl, inputClass)
            }}
          />
        </Grid>
        <Grid item className={classes.iconWrapper}>
          <SearchIcon className={classes.icon} />
        </Grid>
      </Grid>
    </div>
  )
}
export default withStyles(styles)(Search)
