import * as types from './index'

const postSchedule = data => ({
  type: types.POST_SCHEDULE,
  data
})

const editSchedule = ({ id, data, name }) => ({
  type: types.PUT_SCHEDULE,
  data,
  meta: {
    id,
    name
  }
})

const getSchedule = id => ({
  type: types.GET_SCHEDULE_BY_ID,
  data: id
})

const getScheduleItemsAction = (
  params,
  storeFetchParams = true,
  isLibrary = true
) => ({
  type: types.GET_SCHEDULE_ITEMS,
  params,
  storeFetchParams,
  isLibrary
})

const clearScheduleItemsAction = () => ({
  type: types.CLEAR_SCHEDULE_ITEMS
})

const getScheduleListsAction = params => ({
  type: types.GET_SCHEDULE_LISTS,
  params
})

const getScheduleTimelineAction = params => ({
  type: types.GET_SCHEDULE_TIMELINE,
  params
})

const clearScheduleTimeline = () => ({
  type: types.CLEAR_SCHEDULE_TIMELINE
})

const getCalendarSchedules = params => ({
  type: types.GET_CALENDAR_SCHEDULES,
  payload: params
})

const clearCalendarSchedules = () => ({
  type: types.CLEAR_CALENDAR_SCHEDULES
})

const getScheduleLibraryPrefAction = () => ({
  type: types.GET_SCHEDULE_PREFERENCE
})

const putScheduleLibraryPrefAction = data => ({
  type: types.PUT_SCHEDULE_PREFERENCE,
  payload: data
})

const getScheduleGroupItemsAction = (id, params) => ({
  type: types.GET_SCHEDULE_GROUP_ITEMS,
  payload: { id, params }
})

const clearGetScheduleGroupItemsInfoAction = () => ({
  type: types.CLEAR_GET_SCHEDULE_GROUP_ITEMS_INFO
})

const clearScheduleGroupItemsInfo = () => ({
  type: types.CLEAR_SCHEDULE_GROUP_ITEMS_RESPONSE_INFO
})

const postScheduleGroupItemAction = data => ({
  type: types.POST_SCHEDULE_GROUP_ITEM,
  payload: data
})

const clearPostScheduleGroupItemInfoAction = () => ({
  type: types.CLEAR_POST_SCHEDULE_GROUP_ITEM_INFO
})

const deleteScheduleGroupItemAction = data => ({
  type: types.DELETE_SCHEDULE_GROUP_ITEM,
  payload: data
})

const clearDeleteScheduleGroupItemInfoAction = () => ({
  type: types.CLEAR_DELETE_SCHEDULE_GROUP_ITEM_INFO
})

const deleteSelectedSchedules = ids => ({
  type: types.DELETE_SELECTED_SCHEDULES,
  ids
})

const deleteSchedule = id => ({
  type: types.DELETE_SCHEDULE,
  id
})

const approveSchedule = id => ({
  type: types.APPROVE_SCHEDULE,
  id
})

const clearResponseInfo = () => ({
  type: types.CLEAR_SCHEDULE_RESPONSE_INFO
})
const cloneSchedule = data => ({
  type: types.CLONE_SCHEDULE,
  data
})

const copySchedule = data => ({
  type: types.COPY_SCHEDULE,
  data
})

const clearScheduleStatus = () => ({
  type: types.CLEAR_SCHEDULE_STATUS
})

const clearSchedulePutPostStatus = () => ({
  type: types.CLEAR_SCHEDULE_PUT_POST_STATUS
})

const clearAddedSchedule = () => ({
  type: types.CLEAR_ADDED_SCHEDULE
})

const clearScheduleError = () => ({
  type: types.CLEAR_SCHEDULE_ERROR
})

const getSchedulePreview = (data, key) => ({
  type: types.GET_SCHEDULE_PREVIEW,
  data,
  meta: { key }
})

const generateSchedulePreview = data => ({
  type: types.GENERATE_SCHEDULE_PREVIEW,
  data
})

const generateSmartSchedulePreview = data => ({
  type: types.GENERATE_SMART_SCHEDULE_PREVIEW,
  data
})

const closeSchedulePreview = () => ({
  type: types.CLOSE_SCHEDULE_PREVIEW
})

const clearSchedulePreview = () => ({
  type: types.CLEAR_SCHEDULE_PREVIEW
})

const showSchedulePreview = () => ({
  type: types.SHOW_SCHEDULE_PREVIEW
})

const clearScheduleFetchParams = () => ({
  type: types.CLEAR_SCHEDULE_FETCH_PARAMS
})

const getGroupSchedules = params => ({
  type: types.GET_SCHEDULE_GROUP_SCHEDULES,
  params
})

const assignDevicesSchedule = ({ id, data }) => ({
  type: types.ASSIGN_DEVICES_SCHEDULE,
  data,
  meta: {
    id
  }
})

const checkScheduleAvailability = () => ({
  type: types.GET_ASSIGNABLE_SCHEDULE
})

const patchScheduleItem = ({ id, data, name }) => ({
  type: types.PATCH_SCHEDULE_ITEM,
  data,
  meta: {
    id,
    name
  }
})

const cloneScheduleTimeline = ({ id, data }) => ({
  type: types.CLONE_SCHEDULE_TIMELINE,
  data,
  id
})

const postScheduleBulk = payload => ({
  type: types.POST_SCHEDULE_BULK,
  payload
})

const clearPostScheduleBulkInfo = () => ({
  type: types.CLEAR_POST_SCHEDULE_BULK_INFO
})

export {
  approveSchedule,
  getSchedule,
  postSchedule,
  editSchedule,
  getScheduleItemsAction,
  getScheduleListsAction,
  getScheduleLibraryPrefAction,
  putScheduleLibraryPrefAction,
  getScheduleGroupItemsAction,
  clearGetScheduleGroupItemsInfoAction,
  postScheduleGroupItemAction,
  clearPostScheduleGroupItemInfoAction,
  deleteScheduleGroupItemAction,
  clearDeleteScheduleGroupItemInfoAction,
  deleteSelectedSchedules,
  clearResponseInfo,
  cloneSchedule,
  copySchedule,
  deleteSchedule,
  clearScheduleGroupItemsInfo,
  clearScheduleStatus,
  clearSchedulePutPostStatus,
  clearAddedSchedule,
  clearScheduleError,
  getSchedulePreview,
  generateSchedulePreview,
  generateSmartSchedulePreview,
  closeSchedulePreview,
  clearSchedulePreview,
  showSchedulePreview,
  clearScheduleFetchParams,
  getGroupSchedules,
  getScheduleTimelineAction,
  assignDevicesSchedule,
  clearScheduleItemsAction,
  checkScheduleAvailability,
  patchScheduleItem,
  cloneScheduleTimeline,
  clearScheduleTimeline,
  getCalendarSchedules,
  clearCalendarSchedules,
  postScheduleBulk,
  clearPostScheduleBulkInfo
}
