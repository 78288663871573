import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import classNames from 'classnames'

import {
  withStyles,
  Avatar,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Settings, ExitToApp, Person } from '@material-ui/icons'
import { DropdownHover } from '../Dropdowns'

import {
  logoutUserAction,
  clearLogoutInfo
} from 'actions/authenticationActions'
import { clearUserDetailsAction } from 'actions/userActions'
import { roles } from 'utils'
import { LOGOUT_EVENT_NAME, SYSTEM_ROLE } from 'constants/api'
import restoreLastOriginalUser from 'utils/restoreLastOriginalUser'
import { getLogoutUrl } from 'utils/permissionUrls'
import { storageClear } from 'utils/localStorage'
import { clearConfigReducer } from 'actions/configActions'
import { clearClientStore } from 'actions/new/client'
import socket from 'services/socket'
import HoverMenuList from './HoverMenuList'
import { LIGHT } from 'theme'
import { resetAllFilters } from 'actions/filtersActions'
import usePermissions from 'hooks/api/usePermissions'
import { permissionNames } from 'constants/index'

const styles = theme => {
  const {
    palette,
    type,
    typography,
    fontSize,
    lineHeight,
    fontWeight,
    colors,
    breakpoints
  } = theme
  return {
    accountInfoWrap: {
      display: 'flex',
      alignItems: 'stretch',
      marginLeft: '0.5rem',
      paddingLeft: '1rem',
      borderLeftWidth: 1,
      borderLeftStyle: 'solid',
      borderLeftColor: type === LIGHT ? colors.border.light : colors.border.dark
    },
    subMenu: {
      top: '80%',
      transform: 'rotateX(90deg)',
      transformOrigin: 'top center'
    },
    actionBtnLink: {
      display: 'flex',
      cursor: 'pointer'
    },
    actionBtnIcon: {
      fontSize: '25px',
      opacity: 0.8,
      transition: 'opacity .2s',
      paddingTop: '5px'
    },
    actionBtnThemeIcon: {
      padding: '3px 0'
    },
    languagesBtnLink: {
      cursor: 'pointer'
    },
    avatar: {
      margin: 10
    },
    userName: {
      ...typography.lightText[type],
      fontSize: fontSize.secondary,
      lineHeight: lineHeight.secondary,
      fontWeight: fontWeight.normal,
      '&:hover': {
        textShadow: `0 0 1px ${typography.lightText[type].color}`
      },
      [breakpoints.down('md')]: {
        display: 'none'
      }
    },
    text: {
      ...typography.lightText[type],
      fontSize: fontSize.primary,
      lineHeight: lineHeight.primary,
      fontWeight: 600,
      transition: 'opacity .2s',
      whiteSpace: 'nowrap'
    },
    description: {
      ...typography.lightText[type],
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      lineClamp: 2,
      boxOrient: 'vertical'
    },
    textRoot: {
      padding: '2px 0 0',
      margin: '0px'
    },
    flag: {
      width: 30,
      marginTop: '5px',
      filter: 'grayscale(100%)'
    },
    listContainer: {
      padding: '0px',
      position: 'relative',
      overflowY: 'visible',
      display: 'grid',
      gridGap: '6px'
    },
    switchListItem: {
      height: '3em'
    },
    listItem: {
      padding: 10,
      maxHeight: 90,
      alignItems: 'flex-start',
      '&:hover $actionBtnIcon': {
        opacity: 1
      }
    },
    dropdownTrigger: {
      cursor: 'pointer'
    },
    switchListItemText: {
      cursor: 'pointer'
    },
    switchSet: {
      marginLeft: -18
    },
    scrollbarRoot: {
      maxHeight: 'calc(100vh - 100px)'
    },
    navigationSubMegaMenu: {
      padding: '20px'
    },
    navigationHoverCard: {
      backgroundColor: palette[type].body.background,
      borderRadius: '10px',
      position: 'absolute',
      top: 0,
      left: 0,
      transitionDuration: '0.7s',
      width: '255px'
    },
    subItemRow: {
      gridColumn: '1/3',
      display: 'flex',
      gridGap: 6,
      marginBottom: 10
    },
    disabled: {
      opacity: 0.5,
      pointerEvents: 'none'
    }
  }
}

const dynamicStyles = makeStyles({
  listContainer: {
    gridTemplateColumns: ({ navCols }) =>
      `repeat(${navCols},calc((100% - 12px)/${navCols}))`
  },
  text: {
    color: ({ navColor }) => navColor + ' !important'
  },
  scrollbarRoot: {
    width: ({ navCols }) => `${280 * navCols}px !important`
  }
})

const dropdownFadeTransition = 500

const AccountInfo = ({
  t,
  classes,
  userProfile,
  userReducer,
  logout,
  openLanguageSelector,
  openProfileSelector,
  ssoLogoutUrl,
  location,
  history,
  match,
  dark: isDark,
  currentTheme,
  handleThemeChange,
  logoutUserAction,
  resetAllFilters,
  cols = 2,
  color = '#535d73',
  navigationDisabled,
  ...props
}) => {
  const [hidingDropdown, setHidingDropdown] = useState(false)
  const [hoverCard, setHoverCard] = useState({
    height: '90px',
    transform: 'translate(0px, 0px)'
  })
  const { getPermissionByName } = usePermissions()

  const defaultProfileImage = useMemo(() => {
    return userProfile.userPic && !userProfile.userPic.includes('user1.png')
      ? userProfile.userPic
      : ''
  }, [userProfile])

  const [profile, setProfile] = useState(defaultProfileImage)
  const [userDetails, setUserDetails] = useState({
    role: {},
    permissions: {}
  })

  const dynamicClasses = dynamicStyles({
    navColor: color,
    navCols: cols
  })

  const settingPermissions = useMemo(
    () => ({
      read: getPermissionByName(
        permissionNames.CLIENT_SETTINGS_SHOW,
        permissionNames.SYSTEM_SETTING_SHOW,
        permissionNames.ENTERPRISE_SETTING_SHOW
      )
    }),
    [getPermissionByName]
  )

  const onMouseOver = useCallback(
    index => {
      const x = (index % cols) * 255
      const y = Math.floor(index / cols) * 96
      setHoverCard({
        height: 90,
        transform: `translate(${x}px, ${y}px)`
      })
    },
    [cols]
  )

  useEffect(() => {
    setProfile(defaultProfileImage)
  }, [setProfile, defaultProfileImage])

  useEffect(() => {
    if (userReducer.response) {
      const { role, permissions } = userReducer.response
      setUserDetails({ role: roles.parse(role), permissions })
    }
  }, [userReducer])

  const logoutUser = useCallback(() => {
    resetAllFilters()
    logoutUserAction()
  }, [logoutUserAction, resetAllFilters])

  const menuItems = useMemo(() => {
    const {
      role: { role }
    } = userDetails
    return [
      {
        url: `/${role}/user-profile`,
        text: t('My Settings'),
        description: t('Manage your user profile details and password.'),
        icon: <Person style={{ color }} className={classes.actionBtnIcon} />,
        render: true,
        disabled: navigationDisabled
      },
      {
        url: `/${role}/account-settings`,
        text: t('Account'),
        description: t('Setup account settings security and features.'),
        icon: <Settings style={{ color }} className={classes.actionBtnIcon} />,
        render: role !== SYSTEM_ROLE && settingPermissions.read,
        disabled: navigationDisabled
      },
      {
        url: `/${role}/settings`,
        text: t('Account'),
        description: t('Setup account settings security and features.'),
        icon: <Settings style={{ color }} className={classes.actionBtnIcon} />,
        render: role === SYSTEM_ROLE && settingPermissions.read,
        disabled: navigationDisabled
      },
      {
        onLinkClick: logoutUser,
        text: t('Logout'),
        description: t('Logout from current session to secure your account.'),
        icon: <ExitToApp style={{ color }} className={classes.actionBtnIcon} />,
        render: true
      }
    ]
  }, [
    userDetails,
    t,
    color,
    classes.actionBtnIcon,
    navigationDisabled,
    settingPermissions.read,
    logoutUser
  ])

  useEffect(() => {
    if (logout.response || logout.error) {
      storageClear()

      document.dispatchEvent(new Event(LOGOUT_EVENT_NAME))

      props.clearLogoutInfo()
      props.clearUserDetailsAction()
      props.clearConfigReducer()
      props.clearClientStore()
      socket.disconnect()

      if (!!localStorage.getItem('originalUsers')) {
        restoreLastOriginalUser()
        window.location.reload()
      } else history.push(getLogoutUrl(userDetails.role.role))
    }
    // eslint-disable-next-line
  }, [logout])

  const onResetMouseHover = useCallback(() => {
    setHoverCard({
      height: '0px',
      transform: `translate(0px, 0px)`
    })
  }, [])

  const setDefaultMouseHoverPosition = useCallback(() => {
    const result = menuItems.find(items => items.url === location.pathname)
    const resultIndex = menuItems
      .filter(itemList => itemList.render)
      .findIndex(items => items.url === location.pathname)

    if (result) {
      onMouseOver(resultIndex)
    } else {
      onResetMouseHover()
    }
  }, [location, onMouseOver, menuItems, onResetMouseHover])

  useEffect(() => {
    setDefaultMouseHoverPosition()
  }, [
    location,
    onMouseOver,
    menuItems,
    onResetMouseHover,
    currentTheme,
    setDefaultMouseHoverPosition
  ])

  const scrollHeight = useMemo(() => {
    const filterMenu = menuItems.filter(item =>
      item.hasOwnProperty('render') ? item.render : true
    )
    return 100 * Math.ceil(filterMenu.length / cols) + 25
    // eslint-disable-next-line
  }, [menuItems])

  const onLinkClick = useCallback(
    (onClick, closeDropDown, isLink) => e => {
      !isLink && e.preventDefault()
      onClick && onClick()
      if (closeDropDown) {
        setHidingDropdown(true)
        setTimeout(() => {
          setHidingDropdown(false)
        }, 2 * dropdownFadeTransition)
      }
    },
    []
  )

  const renderListItem = useCallback(
    ({
      type,
      text,
      index,
      onClick,
      closeDropDownOnClick,
      url,
      subIndex,
      printEnterprise,
      icon,
      description,
      disabled
    }) => {
      const onMouseOverByType = () => {
        return type === 'row'
          ? onMouseOver(index, subIndex)
          : onMouseOver(index)
      }
      return (
        <ListItem
          key={text + index}
          className={classNames(classes.listItem, {
            [classes.disabled]: disabled
          })}
          component={RouterLink}
          onClick={onLinkClick(onClick, closeDropDownOnClick, !!url)}
          to={url || '#'}
          disableGutters
          onMouseOver={() => onMouseOverByType(type)}
        >
          <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
          <ListItemText
            classes={{
              root: classes.textRoot,
              primary: classNames(classes.text, dynamicClasses.text),
              secondary: classes.description
            }}
            primary={text}
            secondary={
              <>
                {description && description}
                {printEnterprise && <b> ({t('Enterprise')}).</b>}
              </>
            }
          />
        </ListItem>
      )
    },
    [classes, dynamicClasses, onLinkClick, onMouseOver, t]
  )

  return (
    <div className={classes.accountInfoWrap}>
      <DropdownHover
        forceHidden={hidingDropdown}
        menuContainerClassName={classes.subMenu}
        ButtonComponent={
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.dropdownTrigger}
          >
            <Avatar
              alt={userProfile.userName}
              src={profile}
              children={userProfile.userName[0].toUpperCase()}
              onError={() => setProfile('')}
              className={classes.avatar}
            />
            <Typography component="span" className={classes.userName}>
              {userProfile.userName}
            </Typography>
          </Grid>
        }
        MenuComponent={
          <div
            className={classNames(
              classes.scrollbarRoot,
              dynamicClasses.scrollbarRoot
            )}
            style={{
              height: scrollHeight
            }}
            onMouseLeave={() => setDefaultMouseHoverPosition()}
          >
            <div className={classes.navigationSubMegaMenu}>
              <List
                component="nav"
                className={classNames(
                  classes.listContainer,
                  dynamicClasses.listContainer
                )}
              >
                <div
                  className={classes.navigationHoverCard}
                  style={{
                    ...(hoverCard.width ? { width: hoverCard.width } : {}),
                    height: hoverCard.height,
                    transform: hoverCard.transform
                  }}
                />
                <HoverMenuList
                  classes={classes}
                  menuItems={menuItems}
                  renderListItem={renderListItem}
                />
              </List>
            </div>
          </div>
        }
      />
    </div>
  )
}

const mapStateToProps = ({ logout, user }) => ({
  logout,
  userReducer: user.details
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      logoutUserAction,
      resetAllFilters,
      clearLogoutInfo,
      clearUserDetailsAction,
      clearConfigReducer,
      clearClientStore
    },
    dispatch
  )

export default withTranslation('translations')(
  withStyles(styles)(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountInfo))
  )
)
