import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core'
import ContentLoader from 'react-content-loader'

import {
  designOrientations,
  imageOrientations
} from 'constants/canvasConstants'

const defaultSizeProps = {
  margin: 8,
  radius: 4
}

const landscapeSizeProps = {
  cols: 2,
  rows: 6,
  height: 75,
  width: 128
}

const portraitSizeProps = {
  cols: 3,
  rows: 4,
  height: 144,
  width: 81
}

const DesignGroupLoader = ({ theme, type, sizeProps = {}, withHeader }) => {
  const { cols, rows, height, width, margin, radius } = {
    ...defaultSizeProps,
    ...(type === designOrientations.LANDSCAPE
      ? landscapeSizeProps
      : portraitSizeProps),
    ...sizeProps
  }

  const grids = useMemo(() => {
    const items = []
    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        items.push(
          <rect
            key={`${i}${j}`}
            x={j * (margin * 2 + width)}
            y={i * (margin * 2 + height)}
            rx={radius}
            ry={radius}
            width={width}
            height={height}
          />
        )
      }
    }
    return items
  }, [cols, rows, height, width, margin, radius])
  return (
    <>
      {withHeader && (
        <ContentLoader
          style={{
            width: '100%',
            height: 41
          }}
          backgroundColor={theme.palette[theme.type].loader.background}
          foregroundColor={theme.palette[theme.type].loader.foreground}
        >
          <rect x={10} y={14} rx={3} ry={3} width={40} height={13} />
          <rect x={222} y={14} rx={3} ry={3} width={40} height={13} />
        </ContentLoader>
      )}
      <ContentLoader
        style={{
          width: '100%',
          height: rows * (height + margin * 2) - margin * 2
        }}
        backgroundColor={theme.palette[theme.type].loader.background}
        foregroundColor={theme.palette[theme.type].loader.foreground}
      >
        {grids}
      </ContentLoader>
    </>
  )
}

DesignGroupLoader.propTypes = {
  type: PropTypes.oneOf([
    ...Object.values(designOrientations),
    ...Object.values(imageOrientations)
  ]),
  sizeProps: PropTypes.object,
  withHeader: PropTypes.bool
}

export default withTheme(DesignGroupLoader)
